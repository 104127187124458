import React, { useState } from "react";
import close from "../../Assets/png/close-square.png"
import Button from "../../../Common/shared/Button/button";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import { CreateDropValueByType, UpdateDropValueById, DeleteDropValueByType } from "../../../Services/DropService.ts";

function EditDropdown({ name, servname, items, onClose }) {
    const [displayModal, setDisplayModal] = useState(false);
    const [itemsToEdit, setItemsToEdit] = useState([]);
    const [tableItems, setTableItems] = useState(items);
    const { startLoading, stopLoading } = useLoading();

    const toggleEdit = (item) => {
        if (itemsToEdit.includes(item)) {
            item = {...item, isDeleted: true};
            setItemsToEdit(prevItems => prevItems.filter(prevItem => prevItem.value01 !== item.value01));
        } else {
            setItemsToEdit(prevItems => [...prevItems, item]);
        }
    };

    const handleInputChange = (value, item) => {
        setItemsToEdit(prevItems =>
          prevItems.map(i => i.value01 === item.value01 ? { ...i, value01: value } : i)
        );
        setTableItems(prevItems =>
            prevItems.map(i => i.value01 === item.value01 ? { ...i, value01: value } : i)
          );
      };

    const handleClose = () => {
        setDisplayModal(!displayModal);
        setTableItems(items);
    }

    // const handleAddRow = () => {
    //     setTableItems(prevItems => [...prevItems, {id: tableItems.length + 1, value01: "", value02: ""}]);
    //     toggleEdit({id: tableItems.length + 1, value01: "", value02: ""});
    // };

    const handleSave = () => {
        if (itemsToEdit) {
            startLoading();
            itemsToEdit.forEach(async(element) => {
                if(element.id !== 0 && items.length >= element.id)
                {
                    if(element?.isDeleted)
                    {
                        var res = await DeleteDropValueByType({ id: element.id, value01: element.value01, value02: "" });
                        if(!res) snackBarStore.showSnackBar(`Drop Deleted Successfully`, "success");
                        return;
                    }
                    var res = await UpdateDropValueById({ id: element.id, value01: element.value01, value02: "" }, element.id);
                    if(!res) snackBarStore.showSnackBar(`Drop Updated Successfully`, "success");
                }
                else if(element?.isDeleted === false){
                    var res = await CreateDropValueByType({typeName: servname, values: [{ value01: element.value01, value02: "" }]});
                    if(!res) snackBarStore.showSnackBar(`Drop Saved Successfully`, "success");
                }
                else{
                    var res = await DeleteDropValueByType({ id: element.id, value01: element.value01, value02: "" });
                    if(!res) snackBarStore.showSnackBar(`Drop Deleted Successfully`, "success");
                }
            });
            stopLoading();
            setDisplayModal(false);
            onClose();
        }
    }

    return (
        <div className="App2">

            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-2-editDrop ${displayModal ? "leadShow-2-editDrop" : ""} `} style={{ height: "60vh", bottom: "30%" }}>
                <div
                    className="leadinfo-Top-2"
                >
                    <h6 className="leadInfo-heading">Edit Dropdown</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={handleClose} />
                </div>
                <div className="addServ-content">
                    <div className="editdrop-table">
                        <table className='leadsTable roundedcorner' id='leadsTable'>
                            <tbody>
                                {tableItems.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: itemsToEdit.includes(item) ? "5px 17px" : "17px", width: "70%" }} className='label2'>
                                            {itemsToEdit.includes(item) ?
                                                <input type="text" onBlur={(e) => handleInputChange(e.target.value, item)} 
                                                className="input11" defaultValue={item.value01} />
                                                :
                                                item.value01
                                            }
                                        </td>
                                        <td className='actionsTable' style={{ justifyContent: "center" }}>
                                            <SVGIcons.Edit onClick={() => toggleEdit(item)} />
                                            <SVGIcons.Trash onClick={() => {
                                                setTableItems(prevItems => prevItems.filter(prevItem => prevItem.id !== item.id));
                                                toggleEdit({...item, isDeleted: true});
                                            }} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="fu-submit-cancel" style={{ justifyContent: "flex-end", marginRight: "7px" }}>
                        {/* <Button onClick={handleAddRow} variant="smallButton" prefixIcon={<SVGIcons.Add />}>Add</Button> */}
                        <Button variant="mediumButton" onClick={handleSave}>Save</Button>
                    </div>
                </div>
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
        </div>
    );
}


export default EditDropdown;