import { jwtDecode } from "jwt-decode";
import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { redirect } from "react-router-dom";

type AccessTokenType = {
    fullName: string;
    email: string;
    id: string;
    image_url: string;
    tenant: string;
    exp: number;
    role: string;
};
export const useAuth = (): boolean => {
  let accessToken = localStorage.getItem("accessToken");
  if (accessToken == null || accessToken == "" || accessToken.toLocaleLowerCase() === "undefined".toLocaleLowerCase()) return false;
  if (accessToken) {
    const decoded = jwtDecode(accessToken) as AccessTokenType;
    if(isTokenExpired(decoded.exp)) return false;
    const nameidentifier = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    if (nameidentifier)
    {
       return true;
    }
  }

  return false;
};

export const isTokenExpired = (exp: number): boolean => {
  try {
      const expirationTime = exp;
      const currentTime = Math.floor(Date.now() / 1000);
      return expirationTime < currentTime;
  } catch (error) {
    snackBarStore.showSnackBar(
      `Problem in Decode token: ${error.response?.data?.exception}`,
      "error"
    );
      return true;
  }
};

export const getAuthUser = (): AccessTokenType | null => {
  let accessToken = localStorage.getItem("accessToken")!;

  if (!accessToken) return null;
  if (accessToken) {
    const decoded = jwtDecode(accessToken) as AccessTokenType;
    if (!isTokenExpired(decoded.exp)) 
    {
      const authUser: AccessTokenType = {
        fullName: decoded["fullName"],
        email: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
        id: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
        image_url: decoded["image_url"],
        tenant:  decoded["tenant"],
        exp: Number(decoded["exp"]),
        role: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      }
        return authUser;
    }
  }
  return null;
};

export const GetApiForImg = (): string =>
{
  return "https://opticalerp.in/api";
}

export const GetApi = (): string =>
{
  return "https://opticalerp.in/api/";
}