import { React, useState, useContext, useEffect } from 'react';
import logo from '../../Presentation/Assets/png/logoLead.png';
import { SidebarNavigator } from '../shared/sidebarNavigator/sidebarNavigator.jsx';
import SVGIcons from '../shared/Icons/svgicons';
import { FaAngleDown } from 'react-icons/fa6';
import { SidebarContext } from '../../Services/ContextAPI/sidebarContext';
import { logout } from "../../Services/authService.ts";
import { GetAllMenus } from "../../Services/MenusService.ts";
import PageLoader from "../../Common/PageLoader/index.jsx";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Utils/userAuth.ts";
import { useLoading } from '../../Services/ContextAPI/loadingContext.jsx';

const Sidebar = ({ width, sidebarSmall, active, isSet }) => {
  const [collapse2Open, setCollapse2Open] = useState(false);
  const { activePage, setActivePage } = useContext(SidebarContext);
  const [menus, setMenus] = useState([]);
  const { loadingCount, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const isLoggedIn = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
    const fetchMenus = async () => {
      try {
        startLoading();
        const result = await GetAllMenus();
        if (Array.isArray(result)) {
          setMenus(result);
        }
      } catch (error) {
      } finally {
        stopLoading();
      }
    };
  
    fetchMenus();
  }, [isLoggedIn]);

  useEffect(() => {
    setActivePage(active);
    if (isSet) {
      setCollapse2Open(true);
    }
  }, [active, isSet, setActivePage]);

  const handleLogout = () => {
    logout(navigate);
  };

  const toggleCollapse2 = () => {
    setCollapse2Open(prevState => !prevState);
  };

  return (
    <>
      {loadingCount > 0 ? <PageLoader /> :
        <div className={sidebarSmall ? "sidebarSmall" : "sidebar"} style={{ width }}>
          <div className="sidebarTop">
            <div className={sidebarSmall ? "sidebarLogo sidebarSmallLogo" : "sidebarLogo"}>
              <img src={logo} style={{width:(sidebarSmall?"60px":"130px"),transition:"width 1s"}} alt="" className='sidebarLogoImg' />
            </div>
          </div>
          <div className="sidebarMenuHeading">
            <div className={sidebarSmall ? "menuHeadingSmall" : ""}>
              <div className="label3">MENU</div>
            </div>
            <hr className="sidebarMenuLine" />
          </div>
          <div className="sidebarNavigations">
            {menus.filter(x => !x.isHeader && x.sequence == 0).map((sidebar, key) => (
              <SidebarNavigator key={key} name={sidebar.menuName} sidebarSmall={sidebarSmall}
                onClick={() => setActivePage(sidebar.menuName)}
                active={activePage === sidebar.menuName ? "active" : ''}
              />
            ))}
          </div>
          <div className="sidebarMenuLine2">
            <hr className="sidebarMenuLine" />
          </div>
          <div className="sidebarNavigations">
            {menus.filter(x => x.isHeader && x.component == "").map((sidebar, key) => (
              <>
                <div
                  key={key}
                  className={sidebarSmall ? "sidebarNavigator NavigatorSmall" : "sidebarNavigator"}
                  onClick={toggleCollapse2}
                >
                  <div className="sidebaricon">
                    <SVGIcons.Settings2 />
                  </div>
                  <div className={sidebarSmall ? "navigatorName1 navigatorName1small" : "navigatorName1"}>
                    <div className={sidebarSmall ? "label1 sidebarNavigatorSmall" : "label1 sidebarNavigatorBig"}>
                      {sidebar.menuName}
                    </div>
                  </div>
                  <FaAngleDown
                    className={sidebarSmall ? "sidebarDropdown sidebarDropdownSmall" : `sidebarDropdown ${collapse2Open ? 'rotatedDropdown' : ''}`}
                  />
                  <div className="tooltipSidebar">
                    <div className="left-arrow"></div>
                    <div className='tooltipInner'>
                      <span className=''>{sidebar.menuName}</span>
                    </div>
                  </div>
                </div>
                {collapse2Open && !sidebarSmall && (
                  <ul className={collapse2Open ? "collapaseopen" : "collapseclose"}>
                    {menus.filter(y => !y.isHeader && y.typeOf == sidebar.menuName).map((val, ind) => (
                      <SidebarNavigator
                        key={ind}
                        name={val.menuName}
                        sidebarSmall={sidebarSmall}
                        onClick={() => setActivePage(val.menuName)}
                        active={activePage === val.menuName ? "active" : ''}
                      />
                    ))}
                  </ul>
                )}
              </>
            ))}
            <div className="logout" style={{ marginBottom: "100px" }}>
              <SidebarNavigator name="Logout" sidebarSmall={sidebarSmall} onClick={handleLogout} />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Sidebar;