import React, { useState, useEffect } from "react";
import close from "../../Assets/png/close-square.png"
import "../LeadInfo/LeadInfo.css";
import { FaRegFilePdf } from "react-icons/fa6";
import Button from "../../../Common/shared/Button/button";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { useForm } from "react-hook-form";
import "./followUp.css"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { GetFollowUpByLeadId } from "../../../Services/followUpService.ts";
import { GetAllTypes } from "../../../Services/udcService.ts";
import { GetAllUsers } from "../../../Services/authService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import { SaveFollowUpData } from "../../../Services/followUpService.ts";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";

function LeadFollowUp({ name, item }) {
    const [alert, setAlert] = useState("");
    const { startLoading, stopLoading } = useLoading();
    const [displayModal, setDisplayModal] = useState(false);
    // const [displayFollowUp2, setDisplayFollowUp2] = useState(false);
    const [taskTypes, setTaskTypes] = useState([]);
    const [taskTo, setTaskto] = useState([]);
    const [followUpStatus, setfollowUpStatus] = useState([]);
    const [FollowupData, setFollowupData] = useState([]);
    const [form, setForm] = useState(false);
    const [defaultOption, setDefaultOption] = useState({
        taskTypeOption: null,
        taskToOption: null,
        followUpOption: null
    });
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                startLoading();
                const result = await GetAllTypes("Leads");
                if (result) {
                    if (Array.isArray(result)) {
                        setfollowUpStatus(result.map((res) => ({
                            value: res.id,
                            label: res.value01,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchFollowUptype = async () => {
            try {
                startLoading();
                const result = [{ id: item.id, val: "Lead" }, { id: item.customerId, val: "Client" }];
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskTypes(result.map((res) => ({
                            value: res.id,
                            label: res.val,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchTaskTo = async () => {
            try {
                startLoading();
                const result = await GetAllUsers();
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskto(result.filter(x => x.roleName.toUpperCase() != "SuperAdmin".toUpperCase()).map((res) => ({
                            value: res.user.id,
                            label: `${res.user.firstName} ${res.user.lastName}`,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchFollowupData = async () => {
            try {
                startLoading();
                const response = await GetFollowUpByLeadId(item.id);
                setFollowupData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally{
                stopLoading();
            }
        };
        fetchStatus();
        fetchFollowupData();
        fetchFollowUptype();
        fetchTaskTo();
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const handleDropdownChange = (dropDwnId, selected) => {
        setDefaultOption((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    useEffect(() => {
        const clearMessage = setTimeout(() => {
            setAlert("");
        }, 5000);
        return () => clearTimeout(clearMessage);
    }, [alert]);

    const getFilename = (url) => {
        try {
          const urlObj = new URL(url);
          const pathname = urlObj.pathname;
          const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
          return filename;
        } catch (error) {
            snackBarStore.showSnackBar(`Invalid Attachment URL`, "error");
          return '';
        }
    }

    const onSubmit = async(data) => {
        setForm(true);
        startLoading();
        const dates = `${new Date(data.date).getFullYear()}-${('0' + new Date(data.date).getMonth()).slice(-2)}-${new Date(data.date).getDate()}`;
        const followUpData =
        {
            followupType: defaultOption.taskTypeOption.label,
            followupTypeId: defaultOption.taskTypeOption.value,
            title: data.title,
            followupStatus: defaultOption.followUpOption.value,
            assignedTo: defaultOption.taskToOption.value,
            followupDate: dates,
            followupTime: new Date(`${dates}T${data.time}`),
            attachmentUrl: '',
            fileRequest: imageSrc,
            remark: data.description,
            isActive: true
        };
        
        try {
            await SaveFollowUpData(followUpData);
        } catch (error) {
            snackBarStore.showSnackBar(`Error while Saving task: ${error}`, "error");
        }
        finally{
            stopLoading();
            window.location.reload();
        }
        await SaveFollowUpData(data);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc({name: file.name, extension: `.${file.name.split('.').pop()}`, data: reader.result});
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDownload = (fileUrl) => {
        return async () => {
            try {
                startLoading();
                const response = await fetch(fileUrl);
                const contentDisposition = response.headers.get('content-disposition');
                const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
                const fileName = fileNameMatch ? fileNameMatch[1] : 'download';
                const anchor = document.createElement('a');
                anchor.href = fileUrl;
                anchor.download = fileName;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            } catch (error) {
                snackBarStore.showSnackBar(`Error while downloading file: ${error}`, "error");
            } finally {
                stopLoading();
            }
        };
    };
    

    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal ${displayModal ? "leadShow" : ""} `}>
                <div
                    className="leadinfo-Top"
                >
                    <h6 className="leadInfo-heading">Follow up</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={() => setDisplayModal(!displayModal)} />
                </div>
                <div className="leadinfo-content">
                    <div className="followUp-button">
                        <Button variant="smallButton" prefixIcon={<SVGIcons.Add />}
                            onClick={() => setForm(true)}
                        >Add Follow up</Button>
                    </div>
                    {form &&
                        <form className="followup-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="fu-form1" style={{gap:"20px"}} >
                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="FollowType">Follow Up Type</label>
                                    <Dropdown options={taskTypes}
                                        className={`dropdown-fu dropdown-leads ${defaultOption.taskTypeOption === "" ? 'error-border' : ''}`}
                                        onChange={(e) => handleDropdownChange("taskTypeOption", e)}
                                        value={defaultOption.taskTypeOption} 
                                        placeholder="Select Follow Up Type"
                                    />
                                    {defaultOption.taskTypeOption === "" && <span className="errorLogin label3">Follow Up Type required</span>}

                                </div>
                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="Title">Title</label>
                                    <input
                                        type='text'
                                        className={`input-fu input10 ${errors.title ? 'error-border' : ''}`}
                                        placeholder="Enter title here"
                                        {...register("title", {
                                            required: 'Title is required',
                                        })}
                                        aria-invalid={errors.title ? 'true' : 'false'}
                                    />
                                    {errors.title && <div className="errorLogin label3">{errors.title.message}</div>}

                                </div>
                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="Status">Status</label>
                                    <Dropdown options={followUpStatus}
                                        className={`dropdown-fu dropdown-leads ${defaultOption.followUpOption === "" ? 'error-border' : ''}`}
                                        onChange={(e) => handleDropdownChange("followUpOption", e)}
                                        value={defaultOption.followUpOption} 
                                        placeholder="Select Follow Up Status"
                                    />
                                    {defaultOption.followUpOption === "" && <span className="errorLogin label3">Follow Up Status required</span>}
                                </div>

                            </div>
                            {/* {displayFollowUp2 &&
                                <div className="fu-form1" style={{gap:"20px"}}>
                                    <div className="inputForm if2">
                                        <label className="followup-label" htmlFor="Status">Services</label>
                                        <div className="div3">
                                            <Multiselect
                                                options={options2} // Options to display in the dropdown
                                                selectedValues={selectedOptions} // Pre-selected values (optional)
                                                onSelect={handleSelect} // Function to handle selection
                                                onRemove={handleRemove} // Function to handle removal
                                                displayValue="name" // Property of options to display as label
                                                showCheckbox={true}
                                                avoidHighlightFirstOption={true}
                                                placeholder="Select Sub Category"
                                                className="div1"
                                            />
                                        </div>
                                        {errors.status && <div className="errorLogin label3">{errors.status.message}</div>}
                                    </div>
                                    <div className="inputForm if2 ">
                                        <label className="followup-label" htmlFor="desc">Description</label>
                                        <input
                                            type='text'
                                            className={`input-fu input10 ${errors.desc ? 'error-border' : ''}`}
                                            placeholder="Enter desc here"
                                            {...register("desc", {
                                                required: 'desc is required',
                                            })}
                                            aria-invalid={errors.desc ? 'true' : 'false'}
                                        />
                                        {errors.desc && <div className="errorLogin label3">{errors.desc.message}</div>}

                                    </div>
                                </div>
                            } */}
                            <div className="fu-form1" style={{gap:"20px"}} >
                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="AssignTo">Assign To</label>
                                    <Dropdown options={taskTo} className={`dropdown-fu dropdown-leads ${defaultOption.countryOption === "" ? 'error-border' : ''}`}
                                    onChange={(e) => handleDropdownChange("taskToOption", e)}
                                    value={defaultOption.taskToOption} 
                                    placeholder="Select Person" />
                                    {defaultOption.taskToOption === "" && <span className="errorLogin label3">Assigned To required</span>}
                                </div>

                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="Attachment">Attachment</label>
                                    <input
                                        type='file'
                                        className={`input-fu input10 ${errors.attachment ? 'error-border' : ''}`}
                                        placeholder="Upload File here"
                                        {...register("attachment", {
                                            required: 'Attachment is required',
                                        })}
                                        onChange={handleFileChange}
                                        aria-invalid={errors.attachment ? 'true' : 'false'}
                                    />
                                    {errors.attachment && <div className="errorLogin label3">{errors.attachment.message}</div>}
                                </div>

                            </div>


                            <div className="fu-form1" style={{gap:"20px"}} >
                                <div className="inputForm if2 ">
                                    <label className="followup-label" htmlFor="Date">Schedule Meeting / Call</label>
                                    <input
                                        type='date'
                                        className={`input-fu input10 ${errors.date ? 'error-border' : ''}`}
                                        placeholder="Email"
                                        {...register("date", {
                                            required: 'date is required',
                                        })}
                                        aria-invalid={errors.date ? 'true' : 'false'}
                                    />
                                    {errors.date && <div className="errorLogin label3">{errors.date.message}</div>}
                                </div>
                                <div className="inputForm if2 ">
                                    <label className="followup-label fu-time-txt" htmlFor="time">Time</label>
                                    <input
                                        type='time'
                                        className={`input-fu input10 ${errors.time ? 'error-border' : ''}`}
                                        placeholder="time"
                                        {...register("time", {
                                            required: 'Time is required',
                                        })}
                                        aria-invalid={errors.time ? 'true' : 'false'}
                                    />
                                    {errors.time && <div className="errorLogin label3">{errors.time.message}</div>}
                                </div>

                            </div>

                            <div className="inputForm if2 ">
                                <label className="followup-label" htmlFor="Description">Description</label>
                                <textarea
                                    type='text'
                                    className={`input-fu-textarea input10 ${errors.description ? 'error-border' : ''}`}
                                    placeholder="Enter Description"
                                    {...register("description", {
                                        required: 'Description is required',
                                    })}
                                    aria-invalid={errors.description ? 'true' : 'false'}
                                />
                                {errors.description && <div className="errorLogin label3">{errors.description.message}</div>}
                            </div>


                            <div className="fu-submit-cancel">
                                <Button variant="mediumOutline" onClick={()=>setForm(false)} >Cancel</Button>
                                <Button variant="mediumButton">Submit</Button>
                            </div>
                        </form>
                    }

                    <div className="history">
                        {FollowupData.map((val) => (
                            <div>
                                <div className="history1">
                                    <div className="history1-left">
                                        <div className="history-circle">
                                            <div className="history-circle1"></div>
                                            <div className="history-circle2"></div>
                                        </div>
                                        <div className="history1-text">
                                            <div className="history-name">{val.assignedToName}</div>
                                            <div className="history1-text2 label3">
                                                <div className="label3" style={{textAlign: 'left'}}>{val.followupStatusName}</div>
                                                <div className="label3">Date {val.followupDate} {new Date(val.followupTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</div>
                                            </div>
                                            <div className="history1-text3 label3">
                                                <div className="history1-text3-1">{val.remark}</div>
                                            </div>
                                            <div className="history1-pdf">
                                                <div className="history1-pdf1">
                                                    <FaRegFilePdf size={18}  onClick={handleDownload(val.attachmentUrl)}/>
                                                </div>
                                                <div className="history1-pdf2">
                                                    {getFilename(val.attachmentUrl)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="history1-right">{val.followupDate}</div>
                                </div>
                            </div> 
                        ))}
                    </div>


                </div>
                {/* <button
                    className="Button"
                    onClick={() => setAlert("This is an alert!")}
                >
                    Trigger alert
                </button> */}
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
            <p className="Alert">{alert}</p>
        </div>
    );
}


export default LeadFollowUp;