import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { FollowupListDTO } from "../Domain/FollowUp/followUp.ts";
import httpClient from "./httpClient.ts";
import { SaveFollowUp } from "../Domain/FollowUp/followUp.ts";

export const GetFollowUpByLeadId = async (id: number) : Promise<Array<Partial<FollowupListDTO>>> =>{
    try {
        const { data } = await httpClient.get(`followupdetails/getbyleadid/getbyleadid?LeadId=${id}`);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return [];
      }
  }

  export const SaveFollowUpData = async (followUpData: SaveFollowUp) =>{
    try {
      console.log("fdata", JSON.stringify(followUpData));
        const { data } = await httpClient.post(`followupdetails/create/create`, followUpData);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return [];
      }
  }