import React, { useState , useEffect } from "react";
import close from "../../../Presentation/Assets/png/close-square.png"
import "../../../Presentation/Pages/LeadInfo/LeadInfo.css";
import "./importcsv.css"
import Button from "../Button/button";
import SVGIcons from "../Icons/svgicons";
import { FileUploader } from "react-drag-drop-files";
import LinearProgress from '@mui/joy/LinearProgress';
import { UploadLead } from "../../../Services/leadService.ts";
import snackBarStore from "../../snackBar/store/snackbarStore.ts";

function ImportCsv({ name}) {
    const [displayModal, setDisplayModal] = useState(false);
    const [file, setFile] = useState(null);
    const [loader, setLoader] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)
    const [progress, setProgress] = React.useState(0);

    const handleChange = (file) => {
        setFile(file);
    };

    const fileTypes = ["XLSX"];

    const handleClick = async () => { 
        setLoader(true)
        const fileData = file[0];
        if (fileData) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                var obj = {
                    name: fileData.name,
                    extension: `.${fileData.name.split('.').pop()}`,
                    data: reader.result
                }
                try{
                    await UploadLead(obj);
                }catch (error) {
                    snackBarStore.showSnackBar(`Error while Uploading lead: ${error}`, "error");
                }
            };
            reader.readAsDataURL(fileData);
        }
    };
    
    const handleModalClose = () => {
        setDisplayModal(!displayModal)
        setTimeout(() => {
            setLoader(false);
            setFile(null);
            setProgress(0);
        }, 1000);
    };


    React.useEffect(() => {
        let timer;
        if (loader) {
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 20)); // Ensure progress doesn't exceed 100
            }, 500);
        } else {
            clearInterval(timer);
        }

        if (progress >= 100) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [loader, progress]);
    
    useEffect(() => {
        if (progress >= 100) {
            setUploadComplete(true);
        }
    }, [progress]);

    const exportCSVFormat = () => {
        const headers = ['Header1', 'Header2', 'Header3']; // Add your headers here

        const csvData = [headers.join(',')];

        const csvContent = csvData.join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'format.csv'); 

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };


    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-2 ufile-modal ${displayModal ? "leadShow-2" : ""} `}>
                <div
                    className="leadinfo-Top-2"
                >
                    <h6 className="leadInfo-heading">Upload File</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={handleModalClose} />
                </div>
                <div className="addServ-content ufile-content">
                    {loader ?
                        <LinearProgress sx={{
                            "--LinearProgress-thickness": "10px",
                            "--LinearProgress-radius": "5px",
                            "--LinearProgress-progressThickness": "10px",
                            "--LinearProgress-progressRadius": "5px",
                            "color": "#322672"
                        }}
                            determinate size="large" value={progress} />
                        :
                        (file === null ? <FileUploader
                            multiple={true}
                            handleChange={handleChange}
                            name="file"
                            children={
                                <div className="file-uploader">
                                    <SVGIcons.UploadFile />
                                    <div className="label2">Select a EXCEL file to upload</div>
                                    <div className="label">Or drag and drop it here</div>
                                </div>
                            }
                            types={fileTypes}
                        /> :
                            <div className="file-uploader file-up">
                                <div className="close-fileUp" onClick={() => setFile(null)}>
                                    <SVGIcons.Close />
                                </div>
                                <SVGIcons.UploadFile />
                                <p className="label2">{file ? `${file[0].name}` : "no files uploaded yet"}</p>
                            </div>
                        )
                    }


                    {loader ?
                        <div className="fu-submit-cancel">
                            <Button variant="mediumButton" disabled={!uploadComplete} onClick={handleModalClose}>Done</Button>
                        </div>
                        :
                        <div className="fu-submit-cancel">
                            <Button variant="mediumOutline" onClick={() => setDisplayModal(!displayModal)}>Cancel</Button>
                            <Button variant="mediumButton" onClick={handleClick} disabled={!file}>Submit</Button>
                        </div>
                    }
                    {file == null &&
                        <div className="fu-submit-cancel">
                            <Button variant="smallButton" prefixIcon={<SVGIcons.DocumentDownload />} onClick={exportCSVFormat}>Download Format</Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}


export default ImportCsv;