import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { Values } from "../Domain/Drops/Drop.ts";
import httpClient from "./httpClient.ts";

export const GetAllTypes = async (typeName: string) : Promise<Array<Partial<Values>>> => {
    try {
        const { data } = await httpClient.get(`udc/getvaluesbytype?type=${typeName}`);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}
