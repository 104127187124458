import { React, useState, useEffect, useRef } from "react";
import "../addLeads/addLeads.css";
import "./profile.css"
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import PageLoader from "../../../Common/PageLoader/index";
import { useAuth } from "../../../Utils/userAuth.ts";
import { GetApiForImg } from "../../../Utils/userAuth.ts";
import { fetUserData, updateCurrentUser } from "../../../Services/authService.ts";

export default function EditProfile() {
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm();

    const isloggedin = useAuth();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [WhatsAppNo, setWhatsAppNo] = useState("");
    const phoneNumber = watch("phoneNumber", '');
    const syncInputs = watch("syncInputs", false);
    const apiUrl = GetApiForImg();


    useEffect(() => {
        if(!isloggedin)
        {
            navigate("/");
        }

        const fetchUser = async () => {
            try {
                const result = await fetUserData();
                if (result) {
                    setProfileData(result);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
            finally{
                setLoading(false);
            }
        }
    
        fetchUser();
    }, []);

    const [imageSrc, setImageSrc] = useState(null);
    const inputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc({
                    name: file.name,
                    extension: `.${file.name.split('.').pop()}`,
                    data: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = (e) => {
        e.preventDefault();
        inputRef.current.click();
    };

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setLoading(true);
        if (imageSrc) {
            data.deleteCurrentImage = true;
            data.image = imageSrc;
        }
        data.whatsAppNumber = WhatsAppNo;
        try {
            await updateCurrentUser(data, navigate);
        } catch (error) {
            console.error("Error updating user:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        {loading ? <PageLoader /> :
        <>
            <div  style={{ width: "100%" }}>
                <div className="add-leads-form pro-1">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="profile10">
                            <div className="profilePic1">
                                {imageSrc ?
                                    <img className="proPic01" src={imageSrc.data} alt="" />
                                    :
                                    <img className="proPic01" src={profileData.imageUrl ? `${apiUrl}/${profileData.imageUrl}` :"https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="} alt="" />
                                }
                                <Button prefixIcon={<SVGIcons.DocumentUpload />} onClick={handleUploadClick}>
                                    Upload
                                </Button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    ref={inputRef}
                                />
                            </div>
                            <div className="profileInfo">
                                <div className="add-leads-text">
                                    <div className="personal-text">Profile Detail</div>
                                </div>

                                <div className="add-leads-inputbox">
                                    <div className="add-leads-input-container">
                                        <div className="add-leadlabel">First Name</div>

                                        <input
                                            className={`add-leads-input proText ${errors.firstName ? "error-border" : ""
                                                }`}
                                            placeholder="Enter Name"
                                            defaultValue={profileData.firstName}
                                            name="firstName"
                                            {...register("firstName", { required: "Name is required" })}
                                            aria-invalid={errors.firstName ? 'true' : 'false'}
                                        />
                                        {errors.firstName && (
                                            <span className="errorLogin label3">
                                                {errors.firstName.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="add-leads-input-container">
                                        <div className="add-leadlabel">Last Name</div>

                                        <input
                                            className={`add-leads-input proText ${errors.lastName ? "error-border" : ""
                                                }`}
                                            placeholder="Enter Last Name"
                                            defaultValue={profileData.lastName} 
                                            name="lastName"
                                            {...register("lastName", { required: "Last Name is required" })}
                                            aria-invalid={errors.lastName ? 'true' : 'false'}
                                        />
                                        {errors.lastName && (
                                            <span className="errorLogin label3">
                                                {errors.lastName.message}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="add-leads-inputbox">
                                    <div className="add-leads-input-container">
                                        <div className="add-leadlabel">Email</div>
                                        <input
                                            type="text"
                                            className={`add-leads-input proText ${errors.email ? 'error-border' : ''}`}
                                            placeholder="abc@gmail.com"
                                            {...register("email", {
                                                required: 'email is required',
                                            })}
                                            name="email"
                                            defaultValue={profileData.email}
                                            aria-invalid={errors.email ? 'true' : 'false'}
                                        />
                                        {errors.email && <div className="errorLogin label3">{errors.email.message}</div>}
                                    </div>
                                </div>

                                <div className="add-leads-text">
                                    <div className="personal-text">Contact Detail</div>
                                </div>

                                <div className="add-leads-inputbox">
                                    <div className="add-leads-input-container">
                                        <div className="add-leadlabel">Mobile Number</div>
                                        <input
                                            className={`add-leads-input ${errors.phoneNumber ? 'error-border' : ''}`}
                                            {...register("phoneNumber", {
                                                required: 'Phone Number is required',
                                            })}
                                            defaultValue={profileData.phoneNumber}
                                            placeholder="+919876543211"
                                            type="number"
                                            aria-invalid={errors.phoneNumber ? 'true' : 'false'}
                                        />
                                        {errors.phoneNumber && <div className="errorLogin label3">{errors.phoneNumber.message}</div>}
                                    </div>
                                    <div className="add-leads-input-container">
                                        <div className="wp-phone">
                                            <div className="add-leadlabel">WhatsApp Number </div>
                                            <div className="wp-phone-2">
                                                <input
                                                    type="checkbox"
                                                    checked={syncInputs}
                                                    {...register("syncInputs")}
                                                    defaultValue={profileData.whatsAppNumber == profileData.phoneNumber}
                                                    style={{ border: "2px solid #00f", display: "block", backgroundColor: "#fafa" }}
                                                />
                                                <div className="same-no">same as mobile number</div>
                                            </div>
                                        </div>
                                        <input
                                            className={`add-leads-input ${errors.whatsAppNumber ? "error-border" : "" }`}
                                            {...register("whatsAppNumber", {
                                                required: syncInputs ? false : 'WhatsApp Number is required',
                                            })}
                                            value={syncInputs ? phoneNumber : WhatsAppNo} 
                                            onChange={(e) => {
                                                if (!syncInputs) {
                                                    setWhatsAppNo(e.target.value);
                                                }
                                                }}
                                            type="number"
                                            aria-invalid={errors.whatsAppNumber ? 'true' : 'false'}
                                            placeholder="+919876543210"

                                        />
                                        {errors.whatsAppNumber && <div className="errorLogin label3">{errors.whatsAppNumber.message}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="add-leads-button" style={{ marginTop: "15px" }}>
                            <Button variant="smallButton" onClick={() => navigate("/profile")}>Back</Button>
                            <Button
                                type="submit"
                                variant="smallButton"
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>}
        </>
    );
}