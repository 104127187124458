import './leads.css'
import { Search } from '../../../Common/shared/Search/search'
import Button from '../../../Common/shared/Button/button'
import SVGIcons from '../../../Common/shared/Icons/svgicons'
import React, { useState, useEffect, useContext } from 'react';
import "./table.css";
import { LeadStatus } from "../../../Common/shared/leadStatus/leadStatus";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import LeadInfo from '../../Pages/LeadInfo/LeadInfo.jsx';
import { FaAngleDown } from 'react-icons/fa6'
import LeadFollowUp from '../followUp/followUp';
import { DateContext } from '../../../Services/ContextAPI/dateContext';
import ImportCsv from '../../../Common/shared/importCsv/importCsv';
import { useNavigate } from 'react-router-dom';
import { GetAllLeadValues, DeleteLead } from "../../../Services/leadService.ts";
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';

export const Leads = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [10, 15, 20, 25]; // Options for items per page
    const { activeDate } = useContext(DateContext);
    const navigate = useNavigate();
    const { startLoading, stopLoading } = useLoading();

    const exportToCSV = () => {
        const csvData = [];
        const headers = ['Lead No', 'Lead Name', 'Date', 'Contact No', 'Status']; // Adjust headers as per your table structure

        // Push headers to csvData
        csvData.push(headers.join(','));

        // Push data rows to csvData
        filteredData.forEach(item => {
            const row = [
                item.id,
                item.customerName,
                item.leadDate,
                item.mobileNo,
                item.leadStatus
            ];
            csvData.push(row.join(','));
        });

        // Create a CSV file
        const csvContent = csvData.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'leads.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            startLoading();
            const jsonData = await GetAllLeadValues();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            stopLoading();
        }
    };

    // Filter data based on search term if there's a search term, otherwise return all data
    const filteredData = searchTerm
        ? data.filter(item => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                (item.id.toString().toLowerCase().includes(term)) ||
                (item.customerName.toString().toLowerCase().includes(term)) ||
                (item.mobileNo.toString().toLowerCase().includes(term))||
                (item.leadStatus.toString().toLowerCase().includes(term))
            );
        })
        : data.filter(item => activeDate === '' || item.date === activeDate);


    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle pagination
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate which page numbers to display (current, previous, next)
    const pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    // Get current items to display based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearch = (event) => {
        setCurrentPage(1);
        setSearchTerm(event.target.value);
    };

    const handleDelete = (dt) =>{
        return async() => {
            startLoading();
            const obj = {
                id: dt.id,
                tenantId: "string",
                customerId: 0,
                assignedTo: 0,
                leadStatus: 0,
                isActive: true,
                createdBy: 0,
                createdOn: "2024-08-18T11:39:06.946Z",
                lastModifiedBy: 0,
                lastModifiedOn: "2024-08-18T11:39:06.946Z",
                deletedOn: "2024-08-18T11:39:06.946Z",
                deletedBy: 0
            }
            await DeleteLead(obj);
            stopLoading();
            window.location.reload();
        }
    }

    const handleNavigate = (item, event) => {
        event.preventDefault();
        navigate(`/leads/editleads/${item.id}`); // Pass item as state
    };

    return (
        <div className='pagewrapper'>
            <div className='leads'>
                <div className="topLeads">
                    <div className="serachDrop">
                        <div className="dropdown label3 tablePageStatus">
                            <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                            <div className="dropdown-content">
                                {itemsPerPageOptions.map(option => (
                                    <div>
                                        <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                    </div>
                    <div className="topLeadButtons">
                        <div className="topLeadButton">
                            <ImportCsv name={
                                <Button variant="smallButton" prefixIcon={<SVGIcons.DocumentUpload fill='none' />}>Import Leads</Button>
                            } />
                        </div>
                        <div className="topLeadButton">
                            <Button variant="smallButton" prefixIcon={<SVGIcons.DocumentDownload fill='none' />} onClick={exportToCSV}>Export Leads</Button>
                        </div>
                        <div className="topLeadButtonAdd">
                            <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => navigate("/leads/addleads")}>Add Leads</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="table-wrapper">
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                                            Lead id <PiCaretUpDownFill size={12} />
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Lead Name <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Date <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Contact No. <PiCaretUpDownFill />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Status <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                                            <div className="leadsTH">
                                                Action <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td className='label2'>{item.id}</td>
                                            <td className='label2'>
                                                <LeadInfo name={item.customerName} item={item} />
                                            </td>
                                            <td className='label2'>{item.leadDate}</td>
                                            <td className='label2'>{item.mobileNo}</td>
                                            <td className='label2'>
                                                <LeadStatus status={item.leadStatus} />
                                            </td>
                                            <td className='actionsTable'>
                                                <SVGIcons.Edit onClick={(event) => handleNavigate(item, event)} />
                                                <SVGIcons.Trash onClick={handleDelete(item)}/>
                                                <LeadFollowUp name={<SVGIcons.UserSearch />} item={item} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='leadsTableBottom'>
                            <div className="leadsTableBottomLeft">
                                <span className='body2 tablePageStatus'>
                                    Showing {rangeStart} to {rangeEnd} of {data.length} results
                                </span>

                            </div>

                            <div className="leadsTablePaginationButtons">
                                <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                    <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                </button>

                                {pageNumbersToDisplay.map((pageNumber) => (
                                    <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                        {pageNumber}
                                    </button>
                                ))}
                                <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                    <MdOutlineKeyboardDoubleArrowRight size={28} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


