// import React, { Suspense } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { SidebarProvider } from './Services/ContextAPI/sidebarContext';
// import { DateProvider } from './Services/ContextAPI/dateContext';
// import SnackBar from './Common/snackBar/snackBar.tsx';
// import routes from "./routes.js";
// import ErrorBoundary from "./ErrorBoundary";;
// import PrivateRoutes from "./Presentation/Pages/ProtectedRoutes/PrivateRoutes";
// import { LoginPage } from "./Presentation/Pages/loginPage/loginPage.jsx";
// import { RegisterPage } from "./Presentation/Pages/register/register.jsx";
// import { ForgotPassword } from "./Presentation/Pages/ForgotPassword/forgotPassword.jsx";
// import NotFound from "./Common/404/index.jsx";
// import { LoadingProvider } from "./Services/ContextAPI/loadingContext.jsx";
// import PageLoader from "./Common/PageLoader/index.jsx";



import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SidebarProvider } from './Services/ContextAPI/sidebarContext';
import { DateProvider } from './Services/ContextAPI/dateContext';
import SnackBar from './Common/snackBar/snackBar.tsx';
import routes from "./routes.js";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoutes from "./Presentation/Pages/ProtectedRoutes/PrivateRoutes";
import { LoginPage } from "./Presentation/Pages/loginPage/loginPage.jsx";
import { RegisterPage } from "./Presentation/Pages/register/register.jsx";
import { ForgotPassword } from "./Presentation/Pages/ForgotPassword/forgotPassword.jsx";
import NotFound from "./Common/404/index.jsx";
import { LoadingProvider } from "./Services/ContextAPI/loadingContext.jsx";
import PageLoader from "./Common/PageLoader/index.jsx";

function App() {
  return (
    <LoadingProvider>
      <SidebarProvider>
        <DateProvider>
          <div className="App">
            <Router>
            <SnackBar />
              <ErrorBoundary>
              <Suspense fallback={<PageLoader />}>
                  <Routes>
                    <Route path="" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="*" element={<NotFound />} />
                    {routes.map((route, index) => (
                      <Route path={route.path} key={index}
                      element={<PrivateRoutes requiredPermission={{ menuName: route.menu, permissionName: route.permission, isProtected: route.isProtected }}  Component={route.component} />}
                      />
                    ))}
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </div>
        </DateProvider>
      </SidebarProvider>
    </LoadingProvider>
  );
}

export default App;
















// ajay code
// import React, { Suspense } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { SidebarProvider } from './Services/ContextAPI/sidebarContext';
// import { DateProvider } from './Services/ContextAPI/dateContext';
// import SnackBar from './Common/snackBar/snackBar.tsx';
// import routes from "./routes.js";
// import ErrorBoundary from "./ErrorBoundary";
// import PrivateRoutes from "./Presentation/Pages/ProtectedRoutes/PrivateRoutes";
// import { LoginPage } from "./Presentation/Pages/loginPage/loginPage.jsx";
// import { RegisterPage } from "./Presentation/Pages/register/register.jsx";
// import { ForgotPassword } from "./Presentation/Pages/ForgotPassword/forgotPassword.jsx";
// import NotFound from "./Common/404/index.jsx";
// import { LoadingProvider } from "./Services/ContextAPI/loadingContext.jsx";
// import PageLoader from "./Common/PageLoader/index.jsx";

// function App() {
//   return (
//     <LoadingProvider>
//       <SidebarProvider>
//         <DateProvider>
//           <div className="App">
//             <Router>
//               <SnackBar />
//               <ErrorBoundary>
//                 <Suspense fallback={<PageLoader />}>
//                   <Routes>
//                     <Route path="" element={<LoginPage />} />
//                     <Route path="/register" element={<RegisterPage />} />
//                     <Route path="/forgotpassword" element={<ForgotPassword />} />
//                     <Route path="*" element={<NotFound />} />
//                     {routes.map((route, index) => (
//                       <Route 
//                         path={route.path} 
//                         key={index}
//                         element={
//                           <PrivateRoutes 
//                             requiredPermission={{ 
//                               menuName: route.menu, 
//                               permissionName: route.permission, 
//                               isProtected: route.isProtected 
//                             }}  
//                             Component={route.component} 
//                           />
//                         } 
//                       />
//                     ))}
//                   </Routes>
//                 </Suspense>
//               </ErrorBoundary>
//             </Router>
//           </div>
//         </DateProvider>
//       </SidebarProvider>
//     </LoadingProvider>
//   );
// }

// export default App;

