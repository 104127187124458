import React, { useState, useEffect } from "react";
import close from "../../Assets/png/close-square.png"
import Button from "../../../Common/shared/Button/button";
import Dropdown from "react-dropdown";
import { GetAllDrops, CreateDropValueByType } from "../../../Services/DropService.ts";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";

function AddDropdown({ name, onClose }) {
    const [displayModal, setDisplayModal] = useState(false);
    const [sform, setSform] = useState(false)
    const [defaultOption, setdefaultOption] = useState("")
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [option, setoption] = useState();
    const { startLoading, stopLoading } = useLoading();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchDrops = async () => {
            try {
                startLoading();
                const result = await GetAllDrops();
                if (result) {
                    if (Array.isArray(result)) {
                        setOptions(result.map(x => x.typeName));
                    }
                }
              } catch (error) {
                console.error("Error fetching drops:", error);
              } finally {
                stopLoading();
              }
        }

        fetchDrops();
    }, []);

    const changeOption = (e) => {
        setoption(e.target.value)
    }

    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
        setdefaultOption(selected);
    };

    const handleClose = () => {
        setSform(false)
        setDisplayModal(!displayModal);
    }

    const handleSave = async() => {
        setSform(true);
        if (selectedOption !== "" && option !== "") {
            var res = await CreateDropValueByType({typeName: selectedOption.value, values: [{ value01: option, value02: option }]});
            if(!res) snackBarStore.showSnackBar(`Drop Saved Successfully`, "success");
            setDisplayModal(false);
            onClose();
        }
    }

    return (
        <>
            <div className="App2">

                <div
                    className="Button2"
                    onClick={() => setDisplayModal(!displayModal)}
                >
                    {name}
                </div>

                <div className={`leadModal-2 ${displayModal ? "leadShow-2" : ""} `} style={{ height: "40vh", bottom: "30%" }}>
                    <div
                        className="leadinfo-Top-2"
                    >
                        <h6 className="leadInfo-heading">Add Services</h6>
                        <img src={close} alt="" className="leadinfo-closebutton" onClick={handleClose} />
                    </div>
                    <div className="addServ-content">


                        <div className="addServ-content2 addServ-content3" style={{ height: "auto", padding: "20px", display: "flex", gap: "20px" }}>
                            <div className="addServ-input1" style={{ width: "100%" }}>
                                <label htmlFor="dropdown">Select Dropdown</label>
                                <Dropdown options={options}
                                    className={`dropdown-fu dropdown-leads ${(sform && selectedOption === "") ? 'error-border' : ''}`}
                                    onChange={handleDropdownChange}
                                    value={defaultOption} placeholder="Select Status"
                                />
                                {(sform && selectedOption === "") && <span className="errorLogin label3">Dropdown is required</span>}
                            </div>
                            <div className="addServ-input1" style={{ width: "100%" }}>
                                <label htmlFor="Option">Add Option</label>
                                <input
                                    placeholder="Insurance"
                                    className={`input10 ${(sform && option === "") ? 'error-border' : ''}`}
                                    type='option'
                                    value={option}
                                    onChange={changeOption}
                                />
                                {(sform && option === "") && <span className="errorLogin label3">Option is required</span>}
                            </div>
                        </div>
                        <div className="fu-submit-cancel">
                            <Button variant="mediumOutline" onClick={() => setDisplayModal(!displayModal)}>Cancel</Button>
                            <Button variant="mediumButton" onClick={handleSave} >Submit</Button>
                        </div>
                    </div>
                </div>
                <div
                    className={`Overlay ${displayModal ? "Show" : ""}`}
                    onClick={() => setDisplayModal(!displayModal)}
                />
            </div>
        </>
    );
}


export default AddDropdown;