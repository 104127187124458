import { Cities, Countries, States } from "../Domain/CountryStateCity/CascadeCountryStateCity.ts";
import httpClient from "./httpClient.ts";
import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";

  export const GetCountries = async (): Promise<Array<Partial<Countries>> | any> => {
    try {
      const { data } = await httpClient.get("cascadecountrycitystate/getcountries");
      return data;
    } catch (error) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
    }
  };

  export const GetStatesByCountryId = async (countryId: number): Promise<Array<Partial<States>> | any> => {
    try {
      const { data } = await httpClient.get(`cascadecountrycitystate/getstatesbycountryid?CountryId=${countryId}`);
      return data;
    } catch (error) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
    }
  };

  export const GetCityByStateId = async (stateId: number): Promise<Array<Partial<Cities>> | any> => {
    try {
      const { data } = await httpClient.get(`cascadecountrycitystate/getcitiesbystateid?StateId=${stateId}`);
      return data;
    } catch (error) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
    }
  };