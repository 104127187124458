import React, { useState, useEffect } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import Dropdown from "react-dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { GetDropValues } from "../../../Services/DropService.ts";
import { GetAllUsers } from "../../../Services/authService.ts";
import { UpdateTask } from "../../../Services/taskService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import DownloadFile from "../../../Common/shared/downloadFile/downloadFiles.jsx";
import { GetApiForImg } from "../../../Utils/userAuth.ts";
import { GetAllClients } from "../../../Services/clientService.ts";

export default function EditTask() {
    const location = useLocation();
    const item = location.state?.item;
    const navigate = useNavigate();
    const [formS, setFormS] = useState(false);
    const [taskTypes, setTaskTypes] = useState([]);
    const [taskTo, setTaskto] = useState([]);
    const [clients, setClients] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const { startLoading, stopLoading } = useLoading();
    const [fileUrl, setFileUrl] = useState(null);
    const apiUrl = GetApiForImg();
    const [defaultOption, setDefaultOption] = useState({
        taskTypeOption: null,
        taskToOption: null,
        clientOption: null,
        priorityOption: null,
    });

    const handleDropdownChange = (dropDwnId, selected) => {
        setDefaultOption((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileUrl({name: file.name, extension: `.${file.name.split('.').pop()}`, data: reader.result});
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const fetchTasktype = async () => {
            try {
                startLoading();
                const result = ["Lead", "Client"];
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskTypes(result.map((res) => ({
                            value: res,
                            label: res,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchTaskTo = async () => {
            try {
                startLoading();
                const result = await GetAllUsers();
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskto(result.filter(x => x.roleName != "SuperAdmin").map((res) => ({
                            value: res.user.id,
                            label: `${res.user.firstName} ${res.user.lastName}`,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchPriorities = async () => {
            try {
                startLoading();
                const result = await GetDropValues("Priority");
                if (result) {
                    if (Array.isArray(result)) {
                        setPriorities(result.map((res) => ({
                            value: res.value01,
                            label: res.value01,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchClients = async () => {
            try {
                startLoading();
                const result = await GetAllClients()
                if (result) {
                    if (Array.isArray(result)) {
                        setClients(result.map((res) => ({
                            value: res.id,
                            label: res.id,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        fetchTasktype();
        fetchClients();
        fetchPriorities();
        fetchTaskTo();
    }, []);

    useEffect(() => {
        if (item) {
            setValue("taskName", item.taskName);
            setValue("taskType", item.taskType);
            setValue("taskTo", handleDropdownChange("taskToOption", {value: item.taskTo, label: item.assignedTo}));
            setValue("clientId", handleDropdownChange("clientOption", {value: item.clientId, label: item.clientName}));
            setValue("dueDate", item.dueDate.slice(0, 10));
            setValue("serviceRequest", item.serviceRequest);
            setValue("startDate", item.startDate.slice(0, 10));
            setValue("reminderDate", item.reminderDate.slice(0, 10));
            setValue("priority", handleDropdownChange({value: item.priority, label: item.priority}));
            setValue("attachments", item.attachments);
            setValue("attachmentName", item.attachmentName);
            setValue("remarks", item.remarks);
        }
    }, [item, setValue]);

    const onSubmit = async (data) => {
        data.id = item.id;
        data.taskType = defaultOption.taskTypeOption?.value || item.taskType;
        data.taskStatus = "Inform Client";
        data.priority = defaultOption.priorityOption?.value || item.priority;
        data.dueDate = new Date(data.dueDate).toISOString();
        data.startDate = new Date(data.startDate).toISOString();
        data.reminderDate = new Date(data.reminderDate).toISOString();
        data.taskFrom = item.taskFrom;
        data.taskTo = defaultOption.taskToOption?.value || item.taskTo;
        data.clientId = defaultOption.clientOption?.value || item.clientId;
        if (fileUrl) {
            data.attachments = fileUrl;
        }
        try {
            startLoading();
            await UpdateTask(data);
        } catch (error) {
            snackBarStore.showSnackBar(`Error while Updating task: ${error}`, "error");
        }
        finally{
            stopLoading();
            navigate("/task");
        }
    };

    const handleSubmission = () => {
        setFormS(true);
        handleSubmit(onSubmit)();
    };

    return (
        <>
        <div className="add-leads-form">
            <div className="add-leads-text">
                <div className="personal-text">Task Detail</div>
            </div>
            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task Name</div>
                    <input
                        className={`add-leads-input ${errors.taskName ? "error-border" : ""}`}
                        placeholder="Add Task Name"
                        {...register("taskName", { required: "Task Name is required" })}
                    />
                    {errors.taskName && (
                        <span className="errorLogin label3">{errors.taskName.message}</span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task Type</div>
                    <Dropdown
                        options={taskTypes}
                        className={`dropdown-fu dropdown-leads ${formS && defaultOption.taskTypeOption ? "error-border" : ""}`}
                        onChange={(e) => handleDropdownChange("taskTypeOption", e)}
                        value={defaultOption.taskTypeOption || item.taskType}
                        placeholder="Select Task Type"
                    />
                    {formS && !defaultOption.taskTypeOption && <span className="errorLogin label3">Task Type required</span>}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task To</div>
                    <Dropdown options={taskTo}
                        className={`dropdown-fu dropdown-leads ${formS && defaultOption.taskToOption ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("taskToOption", e)}
                        value={defaultOption.taskToOption} placeholder="Select Person"
                    />
                    {formS && !defaultOption.taskToOption && <span className="errorLogin label3">Task To required</span>}
                </div>
            </div>
            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Client</div>
                    <Dropdown options={clients}
                        className={`dropdown-fu dropdown-leads ${formS && defaultOption.clientOption ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("clientOption", e)}
                        value={defaultOption.clientOption} placeholder="Select Client"
                    />
                   {formS && !defaultOption.clientOption && <span className="errorLogin label3">Client is required</span>}
                </div>
                <div className={`add-leads-input-container ${errors.dueDate ? " eb1" : ""}`}>
                    <div className="add-leadlabel">Due Date</div>
                    <input
                        type="date"
                        className={`input-fu-1 add-leads-input input10 ${errors.dueDate ? "error-border eb" : ""}`}
                        {...register("dueDate", { required: "Due Date is required" })}
                    />
                    {errors.dueDate && <div className="errorLogin label3">{errors.dueDate.message}</div>}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Priority</div>
                    <Dropdown
                        options={priorities}
                        className={`dropdown-fu dropdown-leads ${formS && defaultOption.priorityOption ? "error-border" : ""}`}
                        onChange={(e) => handleDropdownChange("priorityOption", e)}
                        value={defaultOption.priorityOption || item.priority}
                        placeholder="Select Priority"
                    />
                    {formS && !defaultOption.priorityOption && <span className="errorLogin label3">Priority is required</span>}
                </div>
            </div>
            <div className="add-leads-addres">
                <div className="add-leadlabel">Service Request</div>
                <textarea
                    style={{ resize: "none", border: "2px solid #ccc" }}
                    rows={5}
                    className={`serivce-label ${errors.serviceRequest ? "error-border" : ""}`}
                    placeholder="Service Request"
                    {...register("serviceRequest", { required: "Service Request is required" })}
                />
                {errors.serviceRequest && (
                    <span className="errorLogin label3">{errors.serviceRequest.message}</span>
                )}
            </div>
            <div className="add-leads-text">
                <div className="personal-text">Additional Detail</div>
            </div>
            <div className="add-leads-inputbox">
                <div className={`add-leads-input-container ${errors.startDate ? " eb1" : ""}`}>
                    <div className="add-leadlabel">Start Date</div>
                    <input
                        type="date"
                        className={`input-fu-1 add-leads-input input10 ${errors.startDate ? "error-border eb" : ""}`}
                        {...register("startDate", { required: "Start Date is required" })}
                    />
                    {errors.startDate && <div className="errorLogin label3">{errors.startDate.message}</div>}
                </div>
                <div className={`add-leads-input-container ${errors.reminderDate ? " eb1" : ""}`}>
                    <div className="add-leadlabel">Reminder Date</div>
                    <input
                        type="date"
                        className={`input-fu-1 add-leads-input input10 ${errors.reminderDate ? "error-border eb" : ""}`}
                        {...register("reminderDate", { required: "Reminder Date is required" })}
                    />
                    {errors.reminderDate && <div className="errorLogin label3">{errors.reminderDate.message}</div>}
                </div>
                <div className={`add-leads-input-container ${errors.attachments ? " eb1" : ""}`}>
                    <div className="add-leadlabel">Attachment</div>
                    {(item.attachmentName != '') && <DownloadFile fileUrl={`${apiUrl}/${item.attachmentName}`} />}
                    <input
                        type="text"
                        onFocus={(e) => e.target.type = 'file'}
                        onBlur={(e) => e.target.type = 'text'}
                        style={{ width: "100% !important" }}
                        className={`input-fu-1 add-leads-input input10 ${errors.attachments ? 'error-border eb' : ''}`}
                        placeholder="Upload file here"
                        {...register("attachments")}
                        onChange={handleFileChange}
                        aria-invalid={errors.attachments ? 'true' : 'false'}
                    />
                </div>
            </div>
            <div className="add-leads-addres"></div>
            <div className="add-leads-addres">
                <div className="add-leadlabel">Remarks</div>
                <textarea
                    style={{ resize: "none", border: "2px solid #ccc" }}
                    rows={5}
                    className={`serivce-label ${errors.remarks ? "error-border" : ""}`}
                    placeholder="Remarks"
                    {...register("remarks", { required: "Remarks is required" })}
                />
                {errors.remarks && (
                    <span className="errorLogin label3">{errors.remarks.message}</span>
                )}
            </div>
            <div className="add-leads-button">
                <Button variant="smallButton" onClick={() => navigate("/task")}>Back</Button>
                <Button
                    type="submit"
                    variant="smallButton"
                    onClick={handleSubmission}
                >
                    Submit
                </Button>
            </div>
        </div>
        </>
    );
}
