import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { ClientMasterDTO } from "../Domain/Clients/client.ts";
import httpClient from "./httpClient.ts"

export const GetAllClients = async () : Promise<Array<Partial<ClientMasterDTO>>> => {
    try {
        const { data } = await httpClient.get("client/getlist/get-all");
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}