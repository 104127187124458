import React, { useState } from "react";
import { observer } from "mobx-react";
import snackBarStore from "./store/snackbarStore.ts";
import close from "./../../Presentation/Assets/png/close-square.png";
import "./snakBar.css"

const SnackBar = observer(() => {
  const { text, show, urgency, hide } = snackBarStore;
  if (!show) return null;
  return (
    <div className={`snackbar snackbar__${urgency}`}>
      <span>{text}</span>
      <img src={close} alt="" className="leadinfo-closebutton" onClick={hide} />
    </div>
  );
});

export default SnackBar;