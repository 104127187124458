import React, { useState } from "react";
import close from "../../Assets/png/close-square.png"
import Multiselect from 'multiselect-react-dropdown';
import { PiCaretUpDownFill } from "react-icons/pi";
import { useForm } from "react-hook-form";
import SVGIcons from "../../../Common/SvgComponents/index";
import Button from "../../../Common/shared/Button/button";


function AddServicesClient({ name }) {
    const [displayModal, setDisplayModal] = useState(false);
    const [sform, setSform] = useState(false)
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const options = [
        { id: 1, name: 'Option 1' },
        { id: 2, name: 'Option 2' },
        { id: 3, name: 'Option 3' },
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };


    const handleRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
    };
    const onSubmit = (data) => {
        // Your form submission logic here
        console.log(data);
    };

    const handleAddButtonClick = () => {
        setSform(true); // Set sform to true
        handleSubmit(onSubmit)(); // Submit the form
    };

    const handleClose = () => {
        setSform(false)
        setDisplayModal(!displayModal);
    }

    return (
        <div className="App2">

            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-2 ${displayModal ? "leadShow-2" : ""} `} style={{width:"80%", marginRight:"10%"}}>
                <div
                    className="leadinfo-Top-2"
                >
                    <h6 className="leadInfo-heading">Add Services</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={handleClose} />
                </div>
                <div className="addServ-content">
                    <div className="addServ-content2">
                        <div className="addServ-input1" style={{ marginBottom: "20px" }}>
                            <div className="label label-1">Services</div>

                            <Multiselect
                                // customCloseIcon={<>
                                //     <MdClose />
                                // </>}
                                options={options} // Options to display in the dropdown
                                selectedValues={selectedOptions} // Pre-selected values (optional)
                                onSelect={handleSelect} // Function to handle selection
                                onRemove={handleRemove} // Function to handle removal
                                displayValue="name" // Property of options to display as label
                                showCheckbox={true}
                                avoidHighlightFirstOption={true}
                                placeholder="Select Sub Category"
                                className="div1"
                            />

                            {(selectedOptions.length === 0 && sform) && (
                                <div className="errorLogin label3">Service is required</div>
                            )}
                        </div>


                        <div className="addServ-c1" style={{ marginBottom: "20px" }}>
                            <div className="add-leads-input-container" style={{ width: "100%" }}>
                                <div className="add-leadlabel">Customer Goal</div>
                                <input
                                    className={`add-leads-input ${errors.Svname ? "error-border" : ""
                                        }`}
                                    placeholder="Child education"
                                    type="text"
                                    {...register("Svname", { required: "SIP Value is required" })}

                                />
                                {errors.Svname && (
                                    <span className="errorLogin label3">
                                        {errors.Svname.message}
                                    </span>
                                )}
                            </div>
                            <div className="add-leads-input-container" style={{ width: "100%" }}>
                                <div className="add-leadlabel">SIP Value</div>
                                <input
                                    className={`add-leads-input ${errors.Svname ? "error-border" : ""
                                        }`}
                                    placeholder="₹ 10000"
                                    type="number"
                                    {...register("Svname", { required: "SIP Value is required" })}

                                />
                                {errors.Svname && (
                                    <span className="errorLogin label3">
                                        {errors.Svname.message}
                                    </span>
                                )}
                            </div>

                        </div>
                        <div className="addServ-c1">
                            <div className="add-leads-input-container" style={{ width: "100%" }}>
                                <div className="add-leadlabel">Customer Service Specification</div>
                                <input
                                    className={`add-leads-input ${errors.Svname ? "error-border" : ""
                                        }`}
                                    placeholder="Demat"
                                    type="text"
                                    {...register("Svname", { required: "SIP Value is required" })}

                                />
                                {errors.Svname && (
                                    <span className="errorLogin label3">
                                        {errors.Svname.message}
                                    </span>
                                )}
                            </div>
                            <div className={`add-leads-input-container ${errors.dates ? ' eb1' : ''}`}>
                                <div className="add-leadlabel">
                                    Completion Date / Last Connection Date
                                </div>
                                <input
                                    type="text"
                                    onFocus={(e) => e.target.type = 'date'}
                                    onBlur={(e) => e.target.type = 'text'}
                                    style={{ width: "100% !important" }}
                                    className={`input-fu-1 add-leads-input input10 ${errors.dates ? 'error-border eb' : ''}`}
                                    placeholder="Date"
                                    {...register("dates", {
                                        required: 'date is required',
                                    })}
                                    aria-invalid={errors.dates ? 'true' : 'false'}
                                />
                                {errors.dates && <div className="errorLogin label3">{errors.dates.message}</div>}
                            </div>

                        </div>

                        <div className="addServ-input2">
                            <div className="label-1 label">Remark</div>
                            <textarea placeholder="Enter comment here" className="addServ-textarea" rows={5}
                                {...register("Remark", {
                                    required: 'Remark is required',
                                })}
                                aria-invalid={errors.Remark ? 'true' : 'false'}
                            />
                            {errors.Remark && <div className="errorLogin label3">{errors.Remark.message}</div>}
                        </div>
                        <Button variant="smallButton" onClick={handleAddButtonClick}>Add</Button>
                        <table className='leadsTable roundedcorner addserv-table' id='leadsTable' >
                            <thead>
                                <tr className='body2 roundedcorner'>
                                    <th style={{ borderRadius: "10px 0px  0px  0px" }} className="vraj">
                                        <div className="leadsTH" >
                                            Dates <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th className="vraj">
                                        <div className="leadsTH">
                                            Services <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th className="vraj">
                                        <div className="leadsTH">
                                            SIP value <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th className="vraj">
                                        <div className="leadsTH">
                                            Completion Date <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th >
                                        <div className="leadsTH" >
                                            Remark <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th style={{ borderRadius: "0px 10px  0px  0px" }}>
                                        <div className="leadsTH" style={{ borderRadius: "0px 10px  0px  0px" }}>
                                            Actions <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className='label2'>
                                        11-11-24
                                    </td>
                                    <td className='label2'>
                                        Insurance
                                    </td>
                                    <td className='label2'>
                                        1000/-
                                    </td>
                                    <td className='label2'>
                                        13-11-24
                                    </td>
                                    <td className='label2'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda at delectus, fuga id dicta dolore
                                    </td>
                                    <td className='actionsTable'>
                                        <SVGIcons.Trash />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="fu-submit-cancel">
                        <Button variant="mediumOutline" onClick={() => setDisplayModal(!displayModal)}>Cancel</Button>
                        <Button variant="mediumButton" onClick={() => setDisplayModal(!displayModal)}>Submit</Button>
                    </div>
                </div>
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
        </div>
    );
}


export default AddServicesClient;