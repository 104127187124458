import React from "react";
import "../leadStatus/leadStatus.css";

function getStatus(status) {
  switch (status) {
    case "Inform Client":
      return "#C1FDF6";
    case "Under Process":
      return "#FCEDB1";
    case "Mandate Pending":
      return "#8F47A3";
    case "Task Complete":
      return "#A3DB9E";
    case "Reject":
      return "#FF8A87";
    case "Waiting For Documents":
      return "#C9C2F1";

    default:
      return null;
  }
}
function getStatuscolor(status) {
  switch (status) {
    case "Inform Client":
      return "#000000";
    case "Under Process":
      return "#000000";
    case "Mandate Pending":
      return "#FFFFFF";
    case "Task Complete":
      return "#000000";
    case "Reject":
      return "#000000";
    case "Waiting For Documents":
      return "#000000";

    default:
      return null;
  }
}
export const TaskStatus = ({ status }) => {
  return (
    <div
      className="leadStatus label3"
      style={{
        backgroundColor: getStatus(status),
        color: getStatuscolor(status),
      }}
    >
      {status}
    </div>
  );
};