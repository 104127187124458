import React, { useState, useEffect } from "react";
import "./report.css";
import Button from "../../../Common/shared/Button/button";
import Dropdown from "react-dropdown";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { Search } from "../../../Common/shared/Search/search";
import { PiCaretUpDownFill } from "react-icons/pi";
import { LeadStatus } from "../../../Common/shared/leadStatus/leadStatus";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } 
from "react-icons/md";
import { ClientStatus } from "../../../Common/shared/clientStatus/clientStatus";
import { TaskStatusBar } from "../../../Common/shared/taskStatusBar/taskStatusBar";
import { TaskStatus } from "../../../Common/shared/taskStatus/taskStatus";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const options1 = ['Leads', 'Clients', 'Tasks'];
const options2 = {
  'Leads': ['Follow up', 'Meetings Pending', 'Meetings Schedule', 'Leads Lost', 'Leads Win'],
  'Clients': ['Mandate Approved', 'Under Process', 'Insurance Under Process', 'Demat Under Process', 'Mandate Pending', 'Case Decline', 'Reject', 'Payment Pending', 'Task Completed', 'Policy Issue', 'Insurance Pending', 'Waiting For Documents', 'Conversation Pending'],
  'Tasks': ["Inform Client", "Under Process", "Mandate Pending", "Task Complete", "Reject", "Waiting For Documents"]
};
const options3 = ['Mutual Fund', 'Demat', 'SIP', 'Insurance'];

export default function Reports() {
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [defaultOption1, setDefaultOption1] = useState(null);
  const [defaultOption2, setDefaultOption2] = useState(null);
  const [defaultOption3, setDefaultOption3] = useState(null);
  const [searched, setSearched] = useState(false);



  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [10, 15, 20, 25]; // Options for items per page

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let response = null;
    try {
      if (selectedOption1 === 'Leads') {
        response = await fetch('https://dev-d478mkay4qiodj5.api.raw-labs.com/api/json');
      }
      else if (selectedOption1 === 'Clients') {
        response = await fetch('https://dev-d478mkay4qiodj5.api.raw-labs.com/api/json');
      }
      else {
        response = await fetch('https://dev-d478mkay4qiodj5.api.raw-labs.com/api/json');
      }
      const jsonData = await response.json();
      setData(jsonData);
      // setData(dataMain);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

   const exportToCSV = () => {
    // Convert data to CSV format
    const csvContent = "data:text/csv;charset=utf-8," 
      + currentItems.map(item => Object.values(item).join(',')).join('\n');
    
    // Create a temporary link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${selectedOption1}_data.csv`);
    document.body.appendChild(link);

    // Simulate click to trigger download
    link.click();
  };

  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year
  const currentMonth = currentDate.getMonth(); // Get current month (0-indexed)

  // Get the first day of the current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  // Get the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  // Initial state with default start and end date of the current month
  const [dateRange, setDateRange] = useState([
    {
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      key: 'selection',
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Filter data based on search term if there's a search term, otherwise return all data
  let filteredData = searchTerm
    ? data.filter(item => {
      const searchTerms = searchTerm.toLowerCase().split(' ');
      return searchTerms.every(term =>
        (item.serialNumber.toString().toLowerCase().includes(term)) ||
        (item.name.toString().toLowerCase().includes(term))
      );
    })
    : data;
  // : data.filter(item => activeDate === '' || item.date === activeDate);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    // Close the picker only if both start and end dates are selected
    if (ranges.selection.startDate && ranges.selection.endDate) {
      setIsOpen(false);
    }
  };

  // Calculate total number of pages
  let totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Function to handle pagination
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate which page numbers to display (current, previous, next)
  let pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

  // let rangeStart = (currentPage - 1) * itemsPerPage + 1;
  // let rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

  // Get current items to display based on current page
  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;

  let currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (e) => {
    setSearched(true)
    setSearchTerm(e.target.value)
    console.log(selectedOption2, defaultOption2)
  };

  const reset = () => {
    setDefaultOption1(null);
    setSelectedOption1(null)
    setDefaultOption2(null)
    setSelectedOption2(null)
    setDefaultOption3(null)
    setSelectedOption3(true)
    setDateRange([
      {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
        key: 'selection',
      },
    ]);
    setSearched(false);
  }

  const handleDropdownChange1 = (selected) => {
    setSearched(false)
    setSelectedOption1(selected.value);
    setDefaultOption1(selected);
    setDefaultOption2(null)
    setDefaultOption3(null)
    setDateRange([
      {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
        key: 'selection',
      },
    ]);
  };

  const handleDropdownChange2 = (selected) => {
    setSelectedOption2(selected);
    setDefaultOption2(selected);
  };

  const handleDropdownChange3 = (selected) => {
    setSelectedOption3(selected);
    setDefaultOption3(selected);
  };

  // Define startDate and endDate based on dateRange
  const startDate = dateRange[0].startDate;
  const endDate = dateRange[0].endDate;

  let filteredItems = [];

  // Check if any filter or date range is applied
  if (selectedOption2 || selectedOption3 || startDate) {
    // Filter data based on all conditions
    filteredItems = filteredData.filter(item => {
      // Filter based on selectedOption2 (status)
      if (selectedOption1 === "Leads") {
        if (selectedOption2 && item.status !== selectedOption2.value) {
          return false;
        }
      }
      if (selectedOption1 === "Clients") {
        if (selectedOption2 && item.clientStatus !== selectedOption2.value) {
          return false;
        }
      }
      if (selectedOption1 === "Tasks") {
        if (selectedOption2 && item.taskStatus !== selectedOption2.value) {
          return false;
        }
      }
      // Filter based on selectedOption3 (services)
      if (defaultOption3 && !item.services.includes(selectedOption3.value)) {
        return false;
      }
      // Filter based on date range
      if (startDate && endDate) {
        const itemDate = new Date(item.date);
        // Convert start and end date strings to Date objects
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        // Check if item date is within the selected date range
        if (itemDate <= startDateObj || itemDate >= endDateObj) {
          return false;
        }
      }
      return true;
    });
    // Calculate total number of pages
    let totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    // Calculate which page numbers to display (current, previous, next)
    pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    // rangeStart = (currentPage - 1) * itemsPerPage + 1;
    // rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    // Get current items to display based on current page
    indexOfLastItem = currentPage * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  }
  return (
    <>
      <div className="reports-section">
        <div className="report-inputsection">
          <div className="report-inputbox">
            <div className="report-inputname">Type</div>
            <Dropdown
              options={options1}
              onChange={handleDropdownChange1}
              value={defaultOption1}
              placeholder="Select Type"
            />
          </div>
          <div className="report-inputbox">
            <div className="report-inputname" style={{ color: !selectedOption1 ? "#CCCCCC" : "#000" }}>Status</div>
            <Dropdown
              // options={selectedOption1 ? options2[selectedOption1.value] : []}
              options={selectedOption1 && options2[selectedOption1] ? options2[selectedOption1] : []}
              onChange={handleDropdownChange2}
              value={defaultOption2}
              placeholder="Select Status"
              disabled={!selectedOption1}
            />
          </div>
          <div className="report-inputbox">
            <div className="report-inputname" style={{ color: (!selectedOption1 || selectedOption1 === "Tasks") ? "#CCCCCC" : "#000" }}>Services</div>
            <Dropdown
              options={options3}
              onChange={handleDropdownChange3}
              value={defaultOption3}
              placeholder="Select Services"
              disabled={(!selectedOption1 || selectedOption1 === "Tasks")}
            />
          </div>
          <div className="add-leads-input-container">
            <div className="add-leadlabel" style={{ color: !selectedOption1 ? "#CCCCCC" : "#000" }}>Date</div>
            <div className="single-date-range-picker">
              <input
                type="text"
                value={`${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`}
                onClick={handleToggle}
                readOnly // Prevent manual editing
                disabled={!selectedOption1}
                className="date-input" // Apply custom CSS class for input styling
              />
              {isOpen && (
                <DateRangePicker
                  ranges={dateRange}
                  onChange={handleSelect}
                  editableDateInputs={true}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  direction="horizontal"
                />
              )}
            </div>
          </div>
        </div>
        <div className="report-searchbutton-container">
          <div className="report-searchbutton" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
            <Button variant="smallButton" onClick={reset}>Reset</Button>
            <Button variant="smallButton" onClick={handleSearch}>Search</Button>
          </div>
        </div>
      </div>

      <div className="report-sectiondata" style={{ marginBottom: "100px" }}>
        <div className="section-data-top">
          <div className="report-searchbar">
            <Search handleSearch={handleSearch} searchTerm={searchTerm} />
          </div>
          <div className="report-exportbutton">
            <Button disabled={!searched} variant="smallButton" prefixIcon={<SVGIcons.DocumentDownload />} onClick={exportToCSV}>
              Export
            </Button>
          </div>
        </div>

        {(selectedOption1 === "Leads" && searched) &&
          <div className="table-wrapper" style={{ width: "100%" }}>
            <table className='leadsTable roundedcorner' id='leadsTable' >
              <thead>
                <tr className='body2 roundedcorner'>
                  <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}
                    >
                      Lead Id <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Lead Name <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Date <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Contact No. <PiCaretUpDownFill />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Email <PiCaretUpDownFill />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Services <PiCaretUpDownFill />
                    </div>
                  </th>
                  <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                    <div className="leadsTH">
                      Status <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td className='label2'>{item.serialNumber}</td>
                      <td className='label2'>{item.name}</td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>{item.phoneNumber}</td>
                      <td className='label2'>{item.email}</td>
                      <td className='label2'>{item.services.join(' , ')}</td>
                      <td className='label2'>
                        <LeadStatus status={item.status} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        }
        {(selectedOption1 === "Clients" && searched) &&
          <div className="table-wrapper">
            <table className='leadsTable roundedcorner' id='leadsTable'>
              <thead>
                <tr className='body2 roundedcorner'>
                  <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                    Id <PiCaretUpDownFill size={12} />
                  </th>
                  <th>
                    <div className="leadsTH">
                      Customer Name <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Date <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Contact No. <PiCaretUpDownFill />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Review Date <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Services <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                    <div className="leadsTH">
                      Status <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(selectedOption2 || selectedOption3)
                  ?
                  filteredData.filter(item => {
                    // Filter based on selectedOption2 (status)
                    if (selectedOption2 && item.clientStatus !== selectedOption2.value) {
                      return false;
                    }
                    // Filter based on selectedOption3 (services)
                    if (selectedOption3 && !item.services.includes(selectedOption3.value)) {
                      return false;
                    }
                    return true;
                  }).map((item, index) => (
                    <tr key={index}>
                      <td className='label2'>{item.serialNumber}</td>
                      <td className='label2'>
                        {item.name}

                      </td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>{item.phoneNumber}</td>
                      <td className='label2'>{item.services.join(' , ')}</td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>
                        <ClientStatus status={item.clientStatus} />
                      </td>
                    </tr>
                  ))
                  :
                  currentItems.filter(item => {
                    // Filter based on selectedOption2 (status)
                    if (selectedOption2 && item.clientStatus !== selectedOption2.value) {
                      return false;
                    }
                    // Filter based on selectedOption3 (services)
                    if (selectedOption3 && !item.services.includes(selectedOption3.value)) {
                      return false;
                    }
                    return true;
                  }).map((item, index) => (
                    <tr key={index}>
                      <td className='label2'>{item.serialNumber}</td>
                      <td className='label2'>
                        {item.name}

                      </td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>{item.phoneNumber}</td>
                      <td className='label2'>{item.services.join(' , ')}</td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>
                        <ClientStatus status={item.clientStatus} />
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        }
        {(selectedOption1 === "Tasks" && searched) &&
          <div className="table-wrapper" style={{ width: "100%" }}>
            <table className='leadsTable roundedcorner' id='leadsTable' style={{ width: "100%" }}>
              <thead>
                <tr className='body2 roundedcorner'>
                  <th style={{ borderRadius: "9px 0px  0px  0px", display: "flex", alignItems: "center" }}>
                    Task ID <PiCaretUpDownFill size={12} />
                  </th>
                  <th>
                    <div className="leadsTH">
                      Task Name <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Indicator <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Task To <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Task From <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Start Date <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th>
                    <div className="leadsTH">
                      Due Date <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                  <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                    <div className="leadsTH">
                      Action <PiCaretUpDownFill size={12} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => {
                  const [taskTo, taskFrom] = item.name.split(" ");
                  return (
                    <tr key={index}>
                      <td className='label2'>{item.serialNumber}</td>
                      <td className='label2'>
                        {item.name}
                      </td>
                      <td className='label2'>
                        <TaskStatusBar status={item.taskStatus} />
                      </td>
                      <td className='label2'>{taskTo}</td>
                      <td className='label2'>{taskFrom}</td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>{item.date}</td>
                      <td className='label2'>
                        <TaskStatus status={item.taskStatus} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        }
        {(selectedOption1 && filteredItems.length > 9 && searched) &&
          <div className='leadsTableBottom' style={{ justifyContent: "space-between", width: "100%" }}>
            <div className="leadsTableBottomLeft">
              {/* <span className='body2 tablePageStatus'>
                Showing {rangeStart} to {rangeEnd} of {data.length} results
              </span> */}

            </div>

            <div className="leadsTablePaginationButtons">
              <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                <MdOutlineKeyboardDoubleArrowLeft size={28} />
              </button>

              {pageNumbersToDisplay.map((pageNumber) => (
                <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                  {pageNumber}
                </button>
              ))}
              <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                <MdOutlineKeyboardDoubleArrowRight size={28} />
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
}
