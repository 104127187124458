import React, { useState, useEffect } from 'react';
import Main from '../main/main';
import Sidebar from '../Sidebar/sidebar';
import './layout.css';
import { Dashbaord } from '../../Presentation/Pages/dashboard/dashbaord';
import { useAuth } from "../../Utils/userAuth.ts";
import { useNavigate } from 'react-router-dom';

function Layout(children) {
  const navigate = useNavigate();
  const isLoggedIn = useAuth();
  const [sidebarWidth, setSidebarWidth] = useState('15%');
  const [mainWidth, setMainWidth] = useState('85%');
  const [sidebarSmall, setSidebarSmall] = useState(false)

  useEffect(() => {
    if (!isLoggedIn) {
        navigate('/');
    }
}, [navigate]);

  const toggleSidebar = () => {
    if (sidebarWidth === '15%') {
      setSidebarWidth('5%');
      setMainWidth('95%');
      setSidebarSmall(true);
    } else {
      setSidebarWidth('15%');
      setMainWidth('85%');
      setSidebarSmall(false);
    }
  };

  return (
    <div className="layoutContainer">
      <Main width={mainWidth} toggleSidebar={toggleSidebar} children={<Dashbaord/>} top="Dashboard"/>
      <Sidebar width={sidebarWidth} sidebarSmall={sidebarSmall} active="Dashboard"/>
    </div>
  );
}

export default Layout;
