import React from 'react'
import "./leadStatus.css"

function getStatus(status) {
    switch (status) {
        case "New Lead":
            return "#C6D8F3"
        case "Follow Up":
            return "#C1FDF6"
        case "Meetings Pending":
            return "#FCEDB1"
        case "Meetings Schedule":
            return "#8F47A3"
        case "Leads Win":
            return "#A3DB9E"
        case "Leads Lost":
            return "#F1C3C2"
        case "Convert to Client":
            return "#aef2b3"
        default:
            return null;
    }
}
function getStatuscolor(status) {
    switch (status) {
        case "New Lead":
            return "#000000"
        case "Follow Up":
            return "#000000"
        case "Meetings Pending":
            return "#000000"
        case "Meetings Schedule":
            return "#FFFFFF"
        case "Leads Win":
            return "#000000"
        case "Leads Lost":
            return "#000000"
        case "Convert to Client":
            return "#000000"
        default:
            return null;
    }
}
export const LeadStatus = ({ status }) => {
    return (
        <div className='leadStatus label3' style={{backgroundColor: getStatus(status), color:getStatuscolor(status)}}>
            {status}
        </div>
    )
}
