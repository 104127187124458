import React, { createContext, useContext, useState, useEffect } from 'react';
import PageLoader from '../../Common/PageLoader';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  const startLoading = () => {
    setLoadingCount((prevCount) => prevCount + 1);
  };

  const stopLoading = () => {
    setLoadingCount((prevCount) => Math.max(prevCount - 1, 0));
  };

  useEffect(() => {
    if (loadingCount === 0) {
      setInitialLoad(false);
    }
  }, []);

  return (
    <LoadingContext.Provider value={{ loadingCount, startLoading, stopLoading }}>
      {children}
      {(loadingCount > 0 || initialLoad) && <PageLoader />}
    </LoadingContext.Provider>
  );
};