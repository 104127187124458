import React from "react";

const SVGImage = ({
  svgContent,
  width,
  height,
  containerMargin,
  fill,
  stroke,
}) => {
  let adjustedSvgContent = svgContent;

  if (fill) {
    adjustedSvgContent = svgContent.replace(/fill="[^"]*"/g, `fill="${fill}"`);
  }
  if (stroke) {
    adjustedSvgContent = adjustedSvgContent.replace(
      /stroke="[^"]*"/g,
      `stroke="${stroke}"`
    );
  }

  // Setting the inner HTML using dangerouslySetInnerHTML
  const createMarkup = () => {
    return { __html: adjustedSvgContent };
  };

  const containerStyle = {
    marginLeft: containerMargin,
    // Add other styles if needed
  };

  return (
    <div style={containerStyle}>
      <div style={{ width, height }} dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

export default SVGImage;
