import '../leads/leads.css'
import { Search } from '../../../Common/shared/Search/search'
import Button from '../../../Common/shared/Button/button'
import SVGIcons from '../../../Common/shared/Icons/svgicons'
import React, { useState, useEffect } from 'react';
import "../leads/table.css";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaAngleDown } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';
import { fetchAllRoles, DeleteRole } from "../../../Services/authService.ts";
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';

export const Role = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [10, 15, 20, 25];
    const navigate = useNavigate();
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            startLoading();
            var jsonData = await fetchAllRoles();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            stopLoading();
        }
    };

    const filteredData = searchTerm
        ? data.filter(item => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                (item.role.toString().toLowerCase().includes(term)) 
            );
        })
        : data;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleNavigate = (item, event) => {
        event.preventDefault();
        navigate('/role/editrole/role', { state: { item } });
    };
    const handleDelete = async (item, event) => {
        event.preventDefault();
        await DeleteRole(item);
        await fetchData();
    };
    const handleNavigate2 = (item, event) => {
        event.preventDefault();
        navigate(`/role/permissions/${item.id}`, { state: { item } });
    };
    return (
        <div className='pagewrapper'>
            <div className='leads'>
                <div className="topLeads">
                    <div className="serachDrop">
                        <div className="dropdown label3 tablePageStatus">
                            <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                            <div className="dropdown-content">
                                {itemsPerPageOptions.map(option => (
                                    <div>
                                        <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                    </div>
                    <div className="topLeadButtons">
                        <div className="topLeadButtonAdd">
                            <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => navigate("/role/addrole")}>Add Role</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <table className='leadsTable roundedcorner' id='leadsTable'>
                            <thead>
                                <tr className='body2 roundedcorner'>
                                    <th style={{ borderRadius: "10px 0px  0px  0px", paddingLeft: "30px" }}>
                                        <div className="leadsTH">
                                            Role Name <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Permissions <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th className='actionsTableTh' style={{ borderRadius: "0px 10px  0px  0px" }}>
                                        <div className="leadsTH">
                                            Action <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className='label2' style={{ paddingLeft: "30px" }}>
                                            {item.name}
                                        </td>
                                        <td className='label2' onClick={(event) => handleNavigate2(item, event)}>
                                            Permission
                                        </td>
                                        <td className='actionsTable'>
                                            <SVGIcons.Edit onClick={(event) => handleNavigate(item, event)} />
                                            <SVGIcons.Trash onClick={(event) => handleDelete(item.id, event)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
