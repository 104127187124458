import React, { useState, useContext, useEffect } from 'react';
import "./topbar.css"
import profile from "../../Presentation/Assets/png/profile.png"
import toggleSwitchtopbar from "../../Presentation/Assets/png/textalign-justifycenter.png"
import SVGIcons from '../shared/Icons/svgicons';
import ProfileSlide from '../shared/profileSlide/profileSlide';
import { DateContext } from '../../Services/ContextAPI/dateContext';
import { getAuthUser, useAuth } from '../../Utils/userAuth.ts';
import { useNavigate } from 'react-router-dom';

const TopBar = ({ toggleSidebar, top }) => {
  const [showSortIcon, setShowSortIcon] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const { setActiveDate } = useContext(DateContext);
  const authUser = getAuthUser();
  const isloggedin = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    setShowSortIcon(prevState => !prevState);
    toggleSidebar();
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setActiveDate(event.target.value);
  };

  useEffect(() => {
    if(!isloggedin)
    {
      navigate("/");
    }
  }, []);

  return (
    <div className="topbar">
      <button className="topbarHeading" onClick={handleClick}>
        {showSortIcon ? (
          <div className="homeTopbar">
            <SVGIcons.ArrowRight className='homeTopbar' />
          </div>
        ) : (
          <div className="homeTopbar">
            <img src={toggleSwitchtopbar} alt="" />
          </div>
        )}
        <div className="pageHeading">
          {top}
        </div>
      </button>
      <div className="topbarRight">
        {(top === "Leads" || top === "Client" || top === "Task" )&&
          <input type="date" value={selectedDate} className='add-leads-date-table' onChange={handleDateChange} />
        }
        <hr className="lineTopbar" />
        <ProfileSlide name={
          <div className="profileTopbar">
            <img src={profile} alt="" width="32px" />
            <div className="profileNameRole">
              <div className="profileName label2">{ authUser?.fullName }</div>
              <div className="profileRole label2">{ authUser?.role }</div>
            </div>
          </div>
        }/>
      </div>
    </div>
  );
}

export default TopBar;
