import React from 'react'
import "./testimonial.css"

const Testimonial = ({type, Icon}) => {
  return (
    <div className="testimonial">
      <div className="testLeft">
        <div className="label5 test1">Total {type}</div>
        <h5>0</h5>
      </div>
      <img src={Icon} alt="" />
    </div>
  )
}

export default Testimonial;