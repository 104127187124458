import React, { useState } from "react";
import close from "../../Assets/png/close-square.png"
import Button from "../../../Common/shared/Button/button";
import Dropdown from "react-dropdown";
import { UpdateTaskStatus } from "../../../Services/taskService.ts"

const options = ["Inform Client", "Under Process", "Mandate Pending", "Task Complete", "Reject", "Waiting For Documents"];

function TaskStatusUpdate({ name, id, onClose }) {
    const [displayModal, setDisplayModal] = useState(false);
    const [sform, setSform] = useState(false)
    const [defaultOption, setdefaultOption] = useState("")
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
        setdefaultOption(selected);
    };

    const handleClose = () => {
        setSform(false)
        setDisplayModal(!displayModal);
    }
    const handleSave = async () => {
        setSform(true);
        if (selectedOption !== "") {
            await UpdateTaskStatus(id, selectedOption?.value);
            setDisplayModal(false);
            onClose();
        }
    }

    return (
        <div className="App2">

            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-2 ${displayModal ? "leadShow-2" : ""} `} style={{ height: "40vh", bottom: "30%" }}>
                <div
                    className="leadinfo-Top-2"
                >
                    <h6 className="leadInfo-heading">Update Status</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={handleClose} />
                </div>
                <div className="addServ-content">


                    <div className="addServ-content2 addServ-content3" style={{ height: "auto", padding: "20px" }}>
                        <div className="addServ-input1">
                            <div className="label label-1" style={{ display: "flex", justifyContent: "flex-start", marginBottom: "7px" }}>Status</div>
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(sform && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="Select Status"
                            />
                            {(sform && selectedOption === "") && <span className="errorLogin label3">Status is required</span>}
                        </div>
                    </div>
                    <div className="fu-submit-cancel">
                        <Button variant="mediumOutline" onClick={() => setDisplayModal(!displayModal)}>Cancel</Button>
                        <Button variant="mediumButton" onClick={handleSave} >Submit</Button>
                    </div>
                </div>
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
        </div>
    );
}


export default TaskStatusUpdate;