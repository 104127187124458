import React from "react";
import "../leadStatus/leadStatus.css";

function getStatus(status) {
  switch (status) {
    case "Mandate Approved":
      return "#C1FDF6";
    case "Under Process":
      return "#FCEDB1";
    case "Insurance Under Process":
      return "#FCEDB1";
    case "Demat Under Process":
      return "#8F47A3";
    case "Mandate Pending":
      return "#A3DB9E";
    case "Case Decline":
      return "#F1C3C2";
    case "Reject":
      return "#FF8A87";
    case "Payment Pending":
      return "#C9C2F1";
    case "Task Completed":
      return "#62C459";
    case "Document Recieved \nand Under Process":
      return "#597EC4";
    case "Policy Issue":
      return "#7C59C4";
    case "Insurance Pending":
      return "#AFC459";
    case "Waiting For Documents":
      return "#C48D59";
    case "Conversation Pending":
      return "#C45973";

    default:
      return null;
  }
}
function getStatuscolor(status) {
  switch (status) {
    case "Mandate Approved":
      return "#000000";
    case "Under Process":
      return "#000000";
    case "Insurance Under Process":
      return "#000000";
    case "Demat Under Process":
      return "#FFFFFF";
    case "Mandate Pending":
      return "#000000";
    case "Case Decline":
      return "#000000";
    case "Reject":
      return "#FFFFFF";
    case "Payment Pending":
      return "#000000";
    case "Task Completed":
      return "#000000";
    case "Document Recieved \nand Under Process":
      return "#FFFFFF";
    case "Policy Issue":
      return "#FFFFFF";
    case "Insurance Pending":
      return "#FFFFFF";
    case "Waiting For Documents":
      return "#FFFFFF";
    case "Conversation Pending":
      return "#FFFFFF";

    default:
      return null;
  }
}
export const ClientStatus = ({ status }) => {
  return (
    <div
      className="leadStatus label3"
      style={{
        backgroundColor: getStatus(status),
        color: getStatuscolor(status),
      }}
    >
      {status}
    </div>
  );
};