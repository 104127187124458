import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import Menus from "../Domain/Menus/Menu.ts";
import httpClient from "./httpClient.ts";

export const GetAllMenus = async () : Promise<Array<Partial<Menus>>> => {
    try {
        const { data } = await httpClient.get("menu/getlist");
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const CheckMenuAccess = async (menuName: string, permName: string) : Promise<boolean> => {
  try {
      const { data } = await httpClient.get(`roles/getmenuaccesscurrentuser/check-currentuser-menuaccess?menuName=${menuName}&permissionName=${permName}`);
      return data;
    } catch (error: any) {
      snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
      return false;
    }
}