import {React, useEffect} from 'react';
import TopBar from '../topbar/topbar';
import "./main.css"
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Utils/userAuth.ts";


const Main = ({ width, toggleSidebar , children , top}) => {
  const navigate = useNavigate();
  const isLoggedIn = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
        navigate('/');
    }
  }, [navigate]);

  return (
    <div className="main" style={{
      width
    }}>
      <TopBar toggleSidebar={toggleSidebar} top={top} />
      <div className="mainCom">
        {children}
      </div>
    </div>
  );
}

export default Main;
