import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { Tasks } from "../Domain/Tasks/tasks.ts";
import httpClient from "./httpClient.ts";

export const GetAllTasks = async () : Promise<Array<Partial<Tasks>>> => {
    try {
        const { data } = await httpClient.get("taskdetails/getlist");
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const DeleteTask = async (task: Tasks) => {
    try {
        const { data } = await httpClient.delete("taskdetails/delete", ({data: task}));
        snackBarStore.showSnackBar(`Deleted: ${data!}`, "success");
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
      }
}

export const CreateTask = async (task: Tasks) => {
  try {
      task.tenantId = localStorage.getItem("tenant") || "";
      const { data } = await httpClient.post("taskdetails/create", task);
      snackBarStore.showSnackBar(`Success`, "success");
    } catch (error: any) {
      snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
    }
}

export const UpdateTask = async (task: Tasks) => {
  try {
      task.tenantId = localStorage.getItem("tenant") || "";
      await httpClient.put(`taskdetails/update/${task.id}/tasks`, task);
      snackBarStore.showSnackBar(`Update Successfully`, "success");
    } catch (error: any) {
      snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
    }
}

export const UpdateTaskStatus = async (taskId: number, status: string) => {
  try {
      const { data } = await httpClient.put(`taskdetails/updatestatus?id=${taskId}&status=${status}`);
      snackBarStore.showSnackBar(`Update Successfully: ${data!}`, "success");
    } catch (error: any) {
      snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
    }
}

