import React, { useState, useEffect } from "react";
import close from "../../../Presentation/Assets/png/close-square.png";
import "../../../Presentation/Pages/LeadInfo/LeadInfo.css";
import Button from "../../../Common/shared/Button/button";
import Multiselect from 'multiselect-react-dropdown';
import "./addService.css";
import { useNavigate } from "react-router-dom";
import { PiCaretUpDownFill } from "react-icons/pi";
import SVGIcons from "../Icons/svgicons";
import { GetAllTypes } from "../../../Services/udcService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import snackBarStore from "../../snackBar/store/snackbarStore.ts";
import { UpdateLeadData } from "../../../Services/leadService.ts";

function AddServices({ name, leadData }) {
    const [displayModal, setDisplayModal] = useState(false);
    const { startLoading, stopLoading } = useLoading();
    const navigate = useNavigate();
    const [remark, setRemark] = useState('');
    const [option, setOption] = useState([]);
    const [errorsForm3, setErrorsForm3] = useState({});
    const [defaultForm3Option, setDefaultForm3Option] = useState({
        serviceOption: []
    });
    const [addedServices, setAddedServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                startLoading();
                const result = await GetAllTypes("Services");
                if (result && Array.isArray(result)) {
                    setOption(result.map((res) => ({
                        id: res.id,
                        name: res.value01,
                    })));
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching services: ${error}`, "error");
            } finally {
                stopLoading();
            }
        }
        fetchServices();
    }, []);

    const handleSelect = (selected) => {
        setDefaultForm3Option((prev) => ({
            ...prev,
            serviceOption: selected,
        }));
    };

    const handleRemove = (selected) => {
        setDefaultForm3Option((prev) => ({
            ...prev,
            serviceOption: selected,
        }));
    };

    const handleAddButtonClick = () => {
        if (defaultForm3Option.serviceOption.length === 0) {
            setErrorsForm3({ ...errorsForm3, services: 'Service is required' });
            return;
        }
        if (!remark) {
            setErrorsForm3({ ...errorsForm3, remark: 'Remark is required' });
            return;
        }

        const newServices = defaultForm3Option.serviceOption.map((val) => ({
            id: 0,
            customerId: 0,
            serviceId: val.id,
            leadId: leadData.id,
            clientId: 0,
            isExistingClient: false,
            serviceName: val.name,
            assignedTo: leadData.assignedTo,
            assignedToName: leadData.assignedToName,
            isActive: true,
            remark,
        }));
        
        setAddedServices(prevAddedServices => [...prevAddedServices, ...newServices]);

        // Clear inputs
        setRemark('');
        setDefaultForm3Option({
            serviceOption: []
        });
    };

    const handleSubmit = async () => {
        if (addedServices.length === 0) {
            snackBarStore.showSnackBar("No services to submit", "error");
            return;
        }
        
        try {
            startLoading();
            const z = leadData;
            const d = {...z, serviceDetails: addedServices};
            console.log("d", d);
            console.log("Submitting services:", addedServices);
            await UpdateLeadData(d);
            setAddedServices([]);
            window.location.reload();
        } catch (error) {
            snackBarStore.showSnackBar(`Error submitting services: ${error}`, "error");
        } finally {
            stopLoading();
            handleClose();
        }
    };

    const handleClose = () => {
        setDisplayModal(!displayModal);
    }

    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-2 ${displayModal ? "leadShow-2" : ""} `}>
                <div
                    className="leadinfo-Top-2"
                >
                    <h6 className="leadInfo-heading">Add Services</h6>
                    <img src={close} alt="Close" className="leadinfo-closebutton" onClick={handleClose} />
                </div>
                <div className="addServ-content">
                    <div className="addServ-content2">
                        <div className="addServ-input1">
                            <div className="label label-1">Services</div>
                            <Multiselect
                                options={option}
                                selectedValues={defaultForm3Option.serviceOption}
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                displayValue="name"
                                showCheckbox={false}
                                avoidHighlightFirstOption={true}
                                placeholder="Select Services"
                            />
                            {errorsForm3.services && (
                                <div className="errorLogin label3">{errorsForm3.services}</div>
                            )}
                        </div>
                        <div className="addServ-input2">
                            <div className="label-1 label">Remark</div>
                            <textarea
                                className={`addServ-textarea ${errorsForm3.remark ? "error-border" : ""}`}
                                placeholder="Enter Comment here"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                style={{ marginTop: "5px", resize: "none" }}
                                rows={5}
                            />
                            {errorsForm3.remark && (<span className="errorLogin label3">{errorsForm3.remark}</span>)}
                        </div>
                        <Button variant="smallButton" onClick={handleAddButtonClick}>Add</Button>
                        <table className='leadsTable roundedcorner addserv-table' id='leadsTable' >
                            <thead>
                                <tr className='body2 roundedcorner'>
                                    <th style={{ borderRadius: "10px 0px 0px 0px" }} className="vraj">
                                        <div className="leadsTH">
                                            Dates <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th className="vraj">
                                        <div className="leadsTH">
                                            Services <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Remark <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th style={{ borderRadius: "0px 10px 0px 0px" }}>
                                        <div className="leadsTH" style={{ borderRadius: "0px 10px 0px 0px" }}>
                                            Actions <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {addedServices.map((item, index) => (
                                    <tr key={index}>
                                        <td className='label2'>
                                            {new Date().toLocaleDateString()} {/* Use formatted date */}
                                        </td>
                                        <td className='label2'>
                                            {item.serviceName}
                                        </td>
                                        <td className='label2'>
                                            {item.remark}
                                        </td>
                                        <td className='actionsTable'>
                                            <SVGIcons.Trash onClick={() => {
                                                setAddedServices(prevItems => prevItems.filter(prevItem => prevItem.serviceId !== item.serviceId));
                                            }} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="fu-submit-cancel">
                        <Button variant="mediumOutline" onClick={handleClose}>Cancel</Button>
                        <Button variant="mediumButton" onClick={handleSubmit}>Submit</Button>
                    </div>
                </div>
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={handleClose}
            />
        </div>
    );
}

export default AddServices;
