import React, { useState, useEffect } from "react";
import close from "../../Assets/png/close-square.png"
import { FaAngleDown } from "react-icons/fa6";
import { TaskInfo, AddDetials } from "./taskInfo.js";

function TaskInform({ name, item }) {
    const [alert, setAlert] = useState("");
    const [displayModal, setDisplayModal] = useState(false);

    function getinfo(name) {
        switch (name) {
            case "Start Date":
                return new Date(item.startDate).toDateString();
            case "Due Date":
                return new Date(item.dueDate).toDateString();
            case "Reminder Date":
                return new Date(item.reminderDate).toDateString();
            case "Priority":
                return item.priority;
            case "Remarks":
                return item.remarks;
            case "Service Request":
                return item.serviceRequest;
            default:
                return null;
        }
    }

    useEffect(() => {
        const clearMessage = setTimeout(() => {
            setAlert("");
        }, 5000);
        return () => clearTimeout(clearMessage);
    }, [alert]);

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal ${displayModal ? "leadShow" : ""} `}>
                <div
                    className="leadinfo-Top"
                >
                    <h6 className="leadInfo-heading">Task Information</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={() => setDisplayModal(!displayModal)} />
                </div>
                <div className="leadinfo-content">
                    <div className="leadinfo-contentTop">
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Task Id</div>
                            <div className="leadinfo-data1 label1">{item.id}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Task Name</div>
                            <div className="leadinfo-data1 label1">{item.taskName}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Task Status</div>
                            <div className="leadinfo-data1 label1">{item.taskStatus}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Task Owner</div>
                            <div className="leadinfo-data1 label1">{item.taskFrom}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Priority</div>
                            <div className="leadinfo-data1 label1">{item.priority}</div>
                        </div>
                    </div>

                    <hr className="leadinfoHr" />

                    <div className="leadInfoBox">
                        <button className={`accordion ${activeIndex === 0 ? "activeLeadInfo" : ""}`} onClick={() => toggleAccordion(0)}>
                            <div className="label1">
                                Task Information
                            </div>
                            <div >
                                <FaAngleDown size={20} className={`accordion-icon  ${activeIndex === 0 ? "acordion-icon-active" : ""}`} />
                            </div>
                        </button>
                        <div className={`panel ${activeIndex === 0 ? "activeLeadInfo" : ""}`}>
                            {TaskInfo.map((item1, index) => (
                                <div className="leadInfo-single label5">
                                    <div className="leadInfoname">{item1.name}</div>
                                    <div className="leadInfovalue">{getinfo(item1.name)}</div>
                                </div>
                            ))}

                        </div>

                        <button className={`accordion label1 ${activeIndex === 1 ? "activeLeadInfo" : ""}`} onClick={() => toggleAccordion(1)}>
                            <div className="label1">
                                Additional Details
                            </div>
                            <span className="accordion-icon">
                                <FaAngleDown size={20} />
                            </span>
                        </button>
                        <div className={`panel ${activeIndex === 1 ? "activeLeadInfo" : ""}`}>
                            {AddDetials.map((item1, index) => (
                                <div className="leadInfo-single">
                                    <div className="leadInfoname">{item1.name}</div>
                                    <div className="leadInfovalue">{getinfo(item1.name)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
            <p className="Alert">{alert}</p>
        </div>
    );
}


export default TaskInform;