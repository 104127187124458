import React from 'react'
import "./forgotPassword.css"
import login from "../../Assets/Svg/Group.png"
import logo from "../../Assets/Svg/logo.png"
import Button from '../../../Common/shared/Button/button'
import { useForm } from "react-hook-form"
import "../../../Common/shared/Input/input.css"
import { useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const navigate = useNavigate();
    
    const onSubmit = (data, event) => {
        event.preventDefault();
        document.activeElement.blur()
        if (Object.keys(errors).length === 0) {
            navigate('/otp');
        }
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return (
        <div className="forgotContainer" >
            <div className="forgot-left-section" >
                <div className="forgotForm" >
                    <div className="logo" >
                        <img src={logo} alt="" />
                        <div className="logoText" >
                            <h5>INSURE</h5>
                            <h6>CRM</h6>
                        </div>
                    </div>
                    <div className="loginHeading" >
                        <h3>Forgot Password</h3>
                    </div>

                    <form className="forgotFormInput" onSubmit={handleSubmit(onSubmit)}>
                        <div className="inputForm" >
                            <label htmlFor="Email">Email</label>
                            <input
                                type='text'
                                className={`input10 ${errors.mail ? 'error-border' : ''}`}
                                placeholder="Email"
                                {...register("mail", {
                                    required: 'Email Address is required',
                                    pattern: { value: emailRegex, message: 'Invalid email format' },
                                })}
                                aria-invalid={errors.mail ? 'true' : 'false'}
                            />
                            {errors.mail && <div className="errorLogin label3" >{errors.mail.message}</div>}
                        </div>
                        <div className="downContainer" >
                            <div className="downcontainer1" >
                                <Button variant="largeButton">Submit</Button>
                            </div>
                            <div className="downcontainer2" >
                                <div className="label1 navigator" style={{ fontWeight: "500", color: "#696E77" }} onClick={() => navigate('/')}>Back to Login</div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="forgot-right-section" >
                <div className="loginImage" >
                    <img src={login} alt="" className="loginImg" />
                </div>
            </div>
        </div>
    )
}
