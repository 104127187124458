import { React, useContext } from 'react';
import Icon from "../../Assets/png/Icon.png";
import Icon2 from "../../Assets/png/Icon2.png";
import Icon3 from "../../Assets/png/Icon3.png";
import Icon4 from "../../Assets/png/Icon4.png";
import Testimonial from '../../../Common/Testimonials/testimonial';

export const Dashbaord = () => {
    return (
        <div>
            <div className="testimonials">
                <Testimonial type="Leads" Icon={Icon} />
                <Testimonial type="Clients" Icon={Icon2} />
                <Testimonial type="Tasks" Icon={Icon3} />
                <Testimonial type="Pending" Icon={Icon4} />
            </div>
        </div>
    )
}
