import { React, useEffect, useState, useSyncExternalStore } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import Dropdown from 'react-dropdown';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate, useParams } from "react-router-dom";
import { PiCaretUpDownFill } from "react-icons/pi";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { GetCountries, GetCityByStateId, GetStatesByCountryId } from "../../../Services/CascadinCountryStateCity.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { GetAllUsers } from "../../../Services/authService.ts";
import { GetAllTypes } from "../../../Services/udcService.ts";
import { getAuthUser } from "../../../Utils/userAuth.ts";
import { UpdateLeadData, GetLeadById } from "../../../Services/leadService.ts";

export default function EditLeads() {
    const navigate = useNavigate();
    const { leadId } = useParams();
    const { startLoading, stopLoading } = useLoading();
    const [formS, setFormS] = useState(false)
    const [formS2, setFormS2] = useState(false)
    const [formS3, setFormS3] = useState(false)
    const [formNumber, setFormNumber] = useState(1);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [taskTo, setTaskto] = useState([]);
    const [occupations, setOccupation] = useState([]);
    const [services, setService] = useState([]);
    const [defaultOption, setDefaultOption] = useState({
        countryOption: null,
        stateOption: null,
        cityOption: null,
        leadSourceOption: null,
        occupationOption: null
    });
    const [defaultform3Option, setDefaultForm3Option] = useState({
        assignTo: null,
        serviceOption: null
    });
    const { register: registerForm2, handleSubmit: handleSubmitForm2, formState: { errors: errorsForm2 }, setValue: setValue2 } = useForm();
    const [syncInputs, setSyncInputs] = useState(false);
    const [formData, setFormData] = useState({
        personalDetail: {},
        occupationDetail: {},
        interestedServices: {}
    });
    const [whatsAppNo, setWhatsAppNo] = useState("");
    const [mobileNo, setMobileNo] = useState('');
    const [remark, setRemark] = useState('');
    const [errorsForm3, setErrorsForm3] = useState({});
    const [addedServices, setAddedServices] = useState([]);
    const [leadData, setLeadData] = useState({});

    const handleInputChange = (e) => {
        const { value } = e.target;
        setMobileNo(value);
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                startLoading();
                const result = await GetCountries();
                if (result) {
                    if (Array.isArray(result)) {
                        setCountries(result.map((country) => ({
                            value: country.id,
                            label: country.countryName,
                        })));
                    }
                }
            } catch (error) {
                console.error("Error fetching Countries:", error);
            }
            finally{
                stopLoading();
            }
        }

        const fetchOccupation = async () => {
            try {
                startLoading();
                const result = await GetAllTypes("Occupation");
                if (result) {
                    if (Array.isArray(result)) {
                        setOccupation(result.map((res) => ({
                            value: res.id,
                            label: res.value01,
                        })));
                    }
                }
            } catch (error) {
                console.error("Error fetching Countries:", error);
            }
            finally{
                stopLoading();
            }
        }

        const fetchLeadSource = async () => {
            try {
                startLoading();
                const result = await GetAllTypes("Lead Source");
                if (result) {
                    if (Array.isArray(result)) {
                        setLeadSources(result.map((res) => ({
                            value: res.id,
                            label: res.value01,
                        })));
                    }
                }
            } catch (error) {
                console.error("Error fetching Countries:", error);
            }
            finally{
                stopLoading();
            }
        }

        const fetchTaskTo = async () => {
            try {
                startLoading();
                const result = await GetAllUsers();
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskto(result.filter(x => x.roleName.toUpperCase() != "SuperAdmin".toUpperCase()).map((res) => ({
                            value: res.user.id,
                            label: `${res.user.firstName} ${res.user.lastName}`,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchServices = async () => {
            try {
                startLoading();
                const result = await GetAllTypes("Services");
                if (result) {
                    if (Array.isArray(result)) {
                        setService(result.map((res) => ({
                            id: res.id,
                            name: res.value01,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchData = async () => {
            try {
                startLoading();
                const item = await GetLeadById(leadId);
                if(item)
                {
                    setLeadData(item);
                    setValue("firstName", item.firstName);
                    setValue("lastName", item.lastName);
                    handleDropdownChange("leadSourceOption", {value: item.leadSource, label: item.leadSourceName});
                    setValue("emailId", item.emailId);
                    setValue("otherSource", item.otherSource);
                    setValue("mobileNo", setMobileNo(item.mobileNo));
                    setWhatsAppNo(item.whatsAppNo);
                    item.whatsAppNo ? setSyncInputs(true) : setSyncInputs(false);
                    setValue("addressLine1", item.addressLine1);
                    setValue("addressLine2", item.addressLine2);
                    setValue("pin", item.pincode);
                    handleDropdownChange("countryOption", {value: item.countryId, label: item.countryName});
                    handleDropdownChange("cityOption", {value: item.cityId, label: item.cityName});
                    handleDropdownChange("stateOption", {value: item.stateId, label: item.stateName});
                    handleDropdownChange("occupationOption", {value: item.occupation, label: item.occupationName});
                    setValue2("workType", item.workType);
                    setValue2("monthlyIncome", item.monthlyIncome);
                    setRemark(item.serviceDetails[0].remark);
                    handleDropdownForm3Change("assignTo", {value: item.serviceDetails[0].assignedTo, label: item.serviceDetails[0].assignedToName});
                    item.serviceDetails.map((val) => {
                        console.log("optionbs", val);
                        handleDropdownForm3Change("serviceOption", {id: val.serviceId, name: val.serviceName});
                    });
                    console.log(defaultform3Option.serviceOption);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally{
                stopLoading();
            }
        };
        fetchOccupation();
        fetchLeadSource();
        fetchCountries();
        fetchTaskTo();
        fetchServices();
        fetchData();
    }, []);

    const handleCountryChange = async (option) => {
        try {
            startLoading();
            const result = await GetStatesByCountryId(option.value);
            if (result) {
                if (Array.isArray(result)) {
                    setStates(result.map((state) => ({
                        value: state.id,
                        label: state.stateName,
                    })));
                    handleDropdownChange("countryOption", option);
                }
            }
        } catch (error) {
            console.error("Error fetching states:", error);
        } finally {
            stopLoading();
        }
    }

    const handleStateChange = async (option) => {
        try {
            startLoading();
            const result = await GetCityByStateId(option.value);
            if (result) {
                if (Array.isArray(result)) {
                    setCities(result.map((city) => ({
                        value: city.id,
                        label: city.cityName,
                    })));
                    handleDropdownChange("stateOption", option);
                }
            }
        } catch (error) {
            console.error("Error fetching cities:", error);
        } finally {
            stopLoading();
        }
    }

    const handleWInputChange = (e) => {
        const { value } = e.target;
        setWhatsAppNo(value);
    };
    const handleCheckboxChange = (event) => {
        setSyncInputs(event.target.checked)
        if (event.target.checked) {
            setWhatsAppNo(mobileNo)
        }
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();
    
    const handleRemove = (dropDwnId, selected) => {
        setDefaultForm3Option((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const handleService = () => {
        if (!defaultform3Option.assignTo) {
            setErrorsForm3({ ...errorsForm3, assignTo: 'Assigned Person required' });
            return;
        }
        if (defaultform3Option.serviceOption.length === 0) {
            setErrorsForm3({ ...errorsForm3, services: 'Service is required' });
            return;
        }
        if (!remark) {
            setErrorsForm3({ ...errorsForm3, remark: 'Remark is required' });
            return;
        }

        const newServices = defaultform3Option.serviceOption.map((val) => ({
            id: 0,
            customerId: 0,
            serviceId: val.id,
            leadId: 0,
            clientId: 0,
            isExistingClient: false,
            serviceName: val.name,
            assignedTo: defaultform3Option.assignTo?.value,
            assignedToName: defaultform3Option.assignTo?.label,
            isActive: true,
            remark,
        }));
        
        setAddedServices(prevAddedServices => [...prevAddedServices, ...newServices]);

        // Clear inputs
        setRemark('');
        setDefaultForm3Option({
            assignTo: null,
            serviceOption: null});
        setErrorsForm3({});
    };

   const handleDropdownChange = (dropDwnId, selected) => {
        setDefaultOption((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const handleDropdownForm3Change = (dropDwnId, selected) => {
        setDefaultForm3Option((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const onSubmit = (data) => {
        console.log(data); // Form data will be available here
        if (Object.keys(errors).length === 0) {
            if (defaultOption.leadSourceOption !== "") {
                setFormData({ ...formData, personalDetail: { ...data, selectedOption: defaultOption.assignTo } });
                setFormNumber(formNumber + 1);
            }
        }
    };

    //for page2
    const onSubmit2 = (data) => {
        if (Object.keys(errors).length === 0) {
            if (defaultOption.occupationOption !== null) {
                setFormData({ ...formData, occupationDetail: data });
                setFormNumber(formNumber + 1);
            }
        }
    };

    const date = new Date();
    //for page3
    const onSubmit3 = async (data) => {
        if (Object.keys(errors).length === 0) {
            if (addedServices.length !== 0) {
                startLoading();
                const leadBody = {
                    id: leadId,
                    tenantId: localStorage.getItem("tenant")?.toString(),
                    customerId: leadData.customerId,
                    firstName: formData.personalDetail.firstName,
                    lastName: formData.personalDetail.lastName,
                    emailId: formData.personalDetail.emailId,
                    mobileNo: mobileNo,
                    whatsAppNo: whatsAppNo,
                    addressLine1: formData.personalDetail.addressLine1 || "12",
                    addressLine2: formData.personalDetail.addressLine2,
                    cityId: defaultOption.cityOption.value,
                    cityName: defaultOption.cityOption.label,
                    stateId: defaultOption.stateOption.value,
                    stateName: defaultOption.stateOption.label,
                    countryId: defaultOption.countryOption.value,
                    countryName: defaultOption.countryOption.label,
                    pincode: formData.personalDetail.pin,
                    leadSource: defaultOption.leadSourceOption.value,
                    leadSourceName: defaultOption.leadSourceOption.label,
                    otherSource: formData.personalDetail.otherSource,
                    occupation: defaultOption.occupationOption.value,
                    occupationName: defaultOption.occupationOption.label,
                    organisationName: leadData.organisationName,
                    workType: formData.occupationDetail.workType,
                    monthlyIncome: formData.occupationDetail.monthlyIncome,
                    assignedTo: addedServices[0].assignedTo,
                    assignedToName: addedServices[0].assignedToName,
                    leadStatus: leadData.leadStatus,
                    leadStatusName: leadData.leadStatusName,
                    createdBy: leadData.createdBy,
                    createdByName: leadData.createdByName,
                    leadDate: leadData.leadDate,
                    isActive: leadData.isActive,
                    serviceDetails: addedServices.length == 0 ? formData.interestedServices : addedServices
                }
                try {
                    await UpdateLeadData(leadBody);
                } catch (error) {
                    snackBarStore.showSnackBar(`Error while Updating lead: ${error}`, "error");
                }
                finally{
                    stopLoading();
                    navigate("/leads");
                }
            }
            else{
                snackBarStore.showSnackBar(`please add at least one service`, "error");
            }
        }
    };

    const FirstPageNext = () => {
        handleSubmit(onSubmit)(); // Submit the form
        setFormS(true);
    };

    const SecondPageNext = () => {
        handleSubmitForm2(onSubmit2)(); // Submit the form
        setFormS2(true);
    };

    const ThirdPageNext = () => {
        setFormS3(true);
        onSubmit3();
    };

    return (
        <>
            {formNumber === 1 &&
                <div className="add-leads-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="add-leads-top">
                        <div className="add-leads-top2">
                            <div className="add-leadssection">1</div>
                            <div className="addleads-sectionname">Personal Detail</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2">
                            <div className="add-leadssection2">2</div>
                            <div className="addleads-sectionname2">Occupation</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2 add-leads-top2_1">
                            <div className="add-leadssection2">3</div>
                            <div className="addleads-sectionname3">Interested Services</div>
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Personal Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">First Name</div>
                            {/* <input className="add-leads-input" placeholder="Rajiv" /> */}
                            <input
                                className={`add-leads-input ${errors.firstName ? 'error-border' : ''}`}
                                placeholder="Rajiv"
                                {...register("firstName", { required: "Name is required" })}

                            />
                            {errors.firstName && <span className="errorLogin label3">{errors.firstName.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Last Name</div>
                            <input
                                className={`add-leads-input ${errors.lastName ? 'error-border' : ''}`}
                                placeholder="Sharma"
                                {...register("lastName", { required: "Last name is required" })}
                            />
                            {errors.lastName && <span className="errorLogin label3">{errors.lastName.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Lead Source</div>
                            <Dropdown options={leadSources}
                                className={`dropdown-fu dropdown-leads ${(formS && defaultOption.leadSourceOption === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleDropdownChange("leadSourceOption", e)}
                                value={ defaultOption.leadSourceOption} placeholder="Status"
                            />
                            {(formS &&  defaultOption.leadSourceOption === "") && <span className="errorLogin label3">lead source required</span>}
                        </div>
                    </div>
                    {true &&
                        <div className="add-leads-inputbox">
                            <div className="add-leads-input-container">
                                <div className="add-leadlabel">Other Source</div>
                                <input
                                    className={`add-leads-input ${errors.otherSource ? 'error-border' : ''}`}
                                    placeholder="Rajiv"
                                    {...register("otherSource", { required: "otherSource is required" })}

                                />
                                {errors.otherSource && <span className="errorLogin label3">{errors.otherSource.message}</span>}
                            </div>
                            <div className="add-leads-input-container">
                            </div>
                            <div className="add-leads-input-container">
                            </div>
                        </div>
                    }

                    <div className="add-leads-text">
                        <div className="personal-text">Contact Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Mobile Number</div>
                            <input
                                className={`add-leads-input ${mobileNo === "" ? (formS ? "error-border" : "") : ""}`}
                                onChange={handleInputChange}
                                type="number"
                                name="mobileNo"
                                placeholder="+919876543211"
                            />
                            {(mobileNo === "" && formS) && (
                                <span className="errorLogin label3">
                                    Phone no. is required
                                </span>
                            )}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Email id</div>
                            <input
                                className={`add-leads-input ${errors.emailId ? 'error-border' : ''}`}
                                placeholder="rajiv.sharma@gmail.com"
                                {...register("emailId", { required: "Email id is required" })}
                            />
                            {errors.emailId && <span className="errorLogin label3">{errors.emailId.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="wp-phone">
                                <div className="add-leadlabel">WhatsApp Number </div>
                                <div className="wp-phone-2">
                                    <input
                                        type="checkbox"
                                        checked={syncInputs}
                                        onChange={handleCheckboxChange}
                                        name="whatsAppNo"
                                        style={{ border: "2px solid #00f", display: "block", backgroundColor: "#fafa" }}
                                    />
                                    <div className="same-no">same as mobile number</div>
                                </div>
                            </div>
                            <input
                                className={`add-leads-input ${whatsAppNo === "" && formS ? "error-border" : ""
                                    }`}
                                type="number"
                                value={syncInputs ? mobileNo : whatsAppNo}
                                onChange={handleWInputChange}
                                placeholder="+919876543210"

                            />
                            {(whatsAppNo === "" && formS) && (
                                <span className="errorLogin label3">
                                    WhatsApp no. is required
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="add-leads-addres">
                        <div className="add-leadlabel">Address Line 1</div>
                        <input
                            className={`add-leads-aadresinput ${errors.addressLine1 ? 'error-border' : ''}`}
                            {...register("addressLine2", { required: "Address is required" })}
                            placeholder="Build 1/A, 101, Shree krishna society,"
                        />
                        {errors.addressLine1 && <span className="errorLogin label3">{errors.addressLine1.message}</span>}
                    </div>

                    <div className="add-leads-addres">
                        <div className="add-leadlabel">Address Line 2</div>
                        <input
                            className={`add-leads-aadresinput ${errors.addressLine2 ? 'error-border' : ''}`}
                            {...register("addressLine2", { required: "Address is required" })}
                            placeholder="Waghle Esate, Thane"
                        />
                        {errors.addressLine2 && <span className="errorLogin label3">{errors.addressLine2.message}</span>}
                    </div>

                    <div className="add-leads-location">
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">Country</div>
                            <Dropdown options={countries}
                                className={`dropdown-fu dropdown-leads ${(formS &&  defaultOption.countryOption === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleCountryChange(e)}
                                value={defaultOption.countryOption} placeholder="India"
                            />
                            {(formS && defaultOption.countryOption === "") && <span className="errorLogin label3">Country required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">State</div>
                            <Dropdown options={states}
                                className={`dropdown-fu dropdown-leads ${(formS && defaultOption.stateOption === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleStateChange(e)}
                                value={defaultOption.stateOption} placeholder="Maharashtra"
                            />
                            {(formS && defaultOption.stateOption === "") && <span className="errorLogin label3">State required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">City</div>
                            <Dropdown options={cities}
                                className={`dropdown-fu dropdown-leads ${(formS && defaultOption.cityOption === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleDropdownChange("cityOption", e)}
                                value={defaultOption.cityOption} placeholder="Thane"
                            />
                            {(formS && defaultOption.cityOption === "") && <span className="errorLogin label3">City required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">Pin code</div>
                            <input className={`add-leads-locationinput ${errors.pin ? 'error-border' : ''}`} placeholder="400601" {...register("pin", { required: "Email id is required" })} />
                            {errors.pin && <span className="errorLogin label3">{errors.pin.message}</span>}
                        </div>
                    </div>

                    <div className="add-leads-button">
                        <Button variant="smallButton" onClick={() => navigate('/leads')}>Back</Button>
                        <Button type="submit" variant="smallButton" onClick={FirstPageNext}>
                            Next
                        </Button>
                    </div>
                    </form> 
                </div>
            }
            {
                formNumber === 2 &&
                <div className="add-leads-form" id="add-leads-form2">
                    <div className="add-leads-top">
                        <div className="add-leads-top2">
                            <div className="add-leadssection noSelectno">1</div>
                            <div className="addleads-sectionname noSelectna">Personal Detail</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2">
                            <div className="add-leadssection2 selectedNo">2</div>
                            <div className="addleads-sectionname2 selectedNa">Occupation</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2 add-leads-top2_1">
                            <div className="add-leadssection2">3</div>
                            <div className="addleads-sectionname3">Interested Services</div>
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Occupation Details</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Occupation</div>
                            <Dropdown options={occupations}
                                className={`dropdown-fu dropdown-leads ${(formS && defaultOption.occupationOption === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleDropdownChange("occupationOption", e)}
                                value={defaultOption.occupationOption} placeholder="Occupation"
                            />
                            {(formS2 && defaultOption.occupationOption === "") && <span className="errorLogin label3">Country required</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Type of Work</div>
                            <input
                                className={`add-leads-input ${errorsForm2.workType ? "error-border" : ""}`}
                                placeholder="IT Engineer"
                                {...registerForm2("workType", { required: "Type of Work is required" })}
                            /> {errorsForm2.workType && (<span className="errorLogin label3">{errorsForm2.workType.message}</span>)}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Monthly Income</div>
                            <input
                                className={`add-leads-input ${errorsForm2.monthlyIncome ? "error-border" : ""}`}
                                placeholder="30000"
                                {...registerForm2("monthlyIncome", { required: "Income is required" })}
                            /> {errorsForm2.monthlyIncome && (<span className="errorLogin label3">{errorsForm2.monthlyIncome.message}</span>)}
                        </div>
                    </div>

                    <div className="add-leads-button">
                        <Button variant="smallButton" onClick={() => setFormNumber(1)}>Back</Button>
                        <Button variant="smallButton" onClick={SecondPageNext}>
                            Next
                        </Button>
                    </div>
                </div>
            }
            {formNumber === 3 &&
                <div className="add-leads-form" id="add-leads-form3">
                    <div className="add-leads-top">
                        <div className="add-leads-top2">
                            <div className="add-leadssection noSelectno">1</div>
                            <div className="addleads-sectionname noSelectna">Personal Detail</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2">
                            <div className="add-leadssection2">2</div>
                            <div className="addleads-sectionname2">Occupation</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2 add-leads-top2_1">
                            <div className="add-leadssection2 selectedNo">3</div>
                            <div className="addleads-sectionname3 selectedNa">Interested Services</div>
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Interested Services</div>
                    </div>

                    <div className="add-leads-location">
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">Assign to</div>
                            <Dropdown options={taskTo}
                                className={`dropdown-fu dropdown-leads ${(formS && defaultform3Option.assignTo === "") ? 'error-border' : ''}`}
                                onChange={(e) => handleDropdownForm3Change("assignTo", e)}
                                value={defaultform3Option.assignTo} placeholder="Select Person"
                            />
                            {formS3 && errorsForm3.assignTo && <span className="errorLogin label3">Assigned Person required</span>}
                        </div>
                        <div className="add-leads-service">
                            <div className="service-text">Services</div>
                            <div className="div3">
                                <Multiselect
                                    className="div1"
                                    options={services} // Options to display in the dropdown
                                    selectedValues= {defaultform3Option.serviceOption || []} // Pre-selected values (optional)
                                    onSelect={(e) => handleDropdownForm3Change("serviceOption", e)} // Function to handle selection
                                    onRemove={(e) => handleRemove("serviceOption", e)} // Function to handle removal
                                    displayValue="name" // Property of options to display as label
                                    showCheckbox={true}
                                    avoidHighlightFirstOption={true}
                                    placeholder="Select Services"
                                />
                            </div>
                            {(formS3 && defaultform3Option.serviceOption?.length === 0 && errorsForm3.services) && (
                                <div className="errorLogin label3">Service is required</div>
                            )}
                        </div>
                    </div>

                    <div className="add-leads-serviceremark">
                        <div className="service-text">Remark</div>
                        <textarea
                            className={`serivce-label ${errorsForm3.remark ? "error-border" : ""}`}
                            placeholder="Enter Comment here"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            style={{ marginTop: "5px", resize: "none" }}
                        />
                        {formS3 && errorsForm3.remark && (<span className="errorLogin label3">{errorsForm3.remark}</span>)}
                    </div>

                    <div className="add-leads-servicebutton">
                        <Button variant="smallButton" onClick={handleService}>Add</Button>
                    </div>

                    {addedServices.length > 0 &&
                        <div>
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "9px 0px  0px  0px", width: "15%" }}>
                                            Services <PiCaretUpDownFill size={12} />
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Remark <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                                            <div className="leadsTH">
                                                Action <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedServices.map((service, index) => (
                                        <tr key={index}>
                                            <td className='label2'>{service.serviceName}</td>
                                            <td className='label2'>{service.remark}</td>
                                            <td className='actionsTable'>
                                            <SVGIcons.Trash onClick={() => {
                                                setAddedServices(prevItems => prevItems.filter(prevItem => prevItem.services !== service.services)); 
                                            }} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }

                    <div className="add-leads-button">
                        <Button variant="smallButton" onClick={() => setFormNumber(2)}>Back</Button>
                        <Button variant="smallButton" onClick={ThirdPageNext}>Submit</Button>
                    </div>
                </div>
            }
        </>
    );
}
