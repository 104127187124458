import React from 'react';
import { GetApiForImg } from '../../../Utils/userAuth.ts';
import Button from '../Button/button';

const DownloadFile = ({ fileUrl }) => {

  const handleDownload = async () => {
    try {
      fileUrl = `${GetApiForImg()}/${fileUrl}`;
      const response = await fetch(fileUrl);
      const contentDisposition = response.headers.get('content-disposition');
      
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const fileName = fileNameMatch && fileNameMatch[1] ? fileNameMatch[1] : 'download';
      
      const anchor = document.createElement('a');
      anchor.href = fileUrl;
      anchor.download = fileName;
      
      document.body.appendChild(anchor);
      
      anchor.click();
      
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Button variant="smallButton" onClick={handleDownload}>
      Download File
    </Button>
  );
};

export default DownloadFile;
