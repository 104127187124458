import { ReactComponent as Settings } from '../../../Presentation/Assets/Svg/Setting.svg';
import { ReactComponent as Settings2 } from '../../../Presentation/Assets/Svg/setting-2.svg';
import { ReactComponent as Add } from '../../../Presentation/Assets/Svg/add.svg';
import { ReactComponent as Dashboard } from '../../../Presentation/Assets/Svg/Dashboardi.svg';
import { ReactComponent as User } from '../../../Presentation/Assets/Svg/user.svg';
import { ReactComponent as SheildTick } from '../../../Presentation/Assets/Svg/shield-tick.svg';
import { ReactComponent as DocumentText } from '../../../Presentation/Assets/Svg/document-text.svg';
import { ReactComponent as MessageText } from '../../../Presentation/Assets/Svg/message-text.svg';
import { ReactComponent as DocumentNormal } from '../../../Presentation/Assets/Svg/document-normal.svg';
import { ReactComponent as Logout } from '../../../Presentation/Assets/Svg/logout.svg';
import { ReactComponent as DocumentFilter } from '../../../Presentation/Assets/Svg/document-filter.svg';
import { ReactComponent as ClipbaordText } from '../../../Presentation/Assets/Svg/clipboard-text.svg';
import { ReactComponent as Bag } from '../../../Presentation/Assets/Svg/bag-2.svg';
import { ReactComponent as DocumentDownload } from '../../../Presentation/Assets/Svg/document-download.svg';
import { ReactComponent as DocumentUpload } from '../../../Presentation/Assets/Svg/document-upload.svg';
import { ReactComponent as UserSearch } from '../../../Presentation/Assets/Svg/user-search.svg';
import { ReactComponent as Trash } from '../../../Presentation/Assets/Svg/trash.svg';
import { ReactComponent as Edit } from '../../../Presentation/Assets/Svg/edit.svg';
import { ReactComponent as ArrowRight } from '../../../Presentation/Assets/Svg/arrow-right.svg';
import { ReactComponent as UploadFile } from '../../../Presentation/Assets/Svg/uploadfile.svg';
import { ReactComponent as Close } from '../../../Presentation/Assets/Svg/close-square.svg';
import { ReactComponent as Checked } from '../../../Presentation/Assets/Svg/checked-svgrepo-com.svg';
import { ReactComponent as Unchecked } from '../../../Presentation/Assets/Svg/unchecked-svgrepo-com.svg';

const SVGIcons = {
    Settings: Settings,
    Dashboard: Dashboard,
    Settings2: Settings2,
    Add: Add,
    User: User,
    SheildTick: SheildTick,
    DocumentText: DocumentText,
    MessageText: MessageText,
    DocumentNormal: DocumentNormal,
    Logout: Logout,
    DocumentFilter: DocumentFilter,
    ClipbaordText: ClipbaordText,
    Bag: Bag,
    DocumentDownload: DocumentDownload,
    DocumentUpload: DocumentUpload,
    UserSearch: UserSearch,
    Edit: Edit,
    Trash: Trash,
    ArrowRight:ArrowRight,
    UploadFile: UploadFile,
    Close:Close,
    Checked: Checked,
    Unchecked: Unchecked,
};

export default SVGIcons;