import React, { useState, useEffect } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import Dropdown from "react-dropdown";
import { useNavigate } from "react-router-dom";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { GetDropValues } from "../../../Services/DropService.ts";
import { GetAllUsers } from "../../../Services/authService.ts";
import { GetAllClients } from "../../../Services/clientService.ts";
import { CreateTask } from "../../../Services/taskService.ts";
import { getAuthUser } from "../../../Utils/userAuth.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";

export default function AddTask() {
    const navigate = useNavigate();
    const currentUser = getAuthUser();
    const [formS, setFormS] = useState(false);
    const { startLoading, stopLoading } = useLoading();
    const [taskTypes, setTaskTypes] = useState([]);
    const [taskTo, setTaskto] = useState([]);
    const [clients, setClients] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [imageSrc, setImageSrc] = useState(null);
    const [defaultOption, setDefaultOption] = useState({
        taskTypeOption: null,
        taskToOption: null,
        clientOption: null,
        priorityOption: null,
    });

    const handleDropdownChange = (dropDwnId, selected) => {
        setDefaultOption((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc({name: file.name, extension: `.${file.name.split('.').pop()}`, data: reader.result});
            };
            reader.readAsDataURL(file);
        }
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = async (data) => {
        setFormS(true);
        startLoading();
        const taskData =
        {
            taskName: data.taskName,
            taskStatus: "Inform Client",
            taskFrom: currentUser.fullName,
            taskType: defaultOption.taskTypeOption.value,
            taskTo: defaultOption.taskToOption.value,
            clientId: defaultOption.clientOption.value,
            dueDate: new Date(data.dueDate).toISOString(),
            serviceRequest: data.serviceRequest,
            startDate: new Date(data.startDate).toISOString(),
            reminderDate: new Date(data.reminderDate).toISOString(),
            priority: defaultOption.priorityOption.value,
            attachments: imageSrc,
            remarks: data.remarks,
        };
        
        try {
            await CreateTask(taskData);
        } catch (error) {
            snackBarStore.showSnackBar(`Error while Saving task: ${error}`, "error");
        }
        finally{
            stopLoading();
            navigate("/task");
        }
    };

    const handleSubmission = () => {
        setFormS(true);
        handleSubmit(onSubmit)();
    };

    useEffect(() => {
        const fetchTasktype = async () => {
            try {
                startLoading();
                const result = ["Lead", "Client"];
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskTypes(result.map((res) => ({
                            value: res,
                            label: res,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchTaskTo = async () => {
            try {
                startLoading();
                const result = await GetAllUsers();
                if (result) {
                    if (Array.isArray(result)) {
                        setTaskto(result.filter(x => x.roleName.toUpperCase() != "SuperAdmin".toUpperCase()).map((res) => ({
                            value: res.user.id,
                            label: `${res.user.firstName} ${res.user.lastName}`,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchPriorities = async () => {
            try {
                startLoading();
                const result = await GetDropValues("Priority");
                if (result) {
                    if (Array.isArray(result)) {
                        setPriorities(result.map((res) => ({
                            value: res.value01,
                            label: res.value01,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        const fetchClients = async () => {
            try {
                startLoading();
                const result = await GetAllClients();
                if (result) {
                    if (Array.isArray(result)) {
                        setClients(result.map((res) => ({
                            value: res.id,
                            label: res.name,
                        })));
                    }
                }
            } catch (error) {
                snackBarStore.showSnackBar(`Error fetching Task type: ${error}`, "error");
            }
            finally{
                stopLoading();
            }
        }

        fetchTasktype();
        fetchClients();
        fetchPriorities();
        fetchTaskTo();
    }, []);

    return (
        <>
        <div className="add-leads-form">
            
            <div className="add-leads-text">
                <div className="personal-text">Task Detail</div>
            </div>

            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task Name</div>

                    <input
                        className={`add-leads-input ${errors.taskName ? "error-border" : ""}`}
                        placeholder="Close Account"
                        {...register("taskName", { required: "Name is required" })}
                    />
                    {errors.fname && (
                        <span className="errorLogin label3">{errors.taskName.message}</span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task Type</div>

                    <Dropdown options={taskTypes}
                        className={`dropdown-fu dropdown-leads ${(formS && defaultOption.taskTypeOption === "") ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("taskTypeOption", e)}
                        value={defaultOption.taskTypeOption} placeholder="Select Task Type"
                    />
                    {(formS && defaultOption.taskTypeOption === "") && <span className="errorLogin label3">Task Type required</span>}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Task To</div>

                    <Dropdown options={taskTo}
                        className={`dropdown-fu dropdown-leads ${(formS && defaultOption.taskToOption === "") ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("taskToOption", e)}
                        value={defaultOption.taskToOption} placeholder="Select Person"
                    />
                    {(formS && defaultOption.taskToOption === "") && <span className="errorLogin label3">Assigned Person required</span>}
                </div>
            </div>

            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Client</div>
                    <Dropdown options={clients}
                        className={`dropdown-fu dropdown-leads ${(formS && defaultOption.clientOption === "") ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("clientOption", e)}
                        value={defaultOption.clientOption} placeholder="Select Client"
                    />
                    {(formS && defaultOption.clientOption === "") && <span className="errorLogin label3">Client is required</span>}
                </div>
                <div className={`add-leads-input-container ${errors.dueDate ? ' eb1' : ''}`}>
                    <div className="add-leadlabel">Due Date</div>
                    <input
                        type="text"
                        onFocus={(e) => e.target.type = 'date'}
                        onBlur={(e) => e.target.type = 'text'}
                        style={{ width: "100% !important" }}
                        className={`input-fu-1 add-leads-input input10 ${errors.dueDate ? 'error-border eb' : ''}`}
                        placeholder="Due Date"
                        {...register("dueDate", {
                            required: 'Due Date is required',
                        })}
                        aria-invalid={errors.dueDate ? 'true' : 'false'}
                    />
                    {errors.dueDate && <div className="errorLogin label3">{errors.dueDate.message}</div>}
                </div>
            </div>

            <div className="add-leads-addres">
                <div className="add-leadlabel">Service Request</div>
                <textarea
                    style={{ resize: "none", border: "2px solid #ccc" }}
                    rows={5}
                    className={`serivce-label ${errors.serviceRequest ? "error-border" : ""}`}
                    placeholder="Account close once redemption amt credited to this account"
                    {...register("serviceRequest", { required: "Service is required" })}
                />
                {errors.Cname && (
                    <span className="errorLogin label3">{errors.serviceRequest.message}</span>
                )}
            </div>

            <div className="add-leads-text">
                <div className="personal-text">Additional Detail</div>
            </div>

            <div className="add-leads-inputbox">
                <div className={`add-leads-input-container ${errors.startDate ? ' eb1' : ''}`}>
                    <div className="add-leadlabel">Start Date</div>
                    <input
                        type="text"
                        onFocus={(e) => e.target.type = 'date'}
                        onBlur={(e) => e.target.type = 'text'}
                        style={{ width: "100% !important" }}
                        className={`input-fu-1 add-leads-input input10 ${errors.startDate ? 'error-border eb' : ''}`}
                        placeholder="Start Date"
                        {...register("startDate", {
                            required: 'Start Date is required',
                        })}
                        aria-invalid={errors.startDate ? 'true' : 'false'}
                    />
                    {errors.startDate && <div className="errorLogin label3">{errors.startDate.message}</div>}
                </div>
                <div className={`add-leads-input-container ${errors.dater ? ' eb1' : ''}`}>
                    <div className="add-leadlabel">Reminder Date</div>
                    <input
                        type="text"
                        onFocus={(e) => e.target.type = 'date'}
                        onBlur={(e) => e.target.type = 'text'}
                        style={{ width: "100% !important" }}
                        className={`input-fu-1 add-leads-input input10 ${errors.reminderDate ? 'error-border eb' : ''}`}
                        placeholder="Reminder Date"
                        {...register("reminderDate", {
                            required: 'Reminder Date is required',
                        })}
                        aria-invalid={errors.reminderDate ? 'true' : 'false'}
                    />
                    {errors.reminderDate && <div className="errorLogin label3">{errors.reminderDate.message}</div>}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Priority</div>
                    <Dropdown options={priorities}
                        className={`dropdown-fu dropdown-leads ${(formS && defaultOption.priorityOption === "") ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("priorityOption", e)}
                        value={defaultOption.priorityOption} placeholder="Select Priority"
                    />
                    {(formS && defaultOption.priorityOption === "") && <span className="errorLogin label3">Priority is required</span>}
                </div>
            </div>

            <div className={`add-leads-input-container ${errors.attachments ? ' eb1' : ''}`}>
                <div className="add-leadlabel">Attachment</div>
                <input
                    type="text"
                    onFocus={(e) => e.target.type = 'file'}
                    onBlur={(e) => e.target.type = 'text'}
                    style={{ width: "100% !important" }}
                    className={`input-fu-1 add-leads-input input10 ${errors.attachments ? 'error-border eb' : ''}`}
                    placeholder="Upload file here"
                    {...register("attachments", {
                        required: 'File upload is required',
                    })}
                    onChange={handleFileChange}
                    aria-invalid={errors.attachments ? 'true' : 'false'}
                />
                {errors.attachments && <div className="errorLogin label3">{errors.attachments.message}</div>}
            </div>

            <div className="add-leads-addres">
                <div className="add-leadlabel">Remarks</div>
                <textarea
                    style={{ resize: "none", border: "2px solid #ccc" }}
                    rows={5}
                    className={`serivce-label ${errors.remarks ? "error-border" : ""}`}
                    placeholder="Query raised- 11310957"
                    {...register("remarks", { required: "Remark is required" })}
                />
                {errors.remarks && (
                    <span className="errorLogin label3">{errors.remarks.message}</span>
                )}
            </div>
            <div className="add-leads-button">
                <Button variant="smallButton" onClick={() => navigate("/task")}>Back</Button>
                <Button
                    type="submit"
                    variant="smallButton"
                    onClick={handleSubmission}
                >
                    Next
                </Button>
            </div>

        </div>
        </>
    );
}
