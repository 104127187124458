import { React, useState } from 'react';
import SVGIcons from '../Icons/svgicons';
import { FaAngleDown } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

function getIcon(iconName) {
    switch (iconName) {
        case "Dashboard":
            return <SVGIcons.Dashboard />;
        case "Leads":
            return <SVGIcons.DocumentFilter />;
        case "Clients":
            return <SVGIcons.User />;
        case "Task":
            return <SVGIcons.Bag />;
        case "Settings":
            return <SVGIcons.Settings2 />;
        case "Logout":
            return <SVGIcons.Logout />;
        case "Reports":
            return <SVGIcons.DocumentNormal />;
        case "Users":
            return <SVGIcons.User />;
        case "Roles":
            return <SVGIcons.DocumentNormal />;
        default:
            return null;
    }
}

export const SidebarNavigator = ({ name, sidebarSmall, onClick, active }) => {
    const [isRotated, setIsRotated] = useState(null);
    const navigate = useNavigate();

    const handleClick = () => {
        setIsRotated(prevState => prevState === name ? null : name);
        onClick();
        switch (name) {
            case "Dashboard":
                navigate('/dashboard');
                break;
            case "Leads":
                navigate('/leads');
                break;
            case "Clients":
                navigate('/clients');
                break;
            case "Task":
                navigate('/task');
                break;
            case "Reports":
                navigate('/reports');
                break;
            case "Users":
                navigate('/user');
                break;
            case "Roles":
                navigate('/role');
                break;
            default:
                return null;
        }

    };

    return (
        <>
            <div className={sidebarSmall ? "sidebarNavigator NavigatorSmall" : "sidebarNavigator"} id={active} onClick={handleClick}>
                <div className={name === "User" || name === "Role" ? "sidebaricon2" : "sidebaricon"}>
                    {getIcon(name)}
                </div>
                <div className={sidebarSmall ? "navigatorName1 navigatorName1small" : "navigatorName1"}>
                    <div className={sidebarSmall ? "label1 sidebarNavigatorSmall" : "label1 sidebarNavigatorBig"}>
                        {name}
                    </div>
                </div>
                {(name === "Settings") && <FaAngleDown className={sidebarSmall ? "sidebarDropdown sidebarDropdownSmall" : `sidebarDropdown ${isRotated === name ? 'rotatedDropdown' : ''}`} />}
                <div className="tooltipSidebar">
                    <div className="left-arrow"></div>
                    <div className='tooltipInner'>
                        <span className=''>{name}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarNavigator;

