import React, { createContext, useState } from 'react';

export const SidebarContext = createContext("");

export const SidebarProvider = ({ children }) => {
  const [activePage, setActivePage] = useState("Dashboard");

  return (
    <SidebarContext.Provider value={{ activePage, setActivePage }}>
      {children}
    </SidebarContext.Provider>
  );
};