import React from "react";
import sww from "../../Presentation/Assets/Svg/404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate(false);
  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img src={sww} alt="" style={{ height: "400px", marginTop: "5%" }} />
        <h1 className="mt-3">Page Not Found</h1>
        <p className="mt-2">
          Go back to
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleHome}
            className="mx-2"
          >
            Home Page
          </span>
        </p>
      </div>
    </>
  );
};

export default NotFound;
