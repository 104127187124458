import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/userAuth.ts";
import { CheckMenuAccess } from "../../../Services/MenusService.ts";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import PageLoader from "../../../Common/PageLoader/index.jsx";

const PrivateRoutes = ({ Component, requiredPermission, fallbackPath = '/dashboard' }) => {
  const isLoggedin = useAuth();
  const { loading, startLoading, stopLoading } = useLoading();
  const [hasPermission, setHasPermission] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedin) {
      return;
    }
  
    const fetchPermission = async () => {
      startLoading();
      const hasPermission = await checkPermission(requiredPermission, requiredPermission);
      setHasPermission(hasPermission);
      stopLoading();
    };
  
    fetchPermission();
  }, [isLoggedin, requiredPermission]);
  
  const checkPermission = async (reqPerm) => {
    try {
      if(!reqPerm.isProtected)
      {
        return true;
      }
      else{
        const result = await CheckMenuAccess(reqPerm.menuName, reqPerm.permissionName);
        return result;
      }
    } catch (error) {
      console.error('Error checking permission:', error);
      return false;
    }
  };
  

  if (!isLoggedin) {
    return navigate('/');
  }

  if (loading  > 0) {
    return <PageLoader/>;
  }

  if (!hasPermission) {
    snackBarStore.showSnackBar(
      `UnAuthorized Access: oops! you don't have rights to access this page`,
      "error"
    );
    return navigate(fallbackPath);
  }

  return <Component />;
};

export default PrivateRoutes;
