import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from '../../../Common/shared/Checkbox/checkbox';
import Button from '../../../Common/shared/Button/button';
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { fetchUserRoleWithPermissionsByRoleId, UpdateUserRoleWithPermissions } from "../../../Services/authService.ts";
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';

const Permissions = () => {
    const { roleId } = useParams();
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const { startLoading, stopLoading } = useLoading();
    const [headerCheckboxes, setHeaderCheckboxes] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            startLoading();
            const data = await fetchUserRoleWithPermissionsByRoleId(roleId);
            if (Array.isArray(data) && data.length > 0) {
                setRole(data[0]);

                const extractedPermissions = extractPermissions(data[0].permissionWithMenu);
                setPermissions(extractedPermissions);

                const initialHeaderCheckboxes = {};
                extractedPermissions.forEach(permission => {
                    initialHeaderCheckboxes[permission.toLowerCase()] = checkColumnCheckboxes(permission, data[0].permissionWithMenu);
                });
                setHeaderCheckboxes(initialHeaderCheckboxes);
            }
        } catch (error) {
            snackBarStore.showSnackBar(
                `Error while fetching permissions ${error}`,
                "error"
            );
        } finally {
            stopLoading();
        }
    };

    const extractPermissions = (permissionWithMenu) => {
        const allPermissions = permissionWithMenu.flatMap(menuItem => menuItem.permission.map(p => p.name));
        return [...new Set(allPermissions)]; // Get unique permissions
    };

    const checkColumnCheckboxes = (action, permissionWithMenu) => {
        return permissionWithMenu.every(menuItem =>
            menuItem.permission.find(permission => permission.name === action)?.status
        );
    };

    const handleCheckboxChange = (menuName, action) => {
        setRole(prevRole => ({
            ...prevRole,
            permissionWithMenu: prevRole.permissionWithMenu.map(menuItem =>
                menuItem.menuName === menuName
                    ? {
                        ...menuItem,
                        permission: menuItem.permission.map(permission =>
                            permission.name === action
                                ? { ...permission, status: !permission.status }
                                : permission
                        )
                    }
                    : menuItem
            )
        }));
    };

    const handleHeaderCheckboxChange = (action) => {
        const allChecked = !headerCheckboxes[action.toLowerCase()];
        setHeaderCheckboxes(prevState => ({
            ...prevState,
            [action.toLowerCase()]: allChecked
        }));
        setRole(prevRole => ({
            ...prevRole,
            permissionWithMenu: prevRole.permissionWithMenu.map(menuItem => ({
                ...menuItem,
                permission: menuItem.permission.map(permission =>
                    permission.name === action ? { ...permission, status: allChecked } : permission
                )
            }))
        }));
    };

    const handleSave = async () => {
        startLoading();
        await UpdateUserRoleWithPermissions(role.id, role);
        stopLoading();
        navigate("/role");
    };

    if (!role) {
        return <div>Role not found!</div>;
    }

    return (
        <div className='pagewrapper'>
            <div className='leads'>
                <div>
                    <div>
                        <table className='leadsTable roundedcorner' id='leadsTable'
                        style={{
                            display: "block",
                            overflow: "auto",
                            whiteSpace: "nowrap"}}>
                            <thead>
                                <tr className='body2 roundedcorner'>
                                    <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                                        Permission
                                    </th>
                                    {permissions.map(action => (
                                        <th key={action}>
                                            <div className="leadsTH">
                                                <div className="check-perm">
                                                    <Checkbox
                                                        checked={headerCheckboxes[action.toLowerCase()]}
                                                        onChange={() => handleHeaderCheckboxChange(action)}
                                                    />{action}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {role.permissionWithMenu.map(menuItem => (
                                    <tr key={menuItem.menuName}>
                                        <td className='label2'>{menuItem.menuName}</td>
                                        {permissions.map(permissionName => {
                                            const permission = menuItem.permission.find(p => p.name === permissionName);
                                            return (
                                                <td key={permissionName} className='label2'>
                                                    <Checkbox
                                                        type="checkbox"
                                                        checked={permission?.status || false}
                                                        onChange={() => handleCheckboxChange(menuItem.menuName, permissionName)}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="add-leads-button" style={{ marginTop: "25px" }}>
                        <Button variant="smallButton" onClick={() => navigate('/role')}>Back</Button>
                        <Button type="submit" variant="smallButton" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Permissions;
