import React, { useState, useEffect, Component } from 'react';
import "./login.css";
import login from "../../Assets/Svg/Group.png";
import logo from "../../Assets/Svg/logo.png";
import Button from '../../../Common/shared/Button/button';
import { useForm } from "react-hook-form";
import "../../../Common/shared/Input/input.css";
import { useNavigate } from 'react-router-dom';
import { userLoginApi } from '../../../Services/authService.ts';
import { useAuth } from "../../../Utils/userAuth.ts";
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import PageLoader from "../../../Common/PageLoader/index.jsx";

export const LoginPage = () => {
    const navigate = useNavigate();
    const isLoggedIn = useAuth();
    const { loadingCount, startLoading, stopLoading } = useLoading();
    const [showPassword, setShowPassword] = useState(false);
    
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = async (data, event) => {
        event.preventDefault();
        startLoading();
        try{
            await userLoginApi(data);
            stopLoading();
            navigate("/dashboard");
        }
        catch(error){
            stopLoading();
            snackBarStore.showSnackBar(
                `Problem in login: ${error}`,
                "error"
              );
        }
    };

    return (
        <div className='pagewrapper'>
            {loadingCount > 0  && <PageLoader />}
            <div className="loginContainer">
                <div className="left-section">
                    <div className="loginform">
                        <div className="logo">
                            <img src={logo} alt="" />
                            <div className="logoText">
                                <h5>INSURE</h5>
                                <h6>CRM</h6>
                            </div>
                        </div>
                        <div className="loginHeading">
                            <h3>Login</h3>
                            <div className="label5 label5text">Login with your data that you entered during your registration</div>
                        </div>

                        <form className="loginFormInput" onSubmit={handleSubmit(onSubmit)}>
                            <div className="inputForm">
                                <label htmlFor="Tenant">Enter Customer Id</label>
                                <input
                                    type='text'
                                    className={`input10 ${errors.tenant ? 'error-border' : ''}`}
                                    placeholder="Tenant"
                                    {...register("tenant", {
                                        required: 'Customer Id is required'
                                    })}
                                    aria-invalid={errors.tenant ? 'true' : 'false'}
                                />
                                {errors.tenant && <div className="errorLogin label3">{errors.tenant.message}</div>}
                            </div>
                            <div className="inputForm">
                                <label htmlFor="Email">Email</label>
                                <input
                                    type='text'
                                    className={`input10 ${errors.email ? 'error-border' : ''}`}
                                    placeholder="Email"
                                    {...register("email", {
                                        required: 'Email Address is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                />
                                {errors.email && <div className="errorLogin label3">{errors.email.message}</div>}
                            </div>
                            <div className="inputForm">
                                <label htmlFor="Password">Password</label>
                                <div className="password-wrapper">
                                    <input
                                        placeholder="Password"
                                        className={`input10 ${errors.password ? 'error-border' : ''}`}
                                        type={showPassword ? 'text' : 'password'}
                                        {...register("password", {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/,
                                                message: 'Password must contain at least 8 characters, including upper/lowercase, number, and a special character'
                                            }
                                        })}
                                        aria-invalid={errors.password ? 'true' : 'false'}
                                    />
                                    <button
                                        type="button"
                                        className="eye-button"
                                        onClick={togglePasswordVisibility}
                                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                                    >
                                        {showPassword ? '👁️‍🗨️' : '👁️'}
                                    </button>
                                </div>
                                {errors.password && <div className="errorLogin label3">{errors.password.message}</div>}
                            </div>

                            <div className="buttoncontainer">
                                <Button variant="largeButton">Login</Button>
                            </div>
                            <div className="downContainer">
                                <div className="downcontainer1">
                                    <div className="label1 navigator" style={{ fontWeight: "500", color: "#696E77" }} onClick={() => navigate('/forgotpassword')}>Trouble logging in? Click here to reset your password</div>
                                </div>
                                <div className="downcontainer2">
                                    <div className="label2 navigator" style={{ fontWeight: "500", color: "#696e77e6" }} onClick={() => navigate('/register')}>Don't have an account? Sign up</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="login-right-section">
                    <div className="loginImage">
                        <img src={login} alt="" className="loginImg" />
                    </div>
                </div>
            </div>
        </div>
    );
};
