export const TaskInfo = [
    {
        name: "Due Date",
    },
    {
        name: "Service Request",
    },
];
export const AddDetials = [
    {
        name: "Start Date",
    },
    {
        name: "Reminder Date",
    },
    {
        name: "Priority",
    },
    {
        name: "Remarks",
    },
];