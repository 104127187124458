import { React } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";

export default function EditRole() {
    const location = useLocation();
    const item = location.state?.item;
    const navigate = useNavigate();
    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm();
    
    const onSubmit = (data) => {
      if (Object.keys(errors).length === 0) {
        navigate('/role')
        snackBarStore.showSnackBar(`Success: Role Updated`, "success");
      }
    };


  const handleAddButtonClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
        <div className="add-leads-form">
          <div className="add-leads-text">
            <div className="personal-text">Edit Role</div>
          </div>

          <div className="add-leads-inputbox">
            <div className="add-leads-input-container">
              <div className="add-leadlabel">Role Name</div>
              <input
                className={`add-leads-input ${
                  errors.rname ? "error-border" : ""
                }`}
                placeholder="Admin"
                value={item.name}
                {...register("rname", { required: "Role is required" })}
              />
              {errors.rname && (
                <span className="errorLogin label3">
                  {errors.rname.message}
                </span>
              )}
            </div>
            <div className="add-leads-input-container">
              <div className="add-leadlabel"></div>
            </div>
            <div className="add-leads-input-container">
              <div className="add-leadlabel"></div>
            </div>
          </div>

          <div className="add-leads-button">
            <Button variant="smallButton" onClick={() => navigate('/role')}>Back</Button>
            <Button
              type="submit"
              variant="smallButton"
              onClick={handleAddButtonClick}
            >
              Next
            </Button>
          </div>
        </div>
    </>
  );
}