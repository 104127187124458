import React, { useContext, useState } from "react";
import 'react-dropdown/style.css';
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Services/authService.ts"

function ProfileSlide({ name, item }) {
    const [displayModal, setDisplayModal] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(navigate);
    };

    const handleClick = () => {
        setDisplayModal(!displayModal);
        navigate("/profile");
    } 

    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal-3 ${displayModal ? "leadShow-3" : ""} `}>
            <div className="profileHead label2" onClick={handleClick}>Profile</div>
            <div className="logHead label2" onClick={handleLogout}>Log out</div>
            </div>
            <div
                className={` ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
            <p className="Alert">{alert}</p>
        </div>
    );
}


export default ProfileSlide;