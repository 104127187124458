import '../leads/leads.css'
import { Search } from '../../../Common/shared/Search/search'
import SVGIcons from '../../../Common/shared/Icons/svgicons'
import React, { useState, useEffect, useContext } from 'react';
import "../leads/table.css";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaAngleDown } from 'react-icons/fa6'
import LeadFollowUp from '../followUp/followUp';
import { DateContext } from '../../../Services/ContextAPI/dateContext';
import { useNavigate } from 'react-router-dom';
import { ClientStatus } from '../../../Common/shared/clientStatus/clientStatus';
import ClientInfo from './clientInfo';
import { GetAllClients } from "../../../Services/clientService.ts";
import 'react-dropdown/style.css';

export const Clients = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [10, 15, 20, 25]; // Options for items per page
    const { activeDate } = useContext(DateContext)
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const dataMain = await GetAllClients();
            setData(dataMain)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Filter data based on search term if there's a search term, otherwise return all data
    const filteredData = searchTerm
        ? data.filter(item => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                (item.serialNumber.toString().toLowerCase().includes(term)) ||
                (item.name.toString().toLowerCase().includes(term))
            );
        })
        : data.filter(item => activeDate === '' || item.date === activeDate);


    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle pagination
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate which page numbers to display (current, previous, next)
    const pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    // Get current items to display based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleNavigate = (item, event) => {
        event.preventDefault();
        navigate(`/clients/editclient/user/${item.id}`); // Pass item as state
    };

    //FOLLOW UP 
    const [displayModal2, setDisplayModal2] = useState(false);
    // const [dropdownVisible, setDropdownVisible] = useState(false);
    const [displayFollowUp2, setDisplayFollowUp2] = useState(false)
    const [form, setForm] = useState(false);

    const options2 = [
        { id: 1, name: 'Option 1' },
        { id: 2, name: 'Option 2' },
        { id: 3, name: 'Option 3' },
        // Add more options as needed
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };

    const handleRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
    };

    const handleStatusChange = (option) => {
        // setSelectedStatus(option.value);
        if (option.value === "three") {
            setDisplayFollowUp2(true);
        } else {
            setDisplayFollowUp2(false);
        }
    };

    useEffect(() => {
        const clearMessage = setTimeout(() => {
        }, 5000);
        return () => clearTimeout(clearMessage);
    }, [alert]);

    // const onSubmit = (data) => {
    //     console.log(data); 
    //     setDisplayModal2(!displayModal2)
    //     toast.success("follow up Added")
    // };

    return (
        <div className='pagewrapper'>
            <div className='leads'>
                <div className="topLeads">
                    <div className="serachDrop">
                        <div className="dropdown label3 tablePageStatus">
                            <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                            <div className="dropdown-content">
                                {itemsPerPageOptions.map(option => (
                                    <div>
                                        <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                    </div>

                </div>
                <div>
                    <div>
                        <table className='leadsTable roundedcorner' id='leadsTable'>
                            <thead>
                                <tr className='body2 roundedcorner'>
                                    <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                                        Customer id <PiCaretUpDownFill size={12} />
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Customer Name <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Date <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Contact No. <PiCaretUpDownFill />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Review Date <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="leadsTH">
                                            Status <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                    <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                                        <div className="leadsTH">
                                            Action <PiCaretUpDownFill size={12} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className='label2'>{item.id}</td>
                                        <td className='label2'>
                                            <ClientInfo name={item.name} item={item} onClose />
                                            
                                        </td>
                                        <td className='label2'>{item.date}</td>
                                        <td className='label2'>{item.phoneNumber}</td>
                                        <td className='label2'>{item.date}</td>
                                        <td className='label2'>
                                            <ClientStatus status={item.clientStatus} />
                                        </td>
                                        <td className={`actionsTable ${item.clientStatus === "Document Recieved \nand Under Process" ? "at-2" : ""
                                            }`}>
                                            <SVGIcons.Edit onClick={(event) => handleNavigate(item, event)} />
                                            <SVGIcons.Trash />
                                            <LeadFollowUp name={<SVGIcons.UserSearch />} item={item} />
                                            {/* <SVGIcons.UserSearch/> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='leadsTableBottom'>
                            <div className="leadsTableBottomLeft">
                                <span className='body2 tablePageStatus'>
                                    Showing {rangeStart} to {rangeEnd} of {data.length} results
                                </span>

                            </div>

                            <div className="leadsTablePaginationButtons">
                                <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                    <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                </button>

                                {pageNumbersToDisplay.map((pageNumber) => (
                                    <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                        {pageNumber}
                                    </button>
                                ))}
                                <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                    <MdOutlineKeyboardDoubleArrowRight size={28} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

