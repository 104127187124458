import React, { useState, useEffect } from "react";
import close from "../../Assets/png/close-square.png"
import "./LeadInfo.css";
import { FaAngleDown } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa6";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { PiCaretUpDownFill } from "react-icons/pi";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } 
from "react-icons/md";
import Button from "../../../Common/shared/Button/button";
import AddServices from "../../../Common/shared/addService/addService";
import { ContactInfo, OccupationInfo } from "./leadInfo.js"
import { GetLeadById } from "../../../Services/leadService.ts";
import { GetFollowUpByLeadId } from "../../../Services/followUpService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";

function LeadInfo({ name, item }) {
    const [alert, setAlert] = useState("");
    const { startLoading, stopLoading } = useLoading();
    const [displayModal, setDisplayModal] = useState(false);
    const itemsPerPage = 20;
    const [data, setData] = useState([]);
    const [FollowupData, setFollowupData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [leadHistory, setLeadHistory] = useState(true)

    useEffect(() => {
        fetchData();
        fetchFollowupData();
    }, []);

    const fetchData = async () => {
        try {
            startLoading();
            const response = await GetLeadById(item.id);
            setData(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            stopLoading();
        }
    };

    const fetchFollowupData = async () => {
        try {
            startLoading();
            const response = await GetFollowUpByLeadId(item.id);
            setFollowupData(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            stopLoading();
        }
    };

    function getinfo(name) {
        switch (name) {
            case "Mobile Number":
                return data.mobileNo;
            case "Email Id":
                return data.emailId;
            case "Whatsapp Number":
                return data.mobileNo;
            case "Address":
                return `${data.addressLine1} ${data.addressLine2}`;
            case "City":
                return data.cityName;
            case "State":
                return data.stateName;
            case "Country":
                return data.countryName;
            case "Pincode":
                return data.pincode;
            case "Occupation":
                return data.occupation;
            case "Type of Work":
                return data.workType;
            case "Monthly Income":
                return data.monthlyIncome;
            default:
                return null;
        }
    }

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Function to handle pagination
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate which page numbers to display (current, previous, next)
    const pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    // Get current items to display based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.serviceDetails?.length > 0 ? data.serviceDetails?.slice(indexOfFirstItem, indexOfLastItem) : [];

    useEffect(() => {
        const clearMessage = setTimeout(() => {
            setAlert("");
        }, 5000);
        return () => clearTimeout(clearMessage);
    }, [alert]);

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const getFilename = (url) => {
        try {
            const urlObj = new URL(url);
            const pathname = urlObj.pathname;
            const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
            return filename;
        } catch (error) {
            snackBarStore.showSnackBar(`Invalid Attachment URL`, "error");
          return '';
        }
    }

    const handleDownload = (fileUrl) => {
        return async () => {
            try {
                startLoading();
                const response = await fetch(fileUrl);
                const contentDisposition = response.headers.get('content-disposition');
                const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
                const fileName = fileNameMatch ? fileNameMatch[1] : 'download';
                const anchor = document.createElement('a');
                anchor.href = fileUrl;
                anchor.download = fileName;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            } catch (error) {
                snackBarStore.showSnackBar(`Error while downloading file: ${error}`, "error");
            } finally {
                stopLoading();
            }
        };
    };

    return (
        <div className="App2">
            <div
                className="Button2"
                onClick={() => setDisplayModal(!displayModal)}
            >
                {name}
            </div>

            <div className={`leadModal ${displayModal ? "leadShow" : ""} `}>
                <div
                    className="leadinfo-Top"
                >
                    <h6 className="leadInfo-heading">Lead Information</h6>
                    <img src={close} alt="" className="leadinfo-closebutton" onClick={() => setDisplayModal(!displayModal)} />
                </div>
                <div className="leadinfo-content">
                    <div className="leadinfo-contentTop">
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Lead Id</div>
                            <div className="leadinfo-data1 label1">{item.id}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Name</div>
                            <div className="leadinfo-data1 label1">{item.customerName}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Lead Status</div>
                            <div className="leadinfo-data1 label1">{item.leadStatus}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Lead Owner</div>
                            <div className="leadinfo-data1 label1">{data.createdByName}</div>
                        </div>
                        <div className="leadinfo-contentTop-section">
                            <div className="label3">Lead Source</div>
                            <div className="leadinfo-data1 label1">{data.leadSourceName}</div>
                        </div>
                    </div>

                    <hr className="leadinfoHr" />

                    <div className="leadInfoBox">
                        <button className={`accordion ${activeIndex === 0 ? "activeLeadInfo" : ""}`} onClick={() => toggleAccordion(0)}>
                            <div className="label1">
                                Contact Information
                            </div>
                            <div >
                                <FaAngleDown size={20} className={`accordion-icon  ${activeIndex === 0 ? "acordion-icon-active" : ""}`} />
                            </div>
                        </button>
                        <div className={`panel ${activeIndex === 0 ? "activeLeadInfo" : ""}`}>
                            {ContactInfo.map((item1, index) => (
                                <div className="leadInfo-single label5">
                                    <div className="leadInfoname">{item1.name}</div>
                                    <div className="leadInfovalue">{getinfo(item1.name)}</div>
                                </div>
                            ))}

                        </div>

                        <button className={`accordion label1 ${activeIndex === 1 ? "activeLeadInfo" : ""}`} onClick={() => toggleAccordion(1)}>
                            <div className="label1">
                                Occupation Details
                            </div>
                            <span className="accordion-icon">
                                <FaAngleDown size={20} />
                            </span>
                        </button>
                        <div className={`panel ${activeIndex === 1 ? "activeLeadInfo" : ""}`}>
                            {OccupationInfo.map((item1, index) => (
                                <div className="leadInfo-single">
                                    <div className="leadInfoname">{item1.name}</div>
                                    <div className="leadInfovalue">{getinfo(item1.name)}</div>
                                </div>
                            ))}
                        </div>

                        <button className={`accordion label1 ${activeIndex === 2 ? "activeLeadInfo" : ""}`} onClick={() => toggleAccordion(2)}>
                            <div className="label1">
                                Interested Services
                            </div>
                            <span className="accordion-icon">
                                <FaAngleDown size={20} />
                            </span>
                        </button>
                        <div className={`panel ${activeIndex === 2 ? "activeLeadInfo" : ""}`}>
                            {/* {InterestedService.map((item1, index) => (
                                <div className="leadInfo-single">
                                    <div className="leadInfoname">{item1.name}</div>
                                    <div className="leadInfovalue">{getinfo(item1.name)}</div>
                                </div>
                            ))} */}
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "10px 0px  0px  0px" }} className="vraj">
                                            <div className="leadsTH" >
                                                Dates <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className="vraj">
                                            <div className="leadsTH">
                                                Services <PiCaretUpDownFill />
                                            </div>
                                        </th>
                                        <th style={{ borderRadius: "0px 10px  0px  0px" }}>
                                            <div className="leadsTH" style={{ borderRadius: "0px 10px  0px  0px" }}>
                                                Remark <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td className='label2'>{data.leadDate}</td>
                                            <td className='label2'>
                                                {item.serviceName}
                                            </td>
                                            <td className='label2'>
                                                {item.remark}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* <tr>
                                        <td className='label2'>{currentItems.createdOn}</td>
                                        <td className='label2'>
                                            {item.leadStatus}
                                        </td>
                                        <td className='label2'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda at delectus, fuga id dicta dolore
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div className='leadsTableBottom'>
                                <div className="leadsTableBottomLeft">
                                    <span className='body2 tablePageStatus'>
                                        Showing {rangeStart} to {rangeEnd} of {data.length} results
                                    </span>

                                </div>

                                <div className="leadsTablePaginationButtons">
                                    <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                    </button>

                                    {pageNumbersToDisplay.map((pageNumber) => (
                                        <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowRight size={28} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leadI-service1">
                        <div className="services-li">
                            <div className="label2 service1">Suggested Services:</div>
                            {currentItems.map((item, index) => (
                                <div className="label3 service2">{item.serviceName}</div>
                            ))}
                        </div>
                        <AddServices leadData={data} name={
                            <Button variant="yellowButton" prefixIcon={<SVGIcons.Add />} className="addService">Add Service</Button>
                        } />
                    </div>

                    <div className="history">
                        <div className="historyHead label4">
                            <div className="historyHead1" onClick={() => { setLeadHistory(true) }}>
                                <div className={!leadHistory ? "historyHead3 historyHead2" : "historyHead2"}>Lead History</div>
                                <div className="historyHr"></div>
                            </div>
                            {/* <div className="historyHead1" onClick={() => { setLeadHistory(false) }}>
                                <div className={leadHistory ? "historyHead3 historyHead2" : "historyHead2"}>Task History</div>
                                <div className="historyHr"></div>
                            </div> */}
                        </div>
                        {leadHistory && FollowupData.length > 0 ?
                            <>
                            {FollowupData.map((val) => (
                                <div>
                                    <div className="history1">
                                        <div className="history1-left">
                                            <div className="history-circle">
                                                <div className="history-circle1"></div>
                                                <div className="history-circle2"></div>
                                            </div>
                                            <div className="history1-text">
                                                <div className="history-name">{val.assignedToName}</div>
                                                <div className="history1-text2 label3">
                                                    <div className="label3">{val.followupStatusName}</div>
                                                    <div className="label3">Date {val.followupDate} {new Date(val.followupTime).getTime()}</div>
                                                </div>
                                                <div className="history1-text3 label3">
                                                    <div className="history1-text3-1">{val.remark}</div>
                                                </div>
                                                <div className="history1-pdf">
                                                    <div className="history1-pdf1">
                                                        <FaRegFilePdf size={18}  onClick={handleDownload(val.attachmentUrl)}/>
                                                    </div>
                                                    <div className="history1-pdf2">
                                                        {getFilename(val.attachmentUrl)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="history1-right">{val.followupDate}</div>
                                    </div>
                                </div>  
                            ))}
                            </>
                            : 'No Records Found !!!'
                        }
                        {!leadHistory && <div>
                            <div className="history1">
                                <div className="history1-left">
                                    <div className="history-circle">
                                        <div className="history-circle1"></div>
                                        <div className="history-circle2"></div>
                                    </div>
                                    <div className="history1-text">
                                        <div className="history-name">Jay. P</div>
                                        <div className="history1-text2 label3">
                                            <div className="label3">Jay. P Add Next Follow Up </div>
                                            <div className="label3">Date 25-02-2024 02.00 PM</div>
                                        </div>
                                        <div className="history1-text3 label3">
                                            <div className="history1-text3-1">Lorem Ipsum has been the industry's standard ever since</div>
                                            <div className="history1-text3-2">See more.....</div>
                                        </div>
                                        <div className="history1-pdf">
                                            <div className="history1-pdf1">
                                                <FaRegFilePdf size={18} />
                                            </div>
                                            <div className="history1-pdf2">
                                                Insurnace.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="history1-right">20-02-2024</div>
                            </div>
                            <div className="history1">
                                <div className="history1-left">
                                    <div className="history-circle">
                                        <div className="history-circle1"></div>
                                        <div className="history-circle2"></div>
                                    </div>
                                    <div className="history1-text">
                                        <div className="history-name">Jay. P</div>
                                        <div className="history1-text2 label3">
                                            <div className="label3">Jay. P Add Next Follow Up </div>
                                            <div className="label3">Date 25-02-2024 02.00 PM</div>
                                        </div>
                                        <div className="history1-text3 label3">
                                            <div className="history1-text3-1">Lorem Ipsum has been the industry's standard ever since</div>
                                            <div className="history1-text3-2">See more.....</div>
                                        </div>
                                        <div className="history1-pdf">
                                            <div className="history1-pdf1">
                                                <FaRegFilePdf size={18} />
                                            </div>
                                            <div className="history1-pdf2">
                                                Insurnace.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="history1-right">20-02-2024</div>
                            </div>
                        </div>}
                    </div>


                </div>
                {/* </div> */}
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />
            <p className="Alert">{alert}</p>
        </div>
    );
}


export default LeadInfo;