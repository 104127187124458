export const ContactInfo = [
    {
        name: "Mobile Number",
    },
    {
        name: "Email Id",
    },
    {
        name: "Whatsapp Number",
    },
    {
        name: "Address",
    },
    {
        name: "City",
    },
    {
        name: "State",
    },
    {
        name: "Country",
    },
    {
        name: "Pincode",
    },
];

export const OccupationInfo = [
    {
        name: "Occupation",
    },
    {
        name: "Type of Work",
    },
    {
        name: "Monthly Income",
    },
];
export const InterestedService = [
    {
        name: "Product",
    },
    {
        name: "Company",
    },
    {
        name: "Plan",
    },
];
