import React, { useContext, useEffect, useState } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useNavigate } from "react-router-dom";
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { GetCountries, GetCityByStateId, GetStatesByCountryId } from "../../../Services/CascadinCountryStateCity.ts";
import { fetchAllRoles } from "../../../Services/authService.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";
import { CreateNewUser } from "../../../Services/authService.ts";

export default function AddUser() {
    const [WhatsAppNo, setWhatsAppNo] = useState("");
    const [PhoneNo, setPhoneNo] = useState('');
    const navigate = useNavigate();
    const [formS, setFormS] = useState(false);
    const [defaultOption, setDefaultOption] = useState({
        roleOption: null,
        countryOption: null,
        stateOption: null,
        cityOption: null,
    });
    const [syncInputs, setSyncInputs] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [roles, setRoles] = useState([]);
    const { startLoading, stopLoading } = useLoading();

    const handleDropdownChange = (dropDwnId, selected) => {
        setDefaultOption((prev) => ({
            ...prev,
            [dropDwnId]: selected,
        }));
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                startLoading();
                const result = await GetCountries();
                if (result) {
                    if (Array.isArray(result)) {
                        setCountries(result.map((country) => ({
                            value: country.id,
                            label: country.countryName,
                        })));
                    }
                }
            } catch (error) {
                console.error("Error fetching Countries:", error);
            }
            finally{
                stopLoading();
            }
        }

        const fetchRoles = async () => {
            try {
                startLoading();
                const result = await fetchAllRoles();
                if (result) {
                    if (Array.isArray(result)) {
                        setRoles(result.map((role) => ({
                            value: role.name,
                            label: role.name,
                        })));
                    }
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
            finally{
                stopLoading();
            }
        }

        fetchCountries();
        fetchRoles();
    }, []);

    const handleCountryChange = async (option) => {
        try {
            startLoading();
            const result = await GetStatesByCountryId(option.value);
            if (result) {
                if (Array.isArray(result)) {
                    setStates(result.map((state) => ({
                        value: state.id,
                        label: state.stateName,
                    })));
                    handleDropdownChange("countryOption", option);
                }
            }
        } catch (error) {
            console.error("Error fetching states:", error);
        } finally {
            stopLoading();
        }
    }

    const handleStateChange = async (option) => {
        try {
            startLoading();
            const result = await GetCityByStateId(option.value);
            if (result) {
                if (Array.isArray(result)) {
                    setCities(result.map((city) => ({
                        value: city.id,
                        label: city.cityName,
                    })));
                    handleDropdownChange("stateOption", option);
                }
            }
        } catch (error) {
            console.error("Error fetching cities:", error);
        } finally {
            stopLoading();
        }
    }

    const onSubmit = async (data) => {
        if (PhoneNo === "" || WhatsAppNo === "") {
            setFormS(true);
            return;
        }
        data.phoneNumber = PhoneNo;
        data.WhatsAppNumber = WhatsAppNo;
        data.stateId = defaultOption.stateOption.value;
        data.cityId = defaultOption.cityOption.value;
        data.countryId = defaultOption.countryOption.value;
        data.roleName = defaultOption.roleOption.value;
        try {
            startLoading();
            await CreateNewUser(data);
            navigate("/user");
        } catch (error) {
            snackBarStore.showSnackBar(
                `Error while Creating New User ${error.response.data?.exception}`,
                "error"
            );
        }
        finally{
            stopLoading();
        }
    };

    const handleSubmission = () => {
        setFormS(true)
        handleSubmit(onSubmit)();
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setPhoneNo(value);
    };

    const handleWInputChange = (e) => {
        const { value } = e.target;
        setWhatsAppNo(value);
    };

    const handleCheckboxChange = (event) => {
        setSyncInputs(event.target.checked)
        if (event.target.checked) {
            setWhatsAppNo(PhoneNo)
        }
    };

    return (
        <>
        <div className="add-leads-form">
            <div className="add-leads-text">
                <div className="personal-text">Add User</div>
            </div>
            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Name</div>
                    <input
                        className={`add-leads-input ${errors.firstName ? "error-border" : ""
                            }`}
                        placeholder="Rajiv"
                        {...register("firstName", { required: "Name is required" })}
                    />
                    {errors.firstName && (
                        <span className="errorLogin label3">
                            {errors.firstName.message}
                        </span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Last Name</div>
                    <input
                        className={`add-leads-input ${errors.lastName ? "error-border" : ""
                            }`}
                        placeholder="Sharma"
                        {...register("lastName", { required: "Last name is required" })}
                    />
                    {errors.lastName && (
                        <span className="errorLogin label3">
                            {errors.lastName.message}
                        </span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Assign Role</div>
                    <Dropdown options={roles}
                        className={`dropdown-fu dropdown-leads ${(formS && !defaultOption.roleOption) ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("roleOption", e)}
                        value={defaultOption.roleOption}
                    />
                    {(formS && !defaultOption.roleOption) && <span className="errorLogin label3">Role is required</span>}
                </div>
            </div>
            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Username</div>
                    <input
                        className={`add-leads-input ${errors.username ? "error-border" : ""
                            }`}
                        placeholder="Rajiv"
                        {...register("username", { required: "Username is required" })}
                    />
                    {errors.username && (
                        <span className="errorLogin label3">
                            {errors.username.message}
                        </span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel"></div>
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel"></div>
                </div>
            </div>

            <div className="add-leads-inputbox">

                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Email id</div>
                    <input
                        className={`add-leads-input ${errors.email ? "error-border" : ""
                            }`}
                        placeholder="rajiv.sharma@gmail.com"
                        {...register("email", { required: "Email id is required" })}
                    />
                    {errors.email && (
                        <span className="errorLogin label3">
                            {errors.email.message}
                        </span>
                    )}
                </div>

                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Password</div>
                    <input
                        type="password"
                        className={`add-leads-input ${errors.password ? "error-border" : ""
                            }`}
                        placeholder="********"
                        {...register("password", { required: "Password is required" })}
                    />
                    {errors.password && (
                        <span className="errorLogin label3">
                            {errors.password.message}
                        </span>
                    )}
                </div>

                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Confirm Password</div>
                    <input
                        className={`add-leads-input ${errors.confirmPassword ? "error-border" : ""
                            }`}
                        type="password"
                        placeholder="********"
                        {...register("confirmPassword", { required: "Confirm Password is required" })}
                    />
                    {errors.confirmPassword && (
                        <span className="errorLogin label3">
                            {errors.confirmPassword.message}
                        </span>
                    )}
                </div>


            </div>

            <div className="add-leads-text">
                <div className="personal-text">Contact Detail</div>
            </div>


            <div className="add-leads-inputbox">
                <div className="add-leads-input-container">
                    <div className="add-leadlabel">Mobile Number</div>
                    <input
                        className={`add-leads-input ${PhoneNo === "" ? (formS ? "error-border" : "") : ""}`}
                        onChange={handleInputChange}
                        type="number"
                        placeholder="+919876543211"
                    />
                    {(PhoneNo === "" && formS) && (
                        <span className="errorLogin label3">
                            Phone no. is required
                        </span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="wp-phone">
                        <div className="add-leadlabel">WhatsApp Number </div>
                        <div className="wp-phone-2">
                            <input
                                type="checkbox"
                                checked={syncInputs}
                                onChange={handleCheckboxChange}
                                style={{ border: "2px solid #00f", display: "block", backgroundColor: "#fafa" }}
                            />
                            <div className="same-no">same as mobile number</div>
                        </div>
                    </div>
                    <input
                        className={`add-leads-input ${WhatsAppNo === "" && formS ? "error-border" : ""
                            }`}
                        type="number"
                        value={syncInputs ? PhoneNo : WhatsAppNo}
                        onChange={handleWInputChange}
                        placeholder="+919876543210"

                    />
                    {(WhatsAppNo === "" && formS) && (
                        <span className="errorLogin label3">
                            WhatsApp no. is required
                        </span>
                    )}
                </div>
                <div className="add-leads-input-container">
                    <div className="add-leadlabel"></div>
                </div>
            </div>

            <div className="add-leads-addres">
                <div className="add-leadlabel">Address Line 1</div>
                <input
                    className={`add-leads-aadresinput ${errors.addressLine1 ? "error-border" : ""
                        }`}
                    placeholder="Build 1/A, 101, Shree krishna society"
                    {...register("addressLine1", { required: "Address is required" })}
                />
                {errors.addressLine1 && (
                    <span className="errorLogin label3">
                        {errors.addressLine1.message}
                    </span>
                )}
            </div>

            <div className="add-leads-addres">
                <div className="add-leadlabel">Address Line 2</div>
                <input
                    className={`add-leads-aadresinput ${errors.addressLine2 ? "error-border" : ""
                        }`}
                    placeholder="Build 1/A, 101, Shree krishna society"
                    {...register("addressLine2", { required: "Address is required" })}
                />
                {errors.addressLine2 && (
                    <span className="errorLogin label3">
                        {errors.addressLine2.message}
                    </span>
                )}
            </div>

            <div className="add-leads-location">
                <div className="add-leads-input-town">
                    <div className="add-leadlabel">Country</div>
                    <Dropdown options={countries}
                        className={`dropdown-fu dropdown-leads ${(formS && !defaultOption.countryOption) ? 'error-border' : ''}`}
                        onChange={(e) => handleCountryChange(e)}
                        value={defaultOption.countryOption}
                    />
                    {(formS && !defaultOption.countryOption) && <span className="errorLogin label3">Country required</span>}
                </div>
                <div className="add-leads-input-town">
                    <div className="add-leadlabel">State</div>
                    <Dropdown options={states}
                        className={`dropdown-fu dropdown-leads ${(formS && !defaultOption.stateOption) ? 'error-border' : ''}`}
                        onChange={(e) => handleStateChange(e)}
                        value={defaultOption.stateOption}
                    />
                    {(formS && !defaultOption.stateOption) && <span className="errorLogin label3">State required</span>}
                </div>
                <div className="add-leads-input-town">
                    <div className="add-leadlabel">City</div>
                    <Dropdown options={cities}
                        className={`dropdown-fu dropdown-leads ${(formS && !defaultOption.cityOption) ? 'error-border' : ''}`}
                        onChange={(e) => handleDropdownChange("cityOption", e)}
                        value={defaultOption.cityOption}
                    />
                    {(formS && !defaultOption.cityOption) && <span className="errorLogin label3">City required</span>}
                </div>
                <div className="add-leads-input-town">
                    <div className="add-leadlabel">Pin code</div>
                    <input
                        className={`add-leads-input ${errors.pinCode ? "error-border" : ""
                            }`}
                        placeholder="400601"
                        {...register("pinCode", { required: "Pin Code is required" })}
                    />
                    {errors.pinCode && (
                        <span className="errorLogin label3">
                            {errors.pinCode.message}
                        </span>
                    )}
                </div>
            </div>

            <div className="add-leads-button">
                <Button variant="smallButton" onClick={() => navigate("/user")}>Back</Button>
                <Button
                    type="submit"
                    variant="smallButton"
                    onClick={handleSubmission}
                >
                    Submit
                </Button>
            </div>
        </div>
        </>
    );
}
