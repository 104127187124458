import React from "react";
import { LoginPage } from "./Presentation/Pages/loginPage/loginPage";
import { RegisterPage } from "./Presentation/Pages/register/register";
import Layout from "./Common/Layout/dashboardLayout";
import LeadsLayout from "./Common/Layout/leadLayout";
import userLayout from "./Common/Layout/userLayout";
import AddLeads from "./Common/Layout/addLeadLayout";
import EditLeadsLayout from "./Common/Layout/editLeadsLayout";
import ClientsLayout from "./Common/Layout/clientsLayout";
import EditClientLayout from "./Common/Layout/EditClientLayout";
import AddUserLayout from "./Common/Layout/addUserLayout";
import EditUserLayout from "./Common/Layout/editUserLayout";
import RoleLayout from "./Common/Layout/RoleLayout";
import AddRoleLayout from "./Common/Layout/addRole";
import EditRoleLayout from "./Common/Layout/editRoleLayout";
import PermissionLayout from "./Common/Layout/permissionsLayout";
import TaskLayout from "./Common/Layout/taskLayout";
import AddTaskLayout from "./Common/Layout/addTask";
import EditTaskLayout from "./Common/Layout/editTask";
import ProfileLayout from "./Common/Layout/profile";
import EditProfileLayout from "./Common/Layout/editProfile";
import ReportLayout from "./Common/Layout/reportLayout";

const routes = [
  {
    path: "",
    component: LoginPage,
    isProtected: false,
  },
  {
    path: "/register",
    component: RegisterPage,
    isProtected: false,
  },
  {
    path: "/dashboard",
    component: Layout,
    isProtected: false,
  },
  {
    path: "/leads",
    component: LeadsLayout,
    isProtected: true,
    menu:"Leads",
    permission:"View"
  },
  {
    path: "/user",
    component: userLayout,
    isProtected: true,
    menu:"Users",
    permission:"View"
  },
  {
    path: "/leads/addleads",
    component: AddLeads,
    isProtected: true,
    menu:"Leads",
    permission:"Create"
  },
  {
    path: "/leads/editleads/:leadId",
    component: EditLeadsLayout,
    isProtected: true,
    menu:"Leads",
    permission:"Update"
  },
  {
    path: "/clients",
    component: ClientsLayout,
    isProtected: true,
    menu:"Clients",
    permission:"View"
  },
  {
    path: "/clients/editclient/:clientId",
    component: EditClientLayout,
    isProtected: true,
    menu:"Clients",
    permission:"Create"
  },
  {
    path: "/user/adduser",
    component: AddUserLayout,
    isProtected: true,
    menu:"Users",
    permission:"Create"
  },
  {
    path: "/user/edituser/user/:userId",
    component: EditUserLayout,
    isProtected: true,
    menu:"Users",
    permission:"Update"
  },
  {
    path: "/role",
    component: RoleLayout,
    isProtected: true,
    menu:"Roles",
    permission:"View"
  },
  {
    path: "/role/addrole",
    component: AddRoleLayout,
    isProtected: true,
    menu:"Roles",
    permission:"Create"
  },
  {
    path: "/role/editrole/role",
    component: EditRoleLayout,
    isProtected: true,
    menu:"Roles",
    permission:"View"
  },
  {
    path: "/role/permissions/:roleId",
    component: PermissionLayout,
    isProtected: true,
    menu:"RoleClaims",
    permission:"Create"
  },
  {
    path: "/task", 
    component: TaskLayout, 
    isProtected: true,
    menu:"Task",
    permission:"View"
  },
  {
    path: "/task/addtask", 
    component: AddTaskLayout, 
    isProtected: true,
    menu:"Task",
    permission:"Create"
  },
  {
    path: "/task/edittask/task", 
    component: EditTaskLayout, 
    isProtected: true,
    menu:"Task",
    permission:"Update"
  },
  {
    path: "/profile", 
    component: ProfileLayout, 
    isProtected: true,
    menu:"Users",
    permission:"View"
  },
  {
    path: "/profile/editprofile", 
    component: EditProfileLayout, 
    isProtected: true,
    menu:"Users",
    permission:"Update"
  },
  {
    path: "/reports", 
    component: ReportLayout, 
    isProtected: true,
    menu:"Reports",
    permission:"View"
  },
];

export default routes;
