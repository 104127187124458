import '../leads/leads.css';
import { Search } from '../../../Common/shared/Search/search';
import Button from '../../../Common/shared/Button/button';
import SVGIcons from '../../../Common/shared/Icons/svgicons';
import React, { useState, useEffect, useContext } from 'react';
import "../leads/table.css";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import PageLoader from '../../../Common/PageLoader/index';
import { FaAngleDown } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Utils/userAuth.ts';
import { GetAllUsers, InActiveUser } from "../../../Services/authService.ts"

export const Users = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [10, 15, 20, 25];
    const navigate = useNavigate();
    const token = useAuth();

    useEffect(() => {
        if(!token)
        {
            navigate("/");
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const jsonData = await GetAllUsers();
            setData(jsonData.map(x => ({ id: x.user.id, isActive: x.user.isActive, userName: x.user.userName, phoneNumber: x.user.phoneNumber, role: x.roleName})));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const filteredData = searchTerm
        ? data.filter(item => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                (item.userName?.toString().toLowerCase().includes(term)) ||
                (item.role?.toString().toLowerCase().includes(term)) ||
                (item.phoneNumber?.toString().toLowerCase().includes(term))
            );
        })
        : data;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleNavigate = (item, event) => {
        event.preventDefault();
        navigate(`/user/edituser/user/${item.id}`); 
    };
    const toggleStatus = async (item, event) => {
        event.preventDefault();
        await InActiveUser({activateUser: !item.isActive, userId: item.id})
        await fetchData(); 
    };

    return (
        <div className='pagewrapper'>

            {data.length === 0 ? <PageLoader margin="50%" /> :
                <div className='leads'>
                    <div className="topLeads">
                        <div className="serachDrop">
                            <div className="dropdown label3 tablePageStatus">
                                <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                                <div className="dropdown-content">
                                    {itemsPerPageOptions.map(option => (
                                        <div>
                                            <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                        </div>
                        <div className="topLeadButtons">
                            <div className="topLeadButtonAdd">
                                <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => navigate("/user/adduser")}>Add User</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "10px 0px  0px  0px" }}>
                                        <div className="leadsTH">
                                                Username<PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Contact No. <PiCaretUpDownFill />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Role <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className='actionsTableTh' style={{ borderRadius: "0px 10px  0px  0px" }}>
                                            <div className="leadsTH">
                                                Action <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td className='label2'>
                                                {item.userName}
                                            </td>
                                            <td className='label2'>{item.phoneNumber}</td>
                                            <td className='label2'>
                                                {item.role}
                                            </td>
                                            <td className='actionsTable'>
                                                <SVGIcons.Edit  onClick={(event) => handleNavigate(item, event)} />
                                                {item.isActive ? <SVGIcons.Checked onClick={(event) => toggleStatus(item, event)} />
                                                    : <SVGIcons.Unchecked onClick={(event) => toggleStatus(item, event)} />}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='leadsTableBottom'>
                                <div className="leadsTableBottomLeft">
                                    <span className='body2 tablePageStatus'>
                                        Showing {rangeStart} to {rangeEnd} of {data.length} results
                                    </span>

                                </div>

                                <div className="leadsTablePaginationButtons">
                                    <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                    </button>

                                    {pageNumbersToDisplay.map((pageNumber) => (
                                        <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowRight size={28} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
