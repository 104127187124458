import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { lead, LeadMasterDTO, SaveLead } from "../Domain/Leads/lead.ts";
import { Image } from "../Domain/UserAuth/AppUser.ts";
import httpClient from "./httpClient.ts";

export const GetAllLeadValues = async (type: string) : Promise<Array<Partial<lead>>> => {
    try {
        const { data } = await httpClient.get(`lead/getlist/get-all`);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
  }

  export const SaveLeadData = async (leadData: SaveLead) => {
    try {
        const { data } = await httpClient.post(`lead/create/create`, leadData);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return [];
      }
  }

  export const UpdateLeadData = async (leadData: SaveLead) => {
    try {
      console.log("UpdateLead", JSON.stringify(leadData));
        const { data } = await httpClient.post(`lead/update/update`, leadData);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return [];
      }
  }

  export const GetLeadById = async (id: number) : Promise<Partial<SaveLead>> =>{
    try {
        const { data } = await httpClient.get(`lead/getbyleadid/${id}`);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return {};
      }
  }

  export const UploadLead = async (uploadData: Image) =>{
    try {
        const { data } = await httpClient.post(`lead/uploadleads/uploadlead`, uploadData);
        snackBarStore.showSnackBar(`Success: ${data}`, "success");
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return {};
      }
  }

  export const DeleteLead = async (leadData: LeadMasterDTO) =>{
    try {
        const { data } = await httpClient.delete(`lead/delete`, ({data: leadData}));
        snackBarStore.showSnackBar(`Success: ${data}`, "success");
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response?.data?.exception}`, "error");
        return {};
      }
  }