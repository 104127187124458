import React, { useState } from 'react';
import Main from '../main/main';
import Sidebar from '../Sidebar/sidebar';
import './layout.css';
import { Task } from '../../Presentation/Pages/Task/task';

function TaskLayout() {
  const [sidebarWidth, setSidebarWidth] = useState('15%');
  const [mainWidth, setMainWidth] = useState('85%');
  const [sidebarSmall, setSidebarSmall] = useState(false)

  const toggleSidebar = () => {
    if (sidebarWidth === '15%') {
      setSidebarWidth('5%');
      setMainWidth('95%');
      setSidebarSmall(true);
    } else {
      setSidebarWidth('15%');
      setMainWidth('85%');
      setSidebarSmall(false);
    }
  };

  return (
    <div className="layoutContainer">
      <Main width={mainWidth} toggleSidebar={toggleSidebar} children={<Task/>} top="Task"/>
      <Sidebar width={sidebarWidth} sidebarSmall={sidebarSmall} active="Task"/>
    </div>
  );
}

export default TaskLayout;
