import { React, useState, useEffect, useContext } from "react";
import "../addLeads/addLeads.css";
import "./profile.css";
import Button from "../../../Common/shared/Button/button";
import { useNavigate } from "react-router-dom";
import SVGIcons from "../../../Common/shared/Icons/svgicons";
import { GetAllDrops } from "../../../Services/DropService.ts";
import AddDropdown from "./addDrop";
import EditDropdown from "./editDrop";
import { fetUserData } from "../../../Services/authService.ts";
import { GetApiForImg } from "../../../Utils/userAuth.ts";
import { useLoading } from "../../../Services/ContextAPI/loadingContext.jsx";

export default function Profile() {
    const colorPalette = [
        "#F1C3C2", // Red
        "#FFC300", // Yellow
        "#DAF7A6", // Light Green
        "#8EF9F3", // Aqua
        "#9AECDB", // Light Blue
        "#ADD8E6", // Light Steel Blue
        "#A0D2DB", // Celeste
        "#98FB98", // Pale Green
        "#40E0D0", // Turquoise
        "#AFC459", // Light Lavender
        "#B0E0E6", // Powder Blue
        "#B19CD9", // Lavender Blue
        "#CCCCFF", // Periwinkle
        "#F0B27A", // Dark Peach
        "#FAD7A0"  // Light Goldenrod
    ];

    const [ drops, setDrops ] = useState([]);
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);
    const { startLoading, stopLoading } = useLoading();
    const apiUrl = GetApiForImg();
    const [isModalOpen, setIsModalOpen] = useState(true);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!isModalOpen) {
            window.location.reload();
        }
    }, [isModalOpen]);


    useEffect(() => {
        const fetchUser = async () => {
            try {
                startLoading();
                const result = await fetUserData();
                if (result) {
                    setProfileData(result);
                }
              } catch (error) {
                console.error("Error fetching user details:", error);
              } finally {
                stopLoading();
              }
        }

        fetchUser();
    }, []);

    useEffect(() => {
        const fetchDrops = async () => {
            try {
                startLoading();
                const result = await GetAllDrops();
                if (result) {
                    if (Array.isArray(result)) {
                        setDrops(result);
                    }
                }
              } catch (error) {
                console.error("Error fetching drops:", error);
              } finally {
                stopLoading()
              }
        }

        fetchDrops();
    }, []);

    const assignColors = () => {
        let colorIndex = 0;
        const colorMap = {};

        drops.forEach(drop => {
            drop.values.forEach(type => {
                colorMap[type.id] = colorPalette[colorIndex];
                colorIndex = (colorIndex + 1) % colorPalette.length;
            });
        });

        return colorMap;
    };

    const colorMap = assignColors();

    return (
        <>
        <div className="add-leads-form pro-1">
            <div className="profile10">
                <div className="profilePic1">
                    <img className="proPic01" src={ profileData?.imageUrl ? `${apiUrl}/${profileData?.imageUrl}`
                    : "https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="} alt="" />
                    <div className="proHead1">Profile Photo</div>
                </div>
                <div className="profileInfo">
                    <div className="profileEdit">
                        <Button variant="mediumButton" onClick={() => navigate('/profile/editprofile')}>Edit</Button>
                    </div>
                    <div className="add-leads-text">
                        <div className="personal-text">Profile Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">First Name</div>

                            <input
                                className={`add-leads-input proText}`}
                                value={profileData?.firstName}
                                disabled
                                placeholder="First Name"
                            />
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Last Name</div>

                            <input
                                className={`add-leads-input proText`}
                                placeholder="Last Name"
                                value={profileData?.lastName}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Email</div>
                            <input
                                type="text"
                                className={`add-leads-input proText`}
                                placeholder="Email Address"
                                disabled
                                value={profileData?.email}
                            />
                        </div>
                        <div className={`add-leads-input-container`}>
                            <div className="add-leadlabel">
                            </div>

                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Contact Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className={`add-leads-input-container`}>
                            <div className="add-leadlabel">
                                Mobile Number
                            </div>
                            <input
                                type="text"
                                style={{ width: "100% !important" }}
                                className={`proText add-leads-input input10 `}
                                value={profileData?.phoneNumber}
                                disabled
                            />
                        </div>
                        <div className={`add-leads-input-container`}>
                            <div className="add-leadlabel">
                                Whatsapp Number
                            </div>
                            <input
                                type="text"
                                className={`proText add-leads-input input10`}
                                value={profileData?.whatsAppNumber}
                                disabled
                            />
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div className="add-leads-form">
            <div className="profileEdit">
                <AddDropdown name={
                    <Button variant="mediumButton">
                        Add
                    </Button>
                } onClose={closeModal} />
            </div>

            <table id="dropTable">
                <tbody>
                    {drops.length == 0 ? "" : drops.map((item, index) => (
                        <tr className={`dropRow ${index === 0 ? 'firstRowP' : ''} ${index === drops.length - 1 ? 'lastRowP' : ''}`} key={index}>
                            <td className={`dropPro ${index === 0 ? 'firstRow' : ''} ${index === drops.length - 1 ? 'lastRow' : ''}`}>{item.typeName}</td>
                            <td className="dropPro2">
                                {item.values && item.values.map((type, idx) => (
                                    <div className="dropType label" key={idx} style={{ backgroundColor: colorMap[type.id], padding: '10px', fontSize: "13px" }}>{type.value01}</div>
                                ))}
                            </td>
                            <td className={`dropPro3 ${index === 0 ? 'firstRowP3' : ''} ${index === drops.length - 1 ? 'lastRowP3' : ''}`}>
                                <EditDropdown name={
                                    <SVGIcons.Edit />
                                }
                                    onClose={closeModal}
                                    servname={item.typeName}
                                    items={item.values}
                                />
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>

        </div>
        </>
    );
}