import React, { createContext, useState , useEffect } from 'react';

export const DateContext = createContext("");

export const DateProvider = ({ children }) => {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const getCurrentDate = () => {
            const date = new Date();
            const formattedDate = date.toLocaleDateString(); // Adjust format as needed
            setCurrentDate(formattedDate);
        };

        getCurrentDate();

        return () => {
        };
    }, []);
    const [activeDate, setActiveDate] = useState(currentDate);

    return (
        <DateContext.Provider value={{ activeDate, setActiveDate }}>
            {children}
        </DateContext.Provider>
    );
};