import { React, useState } from "react";
import "../addLeads/addLeads.css";
import Button from "../../../Common/shared/Button/button";
import { useForm } from "react-hook-form";
import Dropdown from 'react-dropdown';
import { useLocation, useNavigate } from "react-router-dom";


const options = [
    'one', 'two', 'three'
];

export default function EditClients() {
    const location = useLocation();
    const item = location.state?.item;
    const navigate = useNavigate();
    const [defaultOption, setdefaultOption] = useState("")
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [formS, setFormS] = useState(false)
    const [formS2, setFormS2] = useState(false)
    const [formNumber, setFormNumber] = useState(1);
    const { register: registerForm2, handleSubmit: handleSubmitForm2, formState: { errors: errorsForm2 } } = useForm();
    const [syncInputs, setSyncInputs] = useState(false);
    const [formData, setFormData] = useState({
        personalDetail: { selectedOption: "" },
        occupationDetail: {},
        interestedServices: {}
    });
    const [WhatsAppNo, setWhatsAppNo] = useState("");
    const [PhoneNo, setPhoneNo] = useState('');
    const handleInputChange = (e) => {
        const { value } = e.target;
        console.log(PhoneNo)
        setPhoneNo(value);
    };
    const handleWInputChange = (e) => {
        const { value } = e.target;
        console.log(WhatsAppNo)
        setWhatsAppNo(value);
    };
    const handleCheckboxChange = (event) => {
        setSyncInputs(event.target.checked)
        if (event.target.checked) {
            setWhatsAppNo(PhoneNo)
        }
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
        setdefaultOption(selected);
    };

    const onSubmit = (data) => {
        console.log(data); // Form data will be available here
        // Check if there are no errors before incrementing formNumber
        if (Object.keys(errors).length === 0) {
            if (selectedOption !== "") {
                setFormData({ ...formData, personalDetail: { ...data, selectedOption: selectedOption } });
                setFormNumber(formNumber + 1);
            }
        }
    };

    //for page3
    const onSubmit3 = (data) => {
        console.log(data); // Form data will be available here
        // Check if there are no errors before incrementing formNumber
        if (Object.keys(errorsForm2).length === 0) {
            if (selectedOption !== "") {
                setFormData({ ...formData, interestedServices: data });
                navigate("/clients");
                console.log(formData);
                // toast.success("client info updated");
            }
        }
    };



    const FirstPageNext = () => {
        handleSubmit(onSubmit)(); // Submit the form
        setFormS(true);
    };
    const ThirdPageNext = () => {
        handleSubmitForm2(onSubmit3)(); // Submit the form
        setFormS2(true);
    };


    return (
        <>
            {formNumber === 1 &&
                <div className="add-leads-form">
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <div className="add-leads-top">
                        <div className="add-leads-top2">
                            <div className="add-leadssection">1</div>
                            <div className="addleads-sectionname">Personal Detail</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2">
                            <div className="add-leadssection2">2</div>
                            <div className="addleads-sectionname2">Occupation</div>
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Personal Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Name</div>
                            {/* <input className="add-leads-input" placeholder="Rajiv" /> */}
                            <input
                                className={`add-leads-input ${errors.fname ? 'error-border' : ''}`}
                                placeholder="Rajiv"
                                {...register("fname", { required: "Name is required" })}

                            />
                            {errors.fname && <span className="errorLogin label3">{errors.fname.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Last Name</div>
                            <input
                                className={`add-leads-input ${errors.lname ? 'error-border' : ''}`}
                                placeholder="Sharma"
                                value={item.name}
                                {...register("lname", { required: "Last name is required" })}
                            />
                            {errors.lname && <span className="errorLogin label3">{errors.lname.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Lead Source</div>
                            {/* <input className="add-leads-input" placeholder="Name" /> */}
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(formS && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="Status"
                            />
                            {(formS && selectedOption === "") && <span className="errorLogin label3">lead source required</span>}
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Contact Detail</div>
                    </div>

                    <div className="add-leads-inputbox">
                    <div className="add-leads-input-container">
                            <div className="add-leadlabel">Mobile Number</div>
                            <input
                                className={`add-leads-input ${PhoneNo === "" ? (formS ? "error-border" : "") : ""}`}
                                onChange={handleInputChange}
                                type="number"
                                placeholder="+919876543211"
                            />
                            {(PhoneNo === "" && formS) && (
                                <span className="errorLogin label3">
                                    Phone no. is required
                                </span>
                            )}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Email id</div>
                            <input
                                className={`add-leads-input ${errors.ename ? 'error-border' : ''}`}
                                placeholder="rajiv.sharma@gmail.com"
                                {...register("ename", { required: "Email id is required" })}
                            />
                            {errors.ename && <span className="errorLogin label3">{errors.ename.message}</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="wp-phone">
                                <div className="add-leadlabel">WhatsApp Number </div>
                                <div className="wp-phone-2">
                                    <input
                                        type="checkbox"
                                        checked={syncInputs}
                                        onChange={handleCheckboxChange}
                                        style={{ border: "2px solid #00f", display: "block", backgroundColor: "#fafa" }}
                                    />
                                    <div className="same-no">same as mobile number</div>
                                </div>
                            </div>
                            <input
                                className={`add-leads-input ${WhatsAppNo === "" && formS ? "error-border" : ""
                                    }`}
                                type="number"
                                value={syncInputs ? PhoneNo : WhatsAppNo}
                                onChange={handleWInputChange}
                                placeholder="+919876543210"

                            />
                            {(WhatsAppNo === "" && formS) && (
                                <span className="errorLogin label3">
                                    WhatsApp no. is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="add-leads-inputbox">
                        <div className={`add-leads-input-container ${errors.dateb ? ' eb1' : ''}`}>
                            <div className="add-leadlabel">
                                Date of Birth
                            </div>
                            <input
                                type="text"
                                onFocus={(e) => e.target.type = 'date'}
                                onBlur={(e) => e.target.type = 'text'}
                                style={{ width: "100% !important" }}
                                className={`input-fu-1 add-leads-input input10 ${errors.dateb ? 'error-border eb' : ''}`}
                                placeholder="Date of birth"
                                {...register("dateb", {
                                    required: 'date of birth is required',
                                })}
                                aria-invalid={errors.dateb ? 'true' : 'false'}
                            />
                            {errors.dateb && <div className="errorLogin label3">{errors.dateb.message}</div>}
                        </div>
                        <div className={`add-leads-input-container ${errors.datea ? ' eb1' : ''}`}>
                            <div className="add-leadlabel">
                                Date of Anniversary
                            </div>
                            <input
                                placeholder="Date of Anniversary"
                                className={`input-fu-1 add-leads-input input10 ${errors.datea ? 'error-border eb' : ''}`}
                                type="text"
                                onFocus={(e) => e.target.type = 'date'}
                                onBlur={(e) => e.target.type = 'text'}
                                {...register("datea", {
                                    required: 'date of anniversary is required',
                                })}
                                aria-invalid={errors.datea ? 'true' : 'false'}
                            />
                            {errors.datea && <div className="errorLogin label3">{errors.datea.message}</div>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel"></div>
                        </div>
                    </div>

                    <div className="add-leads-addres">
                        <div className="add-leadlabel">Address Line 1</div>
                        <input
                            className={`add-leads-aadresinput ${errors.address1 ? 'error-border' : ''}`}
                            {...register("address1", { required: "Address is required" })}
                            placeholder="Build 1/A, 101, Shree krishna society,"
                        />
                        {errors.address1 && <span className="errorLogin label3">{errors.address1.message}</span>}
                    </div>

                    <div className="add-leads-addres">
                        <div className="add-leadlabel">Address Line 2</div>
                        <input
                            className={`add-leads-aadresinput ${errors.address2 ? 'error-border' : ''}`}
                            {...register("address2", { required: "Address is required" })}
                            placeholder="Waghle Esate, Thane"
                        />
                        {errors.address2 && <span className="errorLogin label3">{errors.address2.message}</span>}
                    </div>

                    <div className="add-leads-location">
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">Country</div>
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(formS && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="India"
                            />
                            {(formS && selectedOption === "") && <span className="errorLogin label3">Country required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">State</div>
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(formS && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="Maharashtra"
                            />
                            {(formS && selectedOption === "") && <span className="errorLogin label3">State required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">City</div>
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(formS && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="Thane"
                            />
                            {(formS && selectedOption === "") && <span className="errorLogin label3">City required</span>}
                        </div>
                        <div className="add-leads-input-town">
                            <div className="add-leadlabel">Pin code</div>
                            <input className={`add-leads-locationinput ${errors.pin ? 'error-border' : ''}`} placeholder="400601" {...register("pin", { required: "Email id is required" })} />
                            {errors.pin && <span className="errorLogin label3">{errors.pin.message}</span>}
                        </div>
                    </div>

                    <div className="add-leads-button">
                        <Button variant="smallButton">Back</Button>
                        <Button type="submit" variant="smallButton" onClick={FirstPageNext}>
                            Next
                        </Button>
                    </div>
                    {/* </form> */}
                </div>
            }
            {
                formNumber === 2 &&
                <div className="add-leads-form" id="add-leads-form2">
                    <div className="add-leads-top">
                        <div className="add-leads-top2">
                            <div className="add-leadssection noSelectno">1</div>
                            <div className="addleads-sectionname noSelectna">Personal Detail</div>
                        </div>
                        <hr className="line" />
                        <div className="add-leads-top2">
                            <div className="add-leadssection2 selectedNo">2</div>
                            <div className="addleads-sectionname2 selectedNa">Occupation</div>
                        </div>
                    </div>

                    <div className="add-leads-text">
                        <div className="personal-text">Occupation Details</div>
                    </div>

                    <div className="add-leads-inputbox">
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Occupation</div>
                            <Dropdown options={options}
                                className={`dropdown-fu dropdown-leads ${(formS && selectedOption === "") ? 'error-border' : ''}`}
                                onChange={handleDropdownChange}
                                value={defaultOption} placeholder="Country"
                            />
                            {(formS2 && selectedOption === "") && <span className="errorLogin label3">Country required</span>}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Type of Work</div>
                            <input
                                className={`add-leads-input ${errorsForm2.type ? "error-border" : ""}`}
                                placeholder="IT Engineer"
                                {...registerForm2("type", { required: "Type of Work is required" })}
                            /> {errorsForm2.type && (<span className="errorLogin label3">{errorsForm2.type.message}</span>)}
                        </div>
                        <div className="add-leads-input-container">
                            <div className="add-leadlabel">Monthly Income</div>
                            <input
                                className={`add-leads-input ${errorsForm2.income ? "error-border" : ""}`}
                                placeholder="30000"
                                {...registerForm2("income", { required: "Income is required" })}
                            /> {errorsForm2.income && (<span className="errorLogin label3">{errorsForm2.income.message}</span>)}
                        </div>
                    </div>

                    <div className="add-leads-button">
                        <Button variant="smallButton" onClick={() => setFormNumber(2)}>Back</Button>
                        <Button
                            type="submit"
                            variant="smallButton"
                            onClick={ThirdPageNext}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </>
    );
}

