import React from 'react';

export const TaskStatusBar = ({ status }) => {
    let barWidth = '0%';
    let color = "#E2362F"

    switch (status) {
        case 'Inform Client':
            barWidth = '22%';
            color = "#FEC600"
            break;
        case 'Under Process':
            barWidth = '50%';
            color = "#FEC600";
            break;
        case 'Mandate Pending':
            barWidth = '80%';
            color = "#FEC600";
            break;
        case 'Task Complete':
            barWidth = '100%';
            color = "#27B45C"
            break;
        case 'Reject':
            barWidth = '10%';
            color = "#E2362F"
            break;
        case 'Waiting For Documents':
            barWidth = '50%';
            color = "#FEC600";
            break;
        default:
            barWidth = '0%';
            break;
    }

    return (
        <div className="w3-border" style={{ width: "100%", height: "5px", borderRadius: "5px", backgroundColor: "#f1ebeb" }}>
            <div className="w3-grey" style={{ width: barWidth, height: "100%", backgroundColor: color, borderRadius: "5px" }}></div>
        </div>
    );
};
