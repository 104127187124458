import snackBarStore from "../Common/snackBar/store/snackbarStore.ts";
import { Drop, Values } from "../Domain/Drops/Drop.ts";
import httpClient from "./httpClient.ts";

export const GetAllDrops = async () : Promise<Array<Partial<Drop>>> => {
    try {
        const { data } = await httpClient.get("udc/gettypelist");
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const DeleteDropValueByType = async (drpData: Drop) : Promise<Array<Partial<Drop>>> => {
    try {
        const { data } = await httpClient.delete("udc/deleteudcvalues", { data: drpData });
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const CreateDropValueByType = async (drpData: Drop) : Promise<Array<Partial<Drop>>> => {
    try {
        const { data } = await httpClient.post("udc/addudctypevalues", drpData);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const UpdateDropValueById = async (drpData: Values, id: number) : Promise<Array<Partial<Values>>> => {
    try {
        const { data } = await httpClient.put(`udc/updateudcvalues/${id}/update-udc-values`, drpData);
        return data;
      } catch (error: any) {
        snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
        return [];
      }
}

export const GetDropValues = async (type: string) : Promise<Array<Partial<Values>>> => {
  try {
      const { data } = await httpClient.get(`udc/getvaluesbytype?type=${type}`);
      return data;
    } catch (error: any) {
      snackBarStore.showSnackBar(`Problem: ${error.response.data?.exception}`, "error");
      return [];
    }
}