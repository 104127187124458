import '../leads/leads.css'
import { Search } from '../../../Common/shared/Search/search'
import Button from '../../../Common/shared/Button/button.jsx'
import SVGIcons from '../../../Common/shared/Icons/svgicons'
import React, { useState, useEffect, useContext } from 'react';
import "../leads/table.css";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaAngleDown } from 'react-icons/fa6'
import { DateContext } from '../../../Services/ContextAPI/dateContext.jsx';
import { useNavigate } from 'react-router-dom';
import { TaskStatus } from '../../../Common/shared/taskStatus/taskStatus';
import { TaskStatusBar } from '../../../Common/shared/taskStatusBar/taskStatusBar';
import TaskStatusUpdate from './taskStatus.jsx';
import TaskInfo from './taskInfo.jsx';
import Modal from '../../../Common/modal/modal';
import { GetAllTasks, DeleteTask } from "../../../Services/taskService.ts"
import snackBarStore from "../../../Common/snackBar/store/snackbarStore.ts";
import { useLoading } from '../../../Services/ContextAPI/loadingContext.jsx';

export const Task = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPageOptions = [10, 15, 20, 25]; // Options for items per page
    const { activeDate } = useContext(DateContext)
    const navigate = useNavigate();
    const [task1, setTask1] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTaskId, setDeleteTaskId] = useState(null);
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        fetchData();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(true);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!isModalOpen) {
            window.location.reload();
        }
    }, [isModalOpen]);

    const fetchData = async () => {
        try {
            startLoading();
            const jsonData = await GetAllTasks();
            setData(jsonData);
        } catch (error) {
            snackBarStore.showSnackBar(`Error fetching data: ${error}`, "error");
        }
        finally{
            stopLoading();
        }
    };

    // Filter data based on search term if there's a search term, otherwise return all data
    const filteredData = searchTerm
        ? data.filter(item => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                (item.id.toString().toLowerCase().includes(term)) ||
                (item.taskName.toString().toLowerCase().includes(term))||
                (item.assignedTo.toString().toLowerCase().includes(term))||
                (item.taskFrom.toString().toLowerCase().includes(term))||
                (item.taskStatus.toString().toLowerCase().includes(term))
            );
        })
        : data.filter(item => activeDate === '' || item.dueDate === activeDate);


    // Calculate total number of pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Function to handle pagination
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate which page numbers to display (current, previous, next)
    const pageNumbersToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, data.length);

    // Get current items to display based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleNavigate = (item, event) => {
        event.preventDefault();
        navigate('/task/edittask/task', { state: { item } }); // Pass item as state
    };
    // Function to delete task
    const openDeleteModal = (itemId) => {
        setDeleteTaskId(itemId);
        setShowDeleteModal(true);
    };

    // Function to confirm delete and call delete API
    const handleDeleteTask = async () => {
        const taskData =
        {
            id: deleteTaskId,
            taskName: "string",
            taskType: "string",
            tenantId: "string",
            taskTo: 0,
            clientId: 0,
            dueDate: "2024-06-13T12:30:02.441Z",
            serviceRequest: "string",
            startDate: "2024-06-13T12:30:02.441Z",
            reminderDate: "2024-06-13T12:30:02.441Z",
            priority: "string",
            attachments: null,
            attachmentName: "",
            remarks: "string",
            taskFrom: "string",
            taskStatus: "string"
        }
        try {
            const response = await DeleteTask(taskData);
            setShowDeleteModal(false);
            snackBarStore.showSnackBar("Deleted Successfully", "success");
            window.location.reload();
            if (!response.ok) {
                console.error('Failed to delete the task');
            } 
        } catch (error) {
            console.error('Error deleting task:', error);
        }
        finally {
            setShowDeleteModal(false);
        }
    };

    return (
        <div className='pagewrapper'>
            <div className='taskTop'>
                <div className={`taskTop1 label4 ${task1 ? "taskTopSelected" : ""}`} onClick={() => setTask1(true)}>Lead Task</div>
                <div className={`taskTop2 label4 ${!task1 ? "taskTopSelected" : ""}`} onClick={() => setTask1(false)}>Client Task</div>
            </div>
            {showDeleteModal && (
                <Modal>
                    <div className="modal-content">
                        <div style={{ marginBottom: "10px" }}>
                            <h6>Delete Task</h6>
                        </div>
                        <div className="modal-body" style={{ marginBottom: "20px" }}>
                            Are you sure you want to delete this task?
                        </div>
                        <div className="modal-actions">
                            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            <Button onClick={handleDeleteTask}>Delete</Button>
                        </div>
                    </div>
                </Modal>
            )}
            {task1 ?
                <div className='leads'>
                    <div className="topLeads">
                        <div className="serachDrop">
                            <div className="dropdown label3 tablePageStatus">
                                <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                                <div className="dropdown-content">
                                    {itemsPerPageOptions.map(option => (
                                        <div>
                                            <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                        </div>
                        <div className="topLeadButtons">
                            <div className="topLeadButtonAdd">
                                <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => navigate("/task/addtask")}>Add Task</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                                            Task ID <PiCaretUpDownFill size={12} />
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task Name <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Indicator <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task To <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task From <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Due Date <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Status <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                                            <div className="leadsTH">
                                                Action <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.filter(item => item.taskType === "Lead").slice().reverse().map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='label2'>{item.id}</td>
                                                <td className='label2'>
                                                    <TaskInfo name={item.taskName} item={item} />
                                                </td>
                                                <td className='label2'>
                                                    <TaskStatusBar status={item.taskStatus} />
                                                </td>
                                                <td className='label2'>{item.assignedTo}</td>
                                                <td className='label2'>{item.taskFrom}</td>
                                                <td className='label2'>{item.dueDate.substring(0, 10)}</td>
                                                <td className='label2'>
                                                    <TaskStatus status={item.taskStatus} />
                                                </td>
                                                <td className='actionsTable'>
                                                    <SVGIcons.Edit onClick={(event) => handleNavigate(item, event)} />
                                                    <SVGIcons.Trash onClick={() => openDeleteModal(item.id)} />
                                                    <TaskStatusUpdate name={
                                                        <SVGIcons.DocumentText />
                                                    } id = {item.id} onClose={closeModal} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='leadsTableBottom'>
                                <div className="leadsTableBottomLeft">
                                    <span className='body2 tablePageStatus'>
                                        Showing {rangeStart} to {rangeEnd} of {data.length} results
                                    </span>

                                </div>

                                <div className="leadsTablePaginationButtons">
                                    <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                    </button>

                                    {pageNumbersToDisplay.map((pageNumber) => (
                                        <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowRight size={28} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='leads'>
                    <div className="topLeads">
                        <div className="serachDrop">
                            <div className="dropdown label3 tablePageStatus">
                                <button className="dropbtn">{itemsPerPage} <FaAngleDown size={10} /></button>
                                <div className="dropdown-content">
                                    {itemsPerPageOptions.map(option => (
                                        <div>
                                            <button key={option} onClick={() => setItemsPerPage(option)}>{option}</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Search handleSearch={handleSearch} searchTerm={searchTerm} />
                        </div>
                        <div className="topLeadButtons">
                            <div className="topLeadButtonAdd">
                                <Button variant="smallButton" prefixIcon={<SVGIcons.Add />} onClick={() => navigate("/task/addtask")}>Add Task</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <table className='leadsTable roundedcorner' id='leadsTable'>
                                <thead>
                                    <tr className='body2 roundedcorner'>
                                        <th style={{ borderRadius: "9px 0px  0px  0px" }}>
                                            Task ID <PiCaretUpDownFill size={12} />
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task Name <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Indicator <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task To <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Task From <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Due Date <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="leadsTH">
                                                Status <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                        <th className='actionsTableTh' style={{ borderRadius: "0px 9px  0px  0px" }}>
                                            <div className="leadsTH">
                                                Action <PiCaretUpDownFill size={12} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.filter(item => item.taskType === "Client").slice().reverse().map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='label2'>{item.id}</td>
                                                <td className='label2'>
                                                    <TaskInfo name={item.taskName} item={item} />
                                                </td>
                                                <td className='label2'>
                                                    <TaskStatusBar status={item.taskStatus} />
                                                </td>
                                                <td className='label2'>{item.assignedTo}</td>
                                                <td className='label2'>{item.taskFrom}</td>
                                                <td className='label2'>{item.dueDate.substring(0, 10)}</td>
                                                <td className='label2'>
                                                    <TaskStatus status={item.taskStatus} />
                                                </td>
                                                <td className='actionsTable'>
                                                    <SVGIcons.Edit onClick={(event) => handleNavigate(item, event)} />
                                                    <SVGIcons.Trash onClick={() => openDeleteModal(item.id)} />
                                                    <TaskStatusUpdate name={
                                                        <SVGIcons.DocumentText />
                                                    } id = {item.id} onClose={closeModal} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='leadsTableBottom'>
                                <div className="leadsTableBottomLeft">
                                    <span className='body2 tablePageStatus'>
                                        Showing {rangeStart} to {rangeEnd} of {data.length} results
                                    </span>

                                </div>
                                <div className="leadsTablePaginationButtons">
                                    <button onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={currentPage === 1} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowLeft size={28} />
                                    </button>

                                    {pageNumbersToDisplay.map((pageNumber) => (
                                        <button key={pageNumber} onClick={() => handlePagination(pageNumber)} disabled={pageNumber === currentPage} className="leadsTablePaginationButtonNumber">
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={currentPage === totalPages} className="leadsTablePaginationButton">
                                        <MdOutlineKeyboardDoubleArrowRight size={28} />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </div>
    )
}


